import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon } from 'antd';

import { Link } from 'react-router-dom';

class RouteMenu extends Component {
  render() {
    const { routes } = this.props
    const routeIndex = routes.reduce((routeIndex, route, index) =>
      (window.location.href.indexOf(route.path) > -1) &&
        (route.path.length > routeIndex.length) ?
        route.path :
        routeIndex
      , '')
    return (
      <Menu
        {...this.props}
        defaultSelectedKeys={[routeIndex]}
        selectedKeys={[routeIndex]}
      >
        {routes.map(route => {
          const normalLink = <Menu.Item key={route.path}>
            <Link to={route.path} >
              {route.path !== 'none' && (<Icon type={route.icon} />)}
              <span className="nav-text">{route.name}</span>
            </Link>
          </Menu.Item>
          const aLink = <Menu.Item key={route.path}>
            <a href={route.path}>
            {route.path !== 'none' && (<Icon type={route.icon} />)}
              <span className="nav-text">{route.name}</span>
            </a>
          </Menu.Item>
          return route.type && route.type === 'a' ? aLink : normalLink
        }
        )}
      </Menu >
    )
  }
}

Menu.propTypes = {
  routes: PropTypes.array.isRequired
};

export default RouteMenu;