import React, { Component } from 'react';
import { Switch, Route } from 'react-router'

import Stats from './Stats.jsx'

export default class Tekken extends Component {

  render() {
    const { user } = this.props
    return <Switch>
      <Route exact path="/tekken"
        render={(props) => <Stats {...props} user={user} />}
      />
      <Route path="/tekken/stat"
        render={(props) => <Stats {...props} user={user} />}
      />
    </Switch>
  }
}