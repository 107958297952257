import React from "react";
import ReactTable from "react-table";
import { Button, Modal, Row, Col } from "antd";
import axios from "axios";

import "react-table/react-table.css";

import "./users.css";
import { FormToSale } from "./FormToSale";
import { FormEditRow } from "./FormEditRow";

const recievers = {
  max: "Максим",
  vova: "Владимир",
  andrey: "Андрей",
  yana: "Яна"
};
const koshels = {
  qiwi: "Киви",
  ya: "Яндекс",
  cards: "Карта",
  wm: "Web Money"
};

const requestData = (getUsersUrl, pageSize, page, sorted, filtered) =>
  new Promise((resolve, reject) => {
    const res = {
      rows: [],
      pages: 0
    };

    axios
      .post(getUsersUrl, {
        pageSize,
        page,
        sorted,
        filtered
      })
      .then(data => {
        res.rows = data.data.rows;
        res.pages = data.data.pages;
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });

class UsersTelegramSport extends React.Component {
  constructor(props) {
    super(props);
    const columns = [
      { Header: "id", accessor: "id", type: "base" },
      {
        Header: "telegramId",
        accessor: "telegramId",
        width: 200,
        type: "base"
      },
      { Header: "login", accessor: "login", type: "base" },
      {
        Header: "Дата окончания",
        accessor: "dateStop",
        width: 150,
        type: "base"
      },
      { Header: "Кто принял", accessor: "reciever", type: "base" },
      { Header: "Кошелек", accessor: "koshel", type: "base" },

      { Header: "", accessor: "id", type: "edit" }
    ];

    this.state = {
      columns: columns.map(column => {
        column.style = { padding: "0" };
        if (column.type === "base") {
          column.Cell = row => {
            var val = "v" in row.value ? row.value.v : row.value;
            if (row.column.id === "reciever") {
              val = recievers[val] ? recievers[val] : val;
            }
            if (row.column.id === "koshel") {
              val = koshels[val] ? koshels[val] : val;
            }
            return <div style={this.rowstyle(row)}>{val}</div>;
          };
        }
        if (column.type === "edit") {
          column.Cell = row => (
            <div style={this.rowstyle(row)}>
              <Button
                onClick={() => {
                  this.setState({
                    editRowVisible: true,
                    editRowData: row.original
                  });
                }}
              >
                Edit
              </Button>
              <Button
                type="danger"
                onClick={() => {
                  const { deleteUserUrl } = this.props;
                  axios
                    .post(deleteUserUrl, {
                      id: row.original.id.v
                    })
                    .then(data => {
                      const { tableState } = this.state;
                      if (data.data.status === "ok") {
                        this.fetchData(tableState);
                      } else {
                        this.handleShowError(data.data.discription);
                      }
                    })
                    .catch(err => {
                      console.error(err);
                    });
                }}
              >
                Delete
              </Button>
            </div>
          );
        }
        return column;
      }),
      data: [],
      pages: null,
      loading: true,
      fastPayVisible: false,
      errorVisible: false,
      errorMsg: "",
      tableState: {},
      editRowVisible: false,
      editRowData: {}
    };
    this.fetchData = this.fetchData.bind(this);
  }

  rowstyle = row => {
    const res = {
      textAlign: "center",
      width: "100%",
      height: "100%",
      lineHeight: "30px"
    };
    if (row.value.bcolor) {
      res.backgroundColor = row.value.bcolor;
    }
    if (row.value.tcolor) {
      res.color = row.value.tcolor;
    }
    return res;
  };

  updateDataRow = value => {
    const { editUserUrl } = this.props;
    this.handleHideFastPay();
    axios
      .post(editUserUrl, value)
      .then(data => {
        const { tableState } = this.state;
        if (data.data.status === "ok") {
          this.fetchData(tableState);
        } else {
          this.handleShowError(data.data.discription);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  updateData = value => {
    const { telegramId, login, dateStop, reciever, koshel } = value;
    const { addPaymentUrl } = this.props;

    axios
      .post(addPaymentUrl, {
        telegramId,
        login,
        dateStop,
        reciever,
        koshel
      })
      .then(data => {
        console.log(data.data);
        const { tableState } = this.state;
        if (data.data.status === "ok") {
          this.fetchData(tableState);
        } else {
          this.handleShowError(data.data.discription);
        }
      })
      .catch(err => {
        console.log(err);
      });

    this.handleHideFastPay();
  };

  handleShowFastPay = () => {
    this.setState({
      fastPayVisible: true
    });
  };

  handleHideFastPay = () => {
    this.setState({
      fastPayVisible: false,
      editRowVisible: false
    });
  };

  handleShowError = msg => {
    this.setState({
      errorVisible: true,
      errorMsg: msg
    });
  };

  handleHideError = () => {
    this.setState({
      errorVisible: false
    });
  };

  fetchData(state, instance) {
    this.setState({ loading: true });
    const { getUsersUrl } = this.props;
    requestData(
      getUsersUrl,
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    )
      .then(res => {
        this.setState({
          data: res.rows,
          pages: res.pages,
          loading: false,
          tableState: state
        });
      })
      .catch(err => {
        this.setState({
          data: [],
          pages: 0,
          loading: false,
          tableState: state
        });
      });
  }

  render() {
    const {
      data,
      pages,
      loading,
      fastPayVisible,
      errorVisible,
      errorMsg,
      columns,
      editRowVisible,
      editRowData
    } = this.state;
    return (
      <div>
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleShowFastPay}>
              Быстрая продажа
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              visible={fastPayVisible}
              title="Быстрая продажа"
              destroyOnClose
              footer={null}
              onCancel={this.handleHideFastPay}
            >
              <FormToSale
                updateData={this.updateData}
                closeModal={this.handleHideFastPay}
              />
              ,
            </Modal>
            <Modal
              visible={editRowVisible}
              title="Редактировать"
              destroyOnClose
              footer={null}
              onCancel={this.handleHideFastPay}
            >
              <FormEditRow
                updateData={this.updateDataRow}
                editRowData={editRowData}
                closeModal={this.handleHideFastPay}
              />
              ,
            </Modal>
            <Modal
              visible={errorVisible}
              title="Ошибка"
              destroyOnClose
              footer={null}
              onCancel={this.handleHideError}
            >
              {errorMsg},
            </Modal>
            <ReactTable
              columns={columns}
              manual
              data={data}
              pages={pages}
              loading={loading}
              onFetchData={this.fetchData}
              filterable
              defaultPageSize={10}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default UsersTelegramSport;
