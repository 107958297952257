import React, { Component } from 'react';
import { Table, Row, Col, Card, Button, DatePicker, Form } from 'antd';

import moment from 'moment';
import axios from 'axios';
import Math from 'mathjs';
import { Link } from 'react-router-dom';

import MetaTags from 'react-meta-tags';

import { inject, observer } from "mobx-react";

import { OppSelect, LigaSelect } from '../../Common';
import { Select, Option } from '../../Common/Select';
import './Analiz.css';

import GraphAnaliz from './GraphAnaliz';

const renderSummPP = (text, record, index) => {
  const textStyle = {
    paddingTop: '2px',
    paddingBottom: '2px',
    textAlign: 'center'
  };

  if (record.p1 === 'vip') text = 'vip';
  if (record.round === 'Сумма') {
    textStyle.fontWeight = 'bold';
  } else {
    if (text > 50) {
      textStyle.backgroundColor = '#A3CCFA';
    }
    if (text >= 65) {
      textStyle.backgroundColor = '#A3AAFA';
    }
  }
  return <div style={textStyle}>{text}</div>;
};

const renderCellDefault = (text, record, index) => {
  const textStyle = {};
  //if (record.p1 === 'vip') text = 'vip';
  if (record.round === 'Сумма') {
    textStyle.fontWeight = 'bold';
  }
  return <div style={textStyle}>{text}</div>;
};

const renderHeader = (title, backColor) => {
  const textStyle = {
    textAlign: 'center'
  };
  if (backColor) textStyle.backgroundColor = backColor;
  return <div style={textStyle}>{title}</div>;
};

const colums = [
  { title: 'Раунд', dataIndex: 'round', render: renderCellDefault },
  {
    title: 'Игроки',
    className: 'players',
    className: 'players',
    children: [
      {
        title: 'Всего',
        dataIndex: 'pTotal',
        render: renderCellDefault,
        className: 'players'
      },
      {
        title: 'П1',
        dataIndex: 'p1',
        render: renderCellDefault,
        className: 'players'
      },
      {
        title: 'П1 %',
        dataIndex: 'p1p',
        render: renderSummPP,
        className: 'players'
      },
      {
        title: 'П2',
        dataIndex: 'p2',
        render: renderCellDefault,
        className: 'players'
      },
      {
        title: 'П2 %',
        dataIndex: 'p2p',
        render: renderSummPP,
        className: 'players'
      }
    ].map(coll => ({ ...coll, align: 'center' }))
  },
  {
    title: 'Без добивания (R)',
    className: 'dobiv_r',
    children: [
      {
        title: 'Всего',
        dataIndex: 'rTotal',
        render: renderCellDefault,
        className: 'dobiv_r'
      },
      {
        title: 'Общий %',
        className: 'dobiv_r',
        render: (text, record) => {
          const textStyle = {
            paddingTop: '2px',
            paddingBottom: '2px',
            textAlign: 'center'
          };
          let value = Math.round((record.rTotal * 100) / record.pTotal, 1);
          if (record.p1 === 'vip') value = 'vip';

          if (record.round === 'Сумма') {
            textStyle.fontWeight = 'bold';
          } else {
            if (value > 50) {
              textStyle.backgroundColor = '#777777';
            }
            if (value >= 65) {
              textStyle.backgroundColor = '#BBBBBB';
            }
          }
          return <div style={textStyle}>{value}</div>;
        }
      },
      {
        title: 'R П1',
        dataIndex: 'rP1',
        render: renderCellDefault,
        className: 'dobiv_r'
      },
      {
        title: 'R П1 %',
        dataIndex: 'rP1P',
        render: renderCellDefault,
        className: 'dobiv_r'
      },
      {
        title: 'R П2',
        dataIndex: 'rP2',
        render: renderCellDefault,
        className: 'dobiv_r'
      },
      {
        title: 'R П2 %',
        dataIndex: 'rP2P',
        render: renderCellDefault,
        className: 'dobiv_r'
      }
    ].map(coll => ({ ...coll, align: 'center' }))
  },
  {
    title: 'Фаталити (F)',
    className: 'dobiv_f',
    children: [
      {
        title: 'Всего',
        dataIndex: 'fTotal',
        render: renderCellDefault,
        className: 'dobiv_f'
      },
      {
        title: 'Общий %',
        dataIndex: 'fTolalP',
        className: 'dobiv_f',
        render: (text, record, index) => {
          const textStyle = {
            paddingTop: '2px',
            paddingBottom: '2px',
            textAlign: 'center'
          };
          if (record.p1 === 'vip') text = 'vip';
          if (record.round === 'Сумма') {
            textStyle.fontWeight = 'bold';
          } else {
            if (text > 40) {
              textStyle.backgroundColor = '#FF8888';
            }
            if (text > 50) {
              textStyle.backgroundColor = '#FF4444';
            }
          }
          return <div style={textStyle}>{text}</div>;
        }
      },
      {
        title: 'F П1',
        dataIndex: 'fP1',
        render: renderCellDefault,
        className: 'dobiv_f'
      },
      {
        title: 'F П1 %',
        dataIndex: 'fP1P',
        className: 'dobiv_f',
        render: renderCellDefault
      },
      {
        title: 'F П2',
        dataIndex: 'fP2',
        render: renderCellDefault,
        className: 'dobiv_f'
      },
      {
        title: 'F П2 %',
        dataIndex: 'fP2P',
        className: 'dobiv_f',
        render: renderCellDefault
      }
    ].map(coll => ({ ...coll, align: 'center' }))
  },
  {
    title: 'Бруталити (B)',
    className: 'dobiv_b',
    children: [
      {
        title: 'Всего',
        dataIndex: 'bTotal',
        render: renderCellDefault,
        className: 'dobiv_b'
      },
      {
        title: 'Общий %',
        className: 'dobiv_b',
        render: (text, record, index) => {
          const textStyle = {
            paddingTop: '2px',
            paddingBottom: '2px',
            textAlign: 'center'
          };
          let value =
            record.pTotal > 0
              ? Math.round((record.bTotal * 100) / record.pTotal, 1)
              : 0;
          if (record.p1 === 'vip') value = 'vip';
          if (record.round === 'Сумма') {
            textStyle.fontWeight = 'bold';
          } else {
            if (value > 30) {
              textStyle.backgroundColor = '#BBFFBB';
            }
            if (value > 40) {
              textStyle.backgroundColor = '#00EE00';
            }
          }
          return <div style={textStyle}>{value}</div>;
        }
      },
      {
        title: 'B П1',
        dataIndex: 'bP1',
        render: renderCellDefault,
        className: 'dobiv_b'
      },
      {
        title: 'B П1 %',
        dataIndex: 'bP1P',
        render: renderCellDefault,
        className: 'dobiv_b'
      },
      {
        title: 'B П2',
        dataIndex: 'bP2',
        render: renderCellDefault,
        className: 'dobiv_b'
      },
      {
        title: 'B П2 %',
        dataIndex: 'bP2P',
        render: renderCellDefault,
        className: 'dobiv_b'
      }
    ].map(coll => ({ ...coll, align: 'center' }))
  },
  { title: 'Время', dataIndex: 'timeA', render: renderCellDefault }
];

const addAnalizColumsTmpl = [
  {
    title: `R`,
    className: 'dobiv_r',
    children: [
      { title: `Общ.`, dataIndex: `r`, align: 'center', className: 'dobiv_r' },
      { title: `П1`, dataIndex: `rp1`, align: 'center', className: 'dobiv_r' },
      { title: `П2`, dataIndex: `rp2`, align: 'center', className: 'dobiv_r' }
    ].map(child => ({
      ...child,
      render: (text, record, index) => {
        const textStyle = {};
        if (Number(text) >= 1 && Number(text) <= 1.8) {
          textStyle.backgroundColor = '#AAAAAA';
        }
        return <div style={textStyle}>{text}</div>;
      }
    }))
  },
  {
    title: `F`,
    className: 'dobiv_f',
    children: [
      { title: `Общ.`, dataIndex: `f`, align: 'center', className: 'dobiv_f' },
      { title: `П1`, dataIndex: `fp1`, align: 'center', className: 'dobiv_f' },
      { title: `П2`, dataIndex: `fp2`, align: 'center', className: 'dobiv_f' }
    ].map(child => ({
      ...child,
      render: (text, record, index) => {
        const textStyle = {};
        if (Number(text) >= 1 && Number(text) <= 3.3) {
          textStyle.backgroundColor = '#FFAAAA';
        }
        return <div style={textStyle}>{text}</div>;
      }
    }))
  },
  {
    title: `B`,
    className: 'dobiv_b',
    children: [
      { title: `Общ.`, dataIndex: `b`, align: 'center', className: 'dobiv_b' },
      { title: `П1`, dataIndex: `bp1`, align: 'center', className: 'dobiv_b' },
      { title: `П2`, dataIndex: `bp2`, align: 'center', className: 'dobiv_b' }
    ].map(child => ({
      ...child,
      render: (text, record, index) => {
        const textStyle = {};
        if (Number(text) >= 1 && Number(text) <= 5) {
          textStyle.backgroundColor = '#AAFFAA';
        }
        return <div style={textStyle}>{text}</div>;
      }
    }))
  }
].map(coll => ({ ...coll, align: 'center' }));

@inject("mkStore") @observer
class Stats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      analiz: [],
      graphAvgtime: [],
      graphAvgtimeAvg: 0,
      graphAvgAut: {
        totalAvg: 0,
        groupAvg: []
      },
      graphTimes: {
        times: [],
        avg: { minB: 0, minM: 0, avgB: 0, avgM: 0, maxB: 0, maxM: 0 }
      },
      graphDobiv: [],
      graphDobivAvg: { r: 0, f: 0, b: 0 },
      groupGraphSize: 5,
      loading: false,
      dataApiUrl: '/api/mk/analiz',
      oppApiUrl: '/api/mk/opp',
      columns: colums,
      totalStat: [{ sp1: 0, sp2: 0 }],
      totalStatColums: [
        { title: `П1`, dataIndex: `sp1`, align: 'center' },
        { title: `П2`, dataIndex: `sp2`, align: 'center' }
      ],
      addAnaliz: [
        {
          r: 0,
          rp1: 0,
          rp2: 0,
          f: 0,
          fp1: 0,
          fp2: 0,
          b: 0,
          bp1: 0,
          bp2: 0
        }
      ],
      addAnalizColums: addAnalizColumsTmpl,
      opps: {},
      champs: {},
      oppsById: {},
      oppLoaded: false,
      filterOpp1: 0,
      filterOpp2: 0,
      filterLiga: 0,
      filterFromDate: '2021-01-01',
      lastGameCount: 0
    };
  }

  componentDidMount() {
    const urlParams = window.location.search.substr(1);
    let paramsObj = {
      opp1: 0,
      opp2: 0
    };
    if (urlParams !== '') {
      paramsObj = urlParams.split('&').reduce((result, param) => {
        if (param.indexOf('=') !== -1) {
          const [key, value] = param.split('=');
          result[key] = value;
        }
        return result;
      }, paramsObj);
    }
    console.log(paramsObj);
    this.fetchData(paramsObj.opp1, paramsObj.opp2, paramsObj.liga);
  }

  onChangeFromDate(date, dateString) {
    const { filterOpp1, filterOpp2, filterLiga } = this.state;
    this.fetchData(filterOpp1, filterOpp2, filterLiga, dateString);
  }

  onChangeLastGameCount(value) {
    const { filterOpp1, filterOpp2, filterLiga, dateString } = this.state;
    this.fetchData(filterOpp1, filterOpp2, filterLiga, dateString, value);
  }

  onChangeGroupGraphSize(value) {
    console.log(value);
    const { filterOpp1, filterOpp2, filterLiga, dateString, lastGameCount } = this.state;
    this.fetchData(filterOpp1, filterOpp2, filterLiga, dateString, lastGameCount, value);
  }

  prepareColums = cols =>
    cols.map(colum => ({
      ...colum,
      align: 'center'
    }));

  fetchData(
    opp1 = 0,
    opp2 = 0,
    liga = 0,
    paramFromDate = null,
    paramLastGameCount = 0,
    paramGroupGraphSize = 0
  ) {
    const fromDate = paramFromDate ? paramFromDate : this.state.fromDate;
    const lastGameCount = paramLastGameCount
      ? paramLastGameCount
      : this.state.lastGameCount;
    const groupGraphSize = paramGroupGraphSize
      ? paramGroupGraphSize
      : this.state.groupGraphSize;
    axios
      .post(this.state.dataApiUrl, {
        opp1,
        opp2,
        liga,
        fromDate,
        lastGameCount,
        groupGraphSize
      })
      .then(data => {
        let {
          analiz,
          graphAvgtime,
          graphAvgAut,
          graphDobiv,
          graphDobivAvg,
          graphTotal,
          graphAvgtimeAvg,
          graphTimes
        } = data.data;
        const sumItem = analiz.reduce((result, round) => {
          Object.keys(round).forEach(propName => {
            if (!result[propName]) result[propName] = 0;
            result[propName] = result[propName] + round[propName];
          });
          return result;
        }, {});
        sumItem.round = 'Сумма';
        sumItem.className = 'summa';

        const addAnaliz = {
          r:
            sumItem.rTotal && sumItem.rTotal > 0
              ? Math.round((sumItem.p1 + sumItem.p2) / sumItem.rTotal, 1)
              : 0,
          rp1: sumItem.rP1 ? Math.round(sumItem.p1 / sumItem.rP1, 1) : 0,
          rp2: sumItem.rP2 ? Math.round(sumItem.p2 / sumItem.rP2, 1) : 0,
          f:
            sumItem.fTotal && sumItem.fTotal > 0
              ? Math.round((sumItem.p1 + sumItem.p2) / sumItem.fTotal, 1)
              : 0,
          fp1: sumItem.fP1 ? Math.round(sumItem.p1 / sumItem.fP1, 1) : 0,
          fp2: sumItem.fP2 ? Math.round(sumItem.p2 / sumItem.fP2, 1) : 0,
          b:
            sumItem.bTotal && sumItem.bTotal > 0
              ? Math.round((sumItem.p1 + sumItem.p2) / sumItem.bTotal, 1)
              : 0,
          bp1: sumItem.bP1 ? Math.round(sumItem.p1 / sumItem.bP1, 1) : 0,
          bp2: sumItem.bP2 ? Math.round(sumItem.p2 / sumItem.bP2, 1) : 0
        };

        analiz = [...analiz, sumItem].map(round => ({
          ...round,
          p1p: round.pTotal
            ? Math.round((round.p1 * 100) / round.pTotal, 1)
            : 0,
          p2p: round.pTotal
            ? Math.round((round.p2 * 100) / round.pTotal, 1)
            : 0,
          rTolalP: round.pTotal
            ? Math.round((round.rTotal * 100) / round.pTotal, 1)
            : 0,
          rP1P: round.rTotal
            ? Math.round((round.rP1 * 100) / round.rTotal, 1)
            : 0,
          rP2P: round.rTotal
            ? Math.round((round.rP2 * 100) / round.rTotal, 1)
            : 0,
          fTolalP: round.pTotal
            ? Math.round((round.fTotal * 100) / round.pTotal, 1)
            : 0,
          fP1P: round.fTotal
            ? Math.round((round.fP1 * 100) / round.fTotal, 1)
            : 0,
          fP2P: round.fTotal
            ? Math.round((round.fP2 * 100) / round.fTotal, 1)
            : 0,
          bTotalP: round.pTotal
            ? Math.round((round.bTotal * 100) / round.pTotal, 1)
            : 0,
          bP1P: round.bTotal
            ? Math.round((round.bP1 * 100) / round.bTotal, 1)
            : 0,
          bP2P: round.bTotal
            ? Math.round((round.bP2 * 100) / round.bTotal, 1)
            : 0,
          timeA: round.timeC ? Math.round(round.timeS / round.timeC, 1) : 0
        }));

        let { totalStat } = data.data;
        const { user } = this.props;

        if (user && user.vip === 0 && (opp1 > 0 || opp2 > 0)) {
          analiz = analiz.map(round => {
            return Object.keys(round).reduce((result, key) => {
              if (key !== 'round') result[key] = 'vip';
              return result;
            }, round);
          });

          totalStat = totalStat.map(row => {
            return Object.keys(row).reduce((result, key) => {
              result[key] = 'vip';
              return result;
            }, row);
          });

          Object.keys(addAnaliz).forEach(key => {
            addAnaliz[key] = 'vip';
          });
        }

        this.setState({
          loading: false,
          analiz,
          graphAvgtime,
          graphAvgtimeAvg,
          graphAvgAut,
          graphDobiv,
          graphDobivAvg,
          graphTotal,
          totalStat,
          graphTimes,
          addAnaliz: [addAnaliz],
          filterOpp1: opp1,
          filterOpp2: opp2,
          filterLiga: liga,
          lastGameCount,
          groupGraphSize
        });
        console.log(data.data);
      })
      .catch(err => {
        this.setState({
          loading: false,
          analiz: []
        });
      });
  }

  render() {
    const {
      filterOpp1,
      filterOpp2,
      filterLiga,
      lastGameCount,
      filterFromDate,
      totalStat,
      totalStatColums,
      loading,
      oppLoaded,
      addAnaliz,
      addAnalizColums,
      analiz,
      columns,
      graphAvgtime,
      graphAvgAut,
      groupGraphSize,
      graphDobiv,
      graphDobivAvg,
      graphTotal,
      graphAvgtimeAvg,
      graphTimes
    } = this.state;

    console.log(filterLiga)

    const { user } = this.props;

    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 8,
      xl: 8
      // style: { marginBottom: 24 },
    };
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    const dateFormat = 'YYYY-MM-DD';

    const { setStatFilter } = this.props.mkStore

    return (
      <div className="mk_analiz" style={{ minHeight: 360 }}>
        <MetaTags>
          <title>Анализ игр MK X</title>
          <meta name="description" content="Анализ киберигр Mortal Kombat X" />
          <meta
            name="keywords"
            content="Mortal Kombat,Mortal Kombat X, MK, аналитика, прогнозы"
          />
        </MetaTags>
        <Row gutter={16}>
          <Col {...topColResponsiveProps}>
            <Card
              title="Пара"
              bordered={false}
              style={{ minHeight: '120px', marginBottom: 24 }}
            >
              <Row gutter={6}>
                <Col>
                  <LigaSelect
                    value={filterLiga}
                    oppApiUrl="/api/mk/opp"
                    onChange={value => {
                      this.fetchData(filterOpp1, filterOpp2, value);
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={6}>
                <Col span={8}>
                  <OppSelect
                    value={filterOpp1}
                    keyPrefix="left_opp"
                    oppApiUrl="/api/mk/opp"
                    onChange={value => {
                      this.fetchData(value, filterOpp2, filterLiga);
                    }}
                  />
                </Col>
                <Col span={8}>
                  <Button
                    style={{ width: '100%' }}
                    type="primary"
                    onClick={event => {
                      // const { filterOpp1, filterOpp2 } = this.state;
                      this.setState({
                        filterOpp1: filterOpp2,
                        filterOpp2: filterOpp1
                      });
                      this.fetchData(filterOpp2, filterOpp1, filterLiga);
                    }}
                  >
                    Поменять местами
                  </Button>
                </Col>
                <Col span={8}>
                  <OppSelect
                    value={filterOpp2}
                    keyPrefix="right_opp"
                    oppApiUrl="/api/mk/opp"
                    onChange={value => {
                      this.fetchData(filterOpp1, value, filterLiga);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Link
                    to={`/mk/stat`}
                  >
                    <Button
                      type="primary"
                      style={{ width: '100%', marginTop: '10px' }}
                      onClick={() => {
                        setStatFilter([
                          { id: 'clid_opp1', value: filterOpp1 },
                          { id: 'clid_opp2', value: filterOpp2 },
                          { id: 'champId', value: filterLiga },
                        ])
                      }}
                    >
                      Статистика пары
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col {...topColResponsiveProps}>
            <Card
              title="Параметры анализа"
              bordered={false}
              style={{ minHeight: '120px', marginBottom: 24 }}
            >
              <Form onSubmit={this.handleSubmit}>
                <Form.Item {...formItemLayout} label="Учитывать последние:">
                  <Select
                    style={{ width: '100%' }}
                    value={lastGameCount}
                    onChange={value => {
                      this.onChangeLastGameCount(value);
                    }}
                  >
                    <Option value={1}>1 бой</Option>
                    <Option value={2}>2 бой</Option>
                    <Option value={3}>3 боя</Option>
                    <Option value={4}>4 боя</Option>
                    <Option value={5}>5 боев</Option>
                    <Option value={6}>6 боев</Option>
                    <Option value={7}>7 боев</Option>
                    <Option value={8}>8 боев</Option>
                    <Option value={9}>9 боев</Option>
                    <Option value={10}>10 боев</Option>
                    <Option value={15}>15 боев</Option>
                    <Option value={25}>25 боев</Option>
                    <Option value={50}>50 боев</Option>
                    <Option value={100}>100 боев</Option>
                    <Option value={0}>Все бои</Option>
                  </Select>
                </Form.Item>
                <Form.Item {...formItemLayout} label="Учитывать бои с:">
                  <DatePicker
                    style={{ width: '100%' }}
                    defaultValue={moment(filterFromDate, dateFormat)}
                    format={dateFormat}
                    onChange={(date, dateSting) => {
                      this.onChangeFromDate(date, dateSting);
                    }}
                  />
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col {...topColResponsiveProps}>
            <Card
              title="Статистика"
              bordered={false}
              style={{ minHeight: '120px', marginBottom: 24 }}
            >
              <Table
                dataSource={totalStat}
                columns={totalStatColums}
                bordered
                pagination={false}
                loading={loading && oppLoaded}
              />
              <Table
                style={{ marginTop: '10px' }}
                dataSource={addAnaliz}
                columns={addAnalizColums}
                bordered
                pagination={false}
                loading={loading && oppLoaded}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card bordered={false}>
              <Table
                dataSource={analiz}
                columns={columns}
                bordered
                pagination={false}
                loading={loading && oppLoaded}
                scroll={{ x: true }}
              />
            </Card>
          </Col>
        </Row>
        {user && user.vip && (
          <Row gutter={16}>
            <Col span={24}>
              <Row gutter={0} style={{ backgroundColor: '#FFFFFF' }}>
                <Col span={6}>
                  <Select
                    style={{ width: '100%' }}
                    value={groupGraphSize}
                    onChange={value => {
                      this.onChangeGroupGraphSize(value);
                    }}
                  >
                    <Option value={1}>1 бой</Option>
                    <Option value={2}>2 боя</Option>
                    <Option value={3}>3 боев</Option>
                    <Option value={4}>4 боев</Option>
                    <Option value={5}>5 боев</Option>
                    <Option value={6}>6 боев</Option>
                    <Option value={7}>7 боев</Option>
                    <Option value={8}>8 боев</Option>
                    <Option value={9}>9 боев</Option>
                    <Option value={10}>10 боев</Option>
                  </Select>
                </Col>
              </Row>
              <Card bordered={false}>
                <GraphAnaliz
                  analiz={analiz}
                  user={user}
                  graphAvgtime={graphAvgtime}
                  graphAvgAut={graphAvgAut}
                  graphDobiv={graphDobiv}
                  graphDobivAvg={graphDobivAvg}
                  graphTotal={graphTotal}
                  graphAvgtimeAvg={graphAvgtimeAvg}
                  graphTimes={graphTimes}
                />
              </Card>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default Stats;
