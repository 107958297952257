import React from 'react';
import { Form, Input, DatePicker, Button, Select } from 'antd';

import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;

const dateFormat = 'YYYY-MM-DD';

class FormToSale extends React.Component {
  state = {
    telegramId: 0,
    login: "",
    dateStop: "2019-10-01",
    reciever: "",
    koshel: ""
  }

  handleChangeTelegramId = (e) => {
    this.setState({
      telegramId: e.target.value,
    });
  }

  handleChangeLogin = (e) => {
    this.setState({
      login: e.target.value,
    });
  }

  handleChangeDateStop = (e, dateString) => {
    this.setState({
      dateStop: dateString,
    });
  }

  handleAdd = () => {
    const { telegramId, login, dateStop, reciever, koshel } = this.state

    const dataToTable = {
      telegramId, login, dateStop, reciever, koshel
    }
    this.props.updateData(dataToTable)
  }

  handleCancel = () => {
    this.props.closeModal({ visible: false })
  }

  handleRecieverChange = (value) => {
    this.setState({
      reciever: value
    });
  }
  handleKoshelChange = (value) => {
    this.setState({
      koshel: value
    });
  }

  render() {
    const { telegramId, login, dateStop, reciever, koshel } = this.state;
    const recievers = {
      'max': "Максим",
      'vova': "Владимир",
      'andrey': "Андрей",
      'yana': "Яна",
    }
    const koshels = {
      'qiwi': 'Киви',
      'ya': 'Яндекс',
      'cards': 'Карта',
      'wm': 'Web Money'
    }
    return (
      <Form layout="horizontal" onSubmit={this.handleSubmit}>
        <FormItem>
          <Input
            value={telegramId}
            placeholder="telegramId"
            onChange={this.handleChangeTelegramId}
          />
        </FormItem>
        <FormItem>
          <Input
            value={login}
            placeholder="login"
            onChange={this.handleChangeLogin}
          />
        </FormItem>
        <FormItem>
          <DatePicker
            value={moment(dateStop, dateFormat)}
            format={dateFormat}
            onChange={this.handleChangeDateStop}
            allowClear={false}
          />
        </FormItem>
        <FormItem label="Кто принял платеж">
          <Select
            value={reciever}
            style={{ width: '100%' }}
            onChange={this.handleRecieverChange}
          >
            {Object.keys(recievers).map(key => <Option key={key}>{recievers[key]}</Option>)}
          </Select>
        </FormItem>

        <FormItem label="Кошелек">
          <Select
            value={koshel}
            style={{ width: '100%' }}
            onChange={this.handleKoshelChange}
          >
            {Object.keys(koshels).map(key => <Option key={key}>{koshels[key]}</Option>)}
          </Select>
        </FormItem>

        <Button key="back" onClick={this.handleCancel}>Отмена</Button>,
            <Button key="submit" type="primary" onClick={this.handleAdd}>
          Подтвердить
            </Button>
      </Form>
    );
  }
}

export { FormToSale }
