import React, { Component } from 'react';
import { Layout } from 'antd';
import styles from './SiderMenu.less';
import { inject, observer } from "mobx-react";

import RouteMenu from '../RouteMenu';

const { Sider } = Layout;

@inject("menuStore") @observer
class SiderMenu extends Component {
  
  render() {
    const { menuShow } = this.props.menuStore
    const { routes } = this.props;
    return (
      <Sider
        trigger={null}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => { console.log(broken); }}
        collapsed={!menuShow}
        className={styles.sider}
      >
        <div className="logo"><h1>BKstat.VIP</h1></div>
        <RouteMenu
          theme="dark"
          mode="inline"
          routes={routes}
        />
      </Sider >
    )
  }
}

export default SiderMenu;