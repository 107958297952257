import { makeAutoObservable } from "mobx"

import menuStore from './menuStore'
import mkStore from './mkStore'
import fifaStore from './fifaStore'


class rootStore {
  menuStore = menuStore
  mkStore = mkStore
  fifaStore = fifaStore

  constructor() {
    makeAutoObservable(this)
  }
}

export default new rootStore()