import React, { Component } from 'react';
import { Layout, Icon, Button } from 'antd';
import RouteMenu from '../RouteMenu';
import { inject, observer } from "mobx-react";

const { Header } = Layout;

const openPay = () => {
  var win = window.open(`https://vk.com/stavkamkx?w=product-142104089_1376084%2Fquery`, '_blank');
  win.focus();
}
@inject("menuStore") @observer
class GlobalHeader extends Component {
  render() {
    const stylesIcon = {
      fontSize: '20px',
      height: '46px',
      cursor: 'pointer',
      transition: 'all 0.3s, padding 0s',
      padding: '15px 20px',
      float: 'left',
      display: 'inline-block'
    }
    const stylesRoute = {
      float: 'left',
      display: 'inline-block'
    }
    const stylesButton = {
      display: 'inline-block',
      // float: 'right',
      right: '50px',
      position: 'fixed',
      margin: '6px',
      background: '#F95353',
      borderColor: '#F95353'
    }

    const { routes, user } = this.props;
    const { rootRouteIndex } = routes.reduce((obj, route, index) => {
      if ((window.location.href.indexOf(route.path) > -1) && (route.path.length > obj.rootRouteLength)) {
        obj = { rootRouteIndex: index, rootRouteLength: route.path.length }
      }
      return obj;
    }, { rootRouteIndex: 0, rootRouteLength: 0 })
    const subroutes = routes[rootRouteIndex].subroutes ? routes[rootRouteIndex].subroutes : [];
    const isVipUser = user && user.vip;
    const showFullFunc = !isVipUser && ((this.props.menuCollapse && window.innerWidth < 471) || (window.innerWidth > 470))
    const { menuShow, changeMenuState } = this.props.menuStore
    return (
      <Header style={{ background: '#fff', padding: 0 }} >
        <Icon
          style={stylesIcon}
          type={menuShow ? 'menu-unfold' : 'menu-fold'}
          onClick={() => {
            changeMenuState()
          }}
        />
        <RouteMenu
          style={stylesRoute}
          mode="horizontal"
          routes={subroutes}
        />
        {showFullFunc && (<Button
          style={stylesButton}
          type='primary'
          onClick={openPay}
        >Полный функионал</Button>)}
      </Header>
    )
  }
}

export default GlobalHeader;