import React, { Component } from 'react';
import classNames from 'classnames';

export const Option = (prop) => <option {...prop} />

export class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue ? this.props.defaultValue : 0
    }
  }

  handleChange = (event) => {
    const { value } = event.target;
    if (this.props.onChange) this.props.onChange(value);

    this.setState({
      value
    })
  }

  render() {
    const selectClasses = classNames({
      'ant-select-selection': true,
      'ant-select-selection--single': true
    })
    let { value } = this.state;
    if (typeof this.props.value !== "undefined") value = this.props.value;
    const style = this.props.style ? this.props.style : {}
    return <select
      className={selectClasses}
      onChange={this.handleChange}
      value={value}
      style={style}
    >
      {this.props.children}
    </select>
  }
}