import React, { Component } from 'react';
import { Select, Option } from './Select';

class SelectMobx extends Component {
  render() {
    const { onChange, keyPrefix, value, items } = this.props;

    const prefixKey = `opp_${keyPrefix}`
    return <Select
      style={{ width: '100%' }}
      value={value}
      onChange={v => {
        onChange(v);
      }}>
      <Option key={`${prefixKey}_0`} value={0}>Все</Option>
      {
        Object.keys(items).sort((a, b) => b > a ? -1 : 1).filter(listID => parseInt(listID, 10) !== 0).map(listID =>
          <Option key={`${prefixKey}_${listID}`} value={items[listID]}>{listID}</Option>
        )
      }
    </Select>
  }
}

export default SelectMobx