import React, { Component } from 'react';
import { Form, Card, Row, Col } from 'antd';
import MetaTags from 'react-meta-tags';
import './calctools.css';

import { Dogon as Dogon2 } from '../../Common/Calcs';

class Dogon extends Component {

  render() {
    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 8,
    };

    return (
      <div>
        <MetaTags>
          <title>Калькулятор догона</title>
          <meta name="description" content="Калькулятор по стратегии догон" />
          <meta name="keywords" content="Калькулятор ставок, догон" />
        </MetaTags>
      <Row>
        <Col {...topColResponsiveProps}>
          <Card title={'Догон'}>
            <Dogon2 />
          </Card>
        </Col>
      </Row>
      </div>
    )

  }
}

const DogonForm = Form.create()(Dogon);

export { DogonForm, Dogon }
