import React, { Component } from 'react';
import { Row, Col, Card, Radio, Button } from 'antd';
import MetaTags from 'react-meta-tags';

import { StatsTable, StatColumnVisibleChanger } from '../../Common';
import AvgStat from './AvgStat';

import { Select, Option } from '../../Common/Select';

const roundResultMap = {
  '0': ' ',
  '10': 'П1',
  '11': 'П1 R',
  '12': 'П1 A',
  '13': 'П1 S',
  '14': 'П1 U',
  '20': 'П2',
  '21': 'П2 R',
  '22': 'П2 A',
  '23': 'П2 S',
  '24': 'П2 U',
  '1': 'R',
  '2': 'A',
  '3': 'S',
  '4': 'U'
};

const RoundFilter = (roundIndex, row) => {
  const { filter, onChange, column } = row;
  return (
    <Select
      defaultValue={filter && filter.value ? filter.value : 0}
      value={filter && filter.value ? filter.value : 0}
      style={{ width: '100%', minWidth: '50px' }}
      onChange={value => {
        onChange(value);
      }}
    >
      {Object.keys(column.dic).map(listID => (
        <Option key={`filter_${roundIndex}_${listID}`} value={listID}>
          {column.dic[listID]}
        </Option>
      ))}
    </Select>
  );
};

const columnsPara = {
  Header: 'Пара',
  key: 'para',
  visible: true,
  HeaderSelectList: {
    no: '',
    win: 'Победитель',
    U: 'U',
    S: 'S',
    A: 'A'
  },
  selectedBacklight: 'win',
  columns: [
    {
      Header: 'Левый',
      accessor: 'clid_opp1',
      width: 130,
      type: 'opp'
    },
    {
      Header: 'Правый',
      accessor: 'clid_opp2',
      width: 130,
      type: 'opp'
    }
  ].map(col => ({
    ...col,
    cellStyle2: (textStyle, cellData) => {
      const newTextStyle = { ...textStyle };
      const { original, column } = cellData;
      const { selectedBacklight } = column;
      const mapDobivColors = {
        A: '#f88581',
        S: '#16FF00',
        U: '#e070ff'
      };
      if (selectedBacklight === 'no') return newTextStyle;
      switch (selectedBacklight) {
        case 'win':
          if (original.result.v.includes('5:') && column.id === 'clid_opp1')
            newTextStyle.backgroundColor = '#A3CCFA';
          if (original.result.v.includes(':5') && column.id === 'clid_opp2')
            newTextStyle.backgroundColor = '#A3CCFA';
          break;
        case 'A':
        case 'S':
        case 'U':
          [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce((result, roundIndex) => {
            if (original[`r${roundIndex}`].dobiv === selectedBacklight) {
              if (
                column.id === 'clid_opp1' &&
                original[`r${roundIndex}`].winner === 1
              )
                return true;
              if (
                column.id === 'clid_opp2' &&
                original[`r${roundIndex}`].winner === 2
              )
                return true;
            }
            return result;
          }, false) &&
            (newTextStyle.backgroundColor = mapDobivColors[selectedBacklight]);
          break;
        default:
      }
      return newTextStyle;
    }
  }))
};

const columnsResult = {
  Header: 'Результат',
  key: 'result',
  accessor: 'result',
  width: 50,
  visible: true,
  cellStyle: (textStyle, value, row, column) => {
    const newStyle = { ...textStyle };
    if (typeof value.v !== 'undefined') {
      if (value.v.indexOf('5:') !== -1) {
        newStyle.backgroundColor = '#000000';
        newStyle.color = '#FFFFFF';
      }
      if (value.v.indexOf(':5') !== -1) {
        newStyle.backgroundColor = '#FD7F01';
      }
      if (value.v === '5:0' || value.v === '0:5') {
        newStyle.backgroundColor = '#628DF5';
      }
      if (value.v.indexOf(':5') === -1 && value.v.indexOf('5:') === -1) {
        newStyle.backgroundColor = '#FFFF55';
      }
    }
    return newStyle;
  }
};

const columnsRounds = {
  Header: 'Раунды',
  key: 'rounds',
  visible: true,
  HeaderSelectList: {
    no: '',
    A_S_U: 'A + S + U',
    A: 'A',
    S: 'S',
    U: 'U',
    P1P2: 'П1+П2',
    P1: 'П1',
    P2: 'П2',
    out: 'Победил аутсайдер'
  },
  selectedBacklight: 'A_S_U',
  columns: [1, 2, 3, 4, 5, 6, 7, 8, 9].map(roundIndex => {
    return {
      width: 50,
      accessor: `r${roundIndex}`,
      Header: `${roundIndex}`,
      dic: roundResultMap,
      Filter: row => RoundFilter(roundIndex, row),
      cellStyle2: (textStyle, cellData) => {
        const newTextStyle = { ...textStyle };
        const { value, original, column } = cellData;
        const { selectedBacklight } = column;

        const mapDobivColors = {
          A: '#f88581',
          S: '#16FF00',
          U: '#e070ff'
        };

        switch (selectedBacklight) {
          case 'A_S_U':
            if (mapDobivColors[value.dobiv])
              newTextStyle.backgroundColor = mapDobivColors[value.dobiv];
            break;
          case 'A':
          case 'S':
          case 'U':
            if (value.dobiv === selectedBacklight)
              newTextStyle.backgroundColor = mapDobivColors[value.dobiv];
            break;
          case 'out':
            if (original.sp1.v > original.sp2.v && value.winner === 1) {
              newTextStyle.backgroundColor = '#000000';
              newTextStyle.color = '#FFFFFF';
            }
            if (original.sp1.v < original.sp2.v && value.winner === 2) {
              newTextStyle.backgroundColor = '#000000';
              newTextStyle.color = '#FFFFFF';
            }
            break;
          case 'P1P2':
            if (value.winner === 1) {
              newTextStyle.backgroundColor = '#000000';
              newTextStyle.color = '#FFFFFF';
            }
            if (value.winner === 2) newTextStyle.backgroundColor = '#FD7F01';
            break;
          case 'P1':
            if (value.winner === 1) {
              newTextStyle.backgroundColor = '#000000';
              newTextStyle.color = '#FFFFFF';
            }
            break;
          case 'P2':
            if (value.winner === 2) newTextStyle.backgroundColor = '#FD7F01';
            break;
          default:
        }
        return newTextStyle;
      }
    };
  })
};

const columnsRoundKefs = {
  Header: 'Кефы раунд',
  key: 'kef_rounds',
  visible: true,
  onlyVip: true,
  columns: [
    { Header: `П1`, accessor: `sp1` },
    { Header: `П2`, accessor: `sp2` }
  ]
};

const columns1x2Kefs = {
  Header: 'Кефы 1x2',
  key: 'kef_1x2',
  onlyVip: true,
  columns: [
    { Header: `П1`, accessor: `s1x1` },
    { Header: `П2`, accessor: `s1x2` }
  ]
};

const columnsDobivKefs = {
  Header: 'Кефы добив.',
  key: 'kef_dobiv',
  visible: true,
  onlyVip: true,
  columns: [
    { Header: `S`, accessor: `kb` },
    { Header: `A`, accessor: `kf` },
    { Header: `R`, accessor: `kr` },
    { Header: `U`, accessor: `ku` }
  ]
};

const columnsTime = {
  Header: 'Продолжительность',
  key: 'times',
  onlyVip: true,
  HeaderSelectList: {
    no: '',
    min: 'Меньшее',
    avg: 'Среднее',
    max: 'Максимальное',
    min_avg_max: 'Мин + Сред + Макс'
  },
  selectedBacklight: 'min_avg_max',
  columns: [1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => {
    return {
      Header: `${index}`,
      accessor: `r${index}time`,
      dic: { 0: ' ' },
      cellStyle2: (textStyle, cellData) => {
        const newTextStyle = { ...textStyle };
        const { value, original, column } = cellData;

        if (!value.v || value.v === '0') return newTextStyle;

        const { selectedBacklight } = column;

        if (
          selectedBacklight.includes('min') ||
          selectedBacklight.includes('avg')
        ) {
          if (
            original.timep2m &&
            original.timep2m.v &&
            value.v < original.timep2m.v
          ) {
            newTextStyle.backgroundColor = '#AAFFAA';
          }
        }
        if (selectedBacklight.includes('min')) {
          if (
            original.timep1m &&
            original.timep1m.v &&
            value.v < original.timep1m.v
          ) {
            newTextStyle.backgroundColor = '#00AA00';
          }
        }
        if (
          selectedBacklight.includes('max') ||
          selectedBacklight.includes('avg')
        ) {
          if (
            original.timep2m &&
            original.timep2m.v &&
            value.v > original.timep2m.v
          ) {
            newTextStyle.backgroundColor = '#44b4ff';
          }
        }

        if (selectedBacklight.includes('max')) {
          if (
            original.timep3m &&
            original.timep3m.v &&
            value.v > original.timep3m.v
          ) {
            newTextStyle.backgroundColor = '#0060dd';
            newTextStyle.color = '#FFFFFF';
          }
        }

        return newTextStyle;
      }
    };
  })
};

const columnsTimeMin = {
  Header: 'Время мин',
  key: 'timeMin',
  onlyVip: true,
  columns: [
    { Header: 'М', accessor: `time1m` },
    { Header: 'время', accessor: `timep1m` },
    { Header: 'Б', accessor: `time1b` }
  ]
};

const columnsTimeAvg = {
  Header: 'Время сред',
  key: 'timeAvg',
  onlyVip: true,
  columns: [
    { Header: 'М', accessor: `time2m` },
    { Header: 'время', accessor: `timep2m` },
    { Header: 'Б', accessor: `time2b` }
  ]
};

const columnsTimeMax = {
  Header: 'Время макс',
  key: 'timeMax',
  onlyVip: true,
  columns: [
    { Header: 'М', accessor: `time3m` },
    { Header: 'время', accessor: `timep3m` },
    { Header: 'Б', accessor: `time3b` }
  ]
};

const columnsTotal = {
  Header: 'Общий тотал',
  key: 'total_common',
  onlyVip: true,
  columns: [
    { Header: '5.5 М', accessor: 't55m' },
    { Header: '5.5 Б', accessor: 't55b' },
    { Header: '6.5 М', accessor: 't65m' },
    { Header: '6.5 Б', accessor: 't65b' },
    { Header: '7.5 М', accessor: 't75m' },
    { Header: '7.5 Б', accessor: 't75b' },
    { Header: '8.5 М', accessor: 't85m' },
    { Header: '8.5 Б', accessor: 't85b' }
  ]
};

const columnsTotalU = {
  Header: 'Тотал U',
  key: 'totalU',
  onlyVip: true,
  columns: [
    { Header: '0.5 М', accessor: 'tu05m' },
    { Header: '0.5 Б', accessor: 'tu05b' }
    // { Header: '1.5 М', accessor: 'tu15m' },
    // { Header: '1.5 Б', accessor: 'tu15b' },
    // { Header: '2.5 М', accessor: 'tu25m' },
    // { Header: '2.5 Б', accessor: 'tu25b' },
    // { Header: '3.5 М', accessor: 'tu35m' },
    // { Header: '3.5 Б', accessor: 'tu35b' },
    // { Header: '4.5 М', accessor: 'tu45m' },
    // { Header: '4.5 Б', accessor: 'tu45b' },
    // { Header: '5.5 М', accessor: 'tu55m' },
    // { Header: '5.5 Б', accessor: 'tu55b' },
  ]
};

const columnsTotalA = {
  Header: 'Тотал A',
  key: 'totalA',
  onlyVip: true,
  columns: [
    { Header: '0.5 М', accessor: 'ta05m' },
    { Header: '0.5 Б', accessor: 'ta05b' },
    { Header: '1.5 М', accessor: 'ta15m' },
    { Header: '1.5 Б', accessor: 'ta15b' },
    { Header: '2.5 М', accessor: 'ta25m' },
    { Header: '2.5 Б', accessor: 'ta25b' },
    { Header: '3.5 М', accessor: 'ta35m' },
    { Header: '3.5 Б', accessor: 'ta35b' }
    // { Header: '4.5 М', accessor: 'ta45m' },
    // { Header: '4.5 Б', accessor: 'ta45b' },
    // { Header: '5.5 М', accessor: 'ta55m' },
    // { Header: '5.5 Б', accessor: 'ta55b' },
  ]
};

const columnsTotalS = {
  Header: 'Тотал S',
  key: 'totalS',
  onlyVip: true,
  columns: [
    { Header: '0.5 М', accessor: 'ts05m' },
    { Header: '0.5 Б', accessor: 'ts05b' },
    { Header: '1.5 М', accessor: 'ts15m' },
    { Header: '1.5 Б', accessor: 'ts15b' }
    // { Header: '2.5 М', accessor: 'ts25m' },
    // { Header: '2.5 Б', accessor: 'ts25b' },
    // { Header: '3.5 М', accessor: 'ts35m' },
    // { Header: '3.5 Б', accessor: 'ts35b' },
    // { Header: '4.5 М', accessor: 'ts45m' },
    // { Header: '4.5 Б', accessor: 'ts45b' },
    // { Header: '5.5 М', accessor: 'ts55m' },
    // { Header: '5.5 Б', accessor: 'ts55b' },
  ]
};

const columnsTotalR = {
  Header: 'Тотал R',
  key: 'totalR',
  onlyVip: true,
  columns: [
    // { Header: '0.5 М', accessor: 'tr05m' },
    // { Header: '0.5 Б', accessor: 'tr05b' },
    // { Header: '1.5 М', accessor: 'tr15m' },
    // { Header: '1.5 Б', accessor: 'tr15b' },
    // { Header: '2.5 М', accessor: 'tr25m' },
    // { Header: '2.5 Б', accessor: 'tr25b' },
    { Header: '3.5 М', accessor: 'tr35m' },
    { Header: '3.5 Б', accessor: 'tr35b' },
    // { Header: '4.5 М', accessor: 'tr45m' },
    // { Header: '4.5 Б', accessor: 'tr45b' },
    { Header: '5.5 М', accessor: 'tr55m' },
    { Header: '5.5 Б', accessor: 'tr55b' }
  ]
};

const baseColumns = [
  {
    Header: '#пп',
    key: 'n',
    accessor: 'n',
    noHide: true,
    visible: true,
    width: 30,
    Filter: row => <div />
  },
  {
    Header: 'Анализ',
    key: 'analiz',
    noHide: true,
    type: 'custom',
    visible: true,
    width: 70,
    Filter: row => <div />,
    Cell: row => {
      const cellStyle = {
        textAlign: 'center',
        width: '100%',
        height: '100%'
      };
      return (
        <div style={cellStyle}>
          <Button
            type="primary"
            icon="line-chart"
            onClick={() => {
              var win = window.open(
                `/ki/analiz?opp1=${row.row.clid_opp1.v}&opp2=${
                  row.row.clid_opp2.v
                }`,
                '_blank'
              );
              win.focus();
            }}
          />
        </div>
      );
    }
  },
  {
    Header: 'Дата и время',
    key: 'dateStart',
    accessor: 'dateStart',
    width: 100,
    type: 'base',
    visible: true
  },
  columnsPara,
  columnsResult,
  columnsRounds,
  columnsRoundKefs,
  columns1x2Kefs,
  columnsDobivKefs,
  columnsTime,
  columnsTimeMin,
  columnsTimeAvg,
  columnsTimeMax,
  columnsTotal,
  columnsTotalU,
  columnsTotalA,
  columnsTotalS,
  columnsTotalR
  // columnsTotalP1,
  // columnsTotalP2,
  // columnsChet
];

class Stats extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;

    this.state = {
      columns: baseColumns,
      avgStat: [
        {
          r: 0,
          rp1: 0,
          rp2: 0,
          a: 0,
          ap1: 0,
          ap2: 0,
          s: 0,
          sp1: 0,
          sp2: 0,
          u: 0,
          up1: 0,
          up2: 0
        }
      ],
      podsvetka: 0
    };
  }

  componentDidMount() {
    this.loadSettings();
  }

  handleChangeVisibleColumn(visibleColumnKeys) {
    const { columns } = this.state;
    const newColumns = columns.map(column => ({
      ...column,
      visible: column.noHide ? true : visibleColumnKeys.includes(column.key)
    }));
    this.setState({
      columns: newColumns
    });
    this.saveSettings({ columns: newColumns });
  }

  handleChangePodsvetka(e) {
    this.setState({
      podsvetka: e.target.value
    });
  }

  loadSettings() {
    const settingSrc = localStorage.getItem(`KI_store_table_visible`);
    if (settingSrc === null) return false;
    try {
      const setting = JSON.parse(settingSrc);
      if (!setting.visibleColumn) return false;
      const visObj = setting.visibleColumn.reduce((result, vis) => {
        result[vis.key] = vis.visible;
        return result;
      }, {});
      const newColumns = this.state.columns.map(col => {
        if (typeof visObj[col.key] !== 'undefined')
          col.visible = visObj[col.key];
        return col;
      });
      this.setState({
        columns: newColumns
      });
    } catch (err) {
      console.log(err);
    }
  }

  saveSettings(customSetting = {}) {
    const { columns } = this.state;
    const cols = customSetting.columns ? customSetting.columns : columns;

    const setting = {
      visibleColumn: cols.map(column => ({
        key: column.key,
        visible: column.visible ? column.visible : false
      }))
    };

    localStorage.setItem(`KI_store_table_visible`, JSON.stringify(setting));
  }

  updateAvgStat(data) {
    const { du, du1, ds, ds1, da, da1, dr, dr1, rc1, rc2, rt, time } = data;
    const dr2 = dr - dr1;
    const da2 = da - da1;
    const ds2 = ds - ds1;
    const du2 = du - du1;
    this.setState({
      avgStat: [
        {
          r: dr ? Math.round((rc1 + rc2) / dr, 1) : 0,
          rp1: dr1 ? Math.round(rc1 / dr1, 1) : 0,
          rp2: dr2 ? Math.round(rc2 / dr2, 1) : 0,

          a: da ? Math.round((rc1 + rc2) / da, 1) : 0,
          ap1: da1 ? Math.round(rc1 / da1, 1) : 0,
          ap2: da2 ? Math.round(rc2 / da2, 1) : 0,

          s: ds ? Math.round((rc1 + rc2) / ds, 1) : 0,
          sp1: ds1 ? Math.round(rc1 / ds1, 1) : 0,
          sp2: ds2 ? Math.round(rc2 / ds2, 1) : 0,

          u: du ? Math.round((rc1 + rc2) / du, 1) : 0,
          up1: du1 ? Math.round(rc1 / du1, 1) : 0,
          up2: du2 ? Math.round(rc2 / du2, 1) : 0,
          time
        }
      ]
    });
  }

  render() {
    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 8
    };
    const { user } = this.props;
    let { columns,avgStat } = this.state;
    if (user && user.vip === 0) {
      columns = columns.filter(column => !column.onlyVip);
    }
    const visibleColumns = columns.filter(column => column.visible);

    const presetColumns = [
      {
        key: 'ishod',
        title: 'Исход',
        columnsKeys: [
          'dateStart',
          'para',
          'result',
          'rounds',
          'kef_rounds',
          'kef_dobiv',
          'kef_1x2'
        ]
      },
      {
        key: 'time',
        title: 'Время',
        columnsKeys: [
          'dateStart',
          'para',
          'result',
          'times',
          'timeMin',
          'timeAvg',
          'timeMax'
        ]
      },
      {
        key: 'total',
        title: 'Тотал',
        columnsKeys: [
          'dateStart',
          'para',
          'result',
          'kef_total_f',
          'kef_total_b',
          'total_common',
          'totalU',
          'totalA',
          'totalS',
          'totalR'
        ]
      }
    ];

    return (
      <div style={{ minHeight: 360 }}>
        <MetaTags>
          <title>Статистика KI</title>
          <meta
            name="description"
            content="Статистика киберигр Killer Instinct"
          />
          <meta name="keywords" content="Killer Instinct, KI, аналитика" />
        </MetaTags>
        <Row gutter={16}>
          <Col {...topColResponsiveProps}>
            <StatColumnVisibleChanger
              storagePrefix="ki"
              key="visibleChanger_ki"
              columns={columns}
              presetColumns={presetColumns}
              handleChangeVisibleColumn={(key, value) => {
                this.handleChangeVisibleColumn(key, value);
              }}
            />
          </Col>

          <Col {...topColResponsiveProps}>
          <AvgStat data={avgStat} user={user} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card bordered={false}>
              <StatsTable
                user={user}
                columns={visibleColumns}
                localStoreKey="KI_store"
                dataApiUrl="/api/ki/stat"
                oppApiUrl="/api/ki/opp"
                wsUrl="/ws/ki"
                onUpdateAvgStat={(data) => { this.updateAvgStat(data) }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Stats;
