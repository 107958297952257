import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './components/App';
import { Provider } from "mobx-react";
import rootStore from './store';
// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render((
  <Provider 
    rootStore={rootStore}
    menuStore={rootStore.menuStore}
    mkStore={rootStore.mkStore}
    fifaStore={rootStore.fifaStore}>
    <BrowserRouter>
      <App />
      {/* <AutorizationLoginForm /> */}
    </BrowserRouter>
  </Provider>
), document.getElementById('root'));
// registerServiceWorker();
