import React from 'react';
import ReactTable from 'react-table';
import { Button, Modal, Row, Col } from 'antd';
import axios from 'axios';

import 'react-table/react-table.css';

import './users.css';
import { FormToSale } from './FormToSale';

const requestData = (pageSize, page, sorted, filtered) =>
  new Promise((resolve, reject) => {
    const res = {
      rows: [],
      pages: 0
    };

    axios
      .post('/api/admin/users_telegram', {
        pageSize,
        page,
        sorted,
        filtered
      })
      .then(data => {
        res.rows = data.data.rows;
        res.pages = data.data.pages;
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });

class UsersTelegram extends React.Component {
  constructor(props) {
    super(props);
    const columns = [
      { Header: 'id', accessor: 'id', type: 'base' },
      {
        Header: 'telegramId',
        accessor: 'telegramId',
        width: 200,
        type: 'base'
      },
      { Header: 'login', accessor: 'login', type: 'base' },
      { Header: 'Осталось дней', accessor: 'daysLeft', type: 'base' }
    ];

    this.state = {
      columns: columns.map(column => {
        column.style = { padding: '0' };
        column.Cell = row => (
          <div style={this.rowstyle(row)}>
            {'v' in row.value ? row.value.v : row.value}
          </div>
        );
        return column;
      }),
      data: [],
      pages: null,
      loading: true,
      fastPayVisible: false,
      errorVisible: false,
      errorMsg: '',
      tableState: {}
    };
    this.fetchData = this.fetchData.bind(this);
  }

  rowstyle = row => {
    const res = {
      textAlign: 'center',
      width: '100%',
      height: '100%',
      lineHeight: '30px'
    };
    if (row.value.bcolor) {
      res.backgroundColor = row.value.bcolor;
    }
    if (row.value.tcolor) {
      res.color = row.value.tcolor;
    }
    return res;
  };

  updateData = value => {
    console.log(value);
    const { telegramId, login, daysLeft } = value;
    axios
      .post('/api/admin/payments/addtelegram', {
        telegramId,
        login,
        daysLeft
      })
      .then(data => {
        console.log(data.data);
        const { tableState } = this.state;
        if (data.data.status === 'ok') {
          this.fetchData(tableState);
        } else {
          this.handleShowError(data.data.discription);
        }
      })
      .catch(err => {
        console.log(err);
      });

    this.handleHideFastPay();
  };

  handleShowFastPay = () => {
    this.setState({
      fastPayVisible: true
    });
  };

  handleHideFastPay = () => {
    this.setState({
      fastPayVisible: false
    });
  };

  handleShowError = msg => {
    this.setState({
      errorVisible: true,
      errorMsg: msg
    });
  };

  handleHideError = () => {
    this.setState({
      errorVisible: false
    });
  };

  fetchData(state, instance) {
    this.setState({ loading: true });
    requestData(state.pageSize, state.page, state.sorted, state.filtered)
      .then(res => {
        this.setState({
          data: res.rows,
          pages: res.pages,
          loading: false,
          tableState: state
        });
      })
      .catch(err => {
        this.setState({
          data: [],
          pages: 0,
          loading: false,
          tableState: state
        });
      });
  }

  render() {
    const {
      data,
      pages,
      loading,
      fastPayVisible,
      errorVisible,
      errorMsg,
      columns
    } = this.state;
    return (
      <div>
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleShowFastPay}>
              Быстрая продажа
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Modal
              visible={fastPayVisible}
              title="Быстрая продажа"
              destroyOnClose
              footer={null}
              onCancel={this.handleHideFastPay}
            >
              <FormToSale
                updateData={this.updateData}
                closeModal={this.handleHideFastPay}
              />
              ,
            </Modal>
            <Modal
              visible={errorVisible}
              title="Ошибка"
              destroyOnClose
              footer={null}
              onCancel={this.handleHideError}
            >
              {errorMsg},
            </Modal>
            <ReactTable
              columns={columns}
              manual
              data={data}
              pages={pages}
              loading={loading}
              onFetchData={this.fetchData}
              filterable
              defaultPageSize={10}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default UsersTelegram;
