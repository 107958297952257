import React, { Component } from 'react';
import { Table, Row, Col, Card, Button, DatePicker, Form } from 'antd';

import moment from 'moment';

import { OppSelect } from '../../Common';

import axios from 'axios';
import Math from 'mathjs'
import { Object } from 'core-js';
import { Link } from 'react-router-dom';

import MetaTags from 'react-meta-tags';

class Analiz extends Component {
  constructor(props) {
    super(props);
    const renderSummColums = (text, record, index) => {
      const textStyle = {
        textAlign: 'center',
      }
      if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
      return <div style={textStyle}>{text}</div>
    }
    const colums = [
      { title: 'Раунд', dataIndex: 'round', render: renderSummColums },
      { title: 'П1', dataIndex: 'p1', render: renderSummColums },
      {
        title: 'П1 %',
        render: (text, record, index) => {
          let textStyle = {
            paddingTop: '2px',
            paddingBottom: '2px',
            textAlign: 'center',
          };
          if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
          let value = Math.round(record.p1 * 100 / record.pTotal, 1);
          if (record.p1 === 'vip') value = 'vip';
          if (value > 50) { textStyle.backgroundColor = '#BBFFBB' }
          return <div style={textStyle}>{value}</div>
        }
      },
      { title: 'П2', dataIndex: 'p2', render: renderSummColums },
      {
        title: 'П2 %',
        render: (text, record, index) => {
          let textStyle = {
            paddingTop: '2px',
            paddingBottom: '2px',
            textAlign: 'center',
          };
          if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
          let value = Math.round(100 - record.p1 * 100 / record.pTotal, 1);
          if (record.p1 === 'vip') value = 'vip';
          if (value > 50) { textStyle.backgroundColor = '#BBFFBB' }
          if (value >= 65) { textStyle.backgroundColor = '#00EE00' }
          return <div style={textStyle}>{value}</div>
        }
      },
      {
        title: 'R', key: 'r', className: 'table-left-right-border',
        children: [
          { title: 'R', dataIndex: 'rTotal', className: 'table-left-border', render: renderSummColums },
          {
            title: 'R %',
            render: (text, record, index) => {
              let textStyle = {
                paddingTop: '2px',
                paddingBottom: '2px',
                textAlign: 'center',
              };
              if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
              let value = Math.round(record.rTotal * 100 / record.pTotal, 1);
              if (record.p1 === 'vip') value = 'vip';
              if (value > 50) { textStyle.backgroundColor = '#BBFFBB' }
              if (value >= 65) { textStyle.backgroundColor = '#00EE00' }
              return <div style={textStyle}>{value}</div>
            }
          },
          { title: 'R П1', dataIndex: 'rP1', render: renderSummColums },
          {
            title: 'R П1 %',
            render: (text, record, index) => {
              const textStyle = {
                textAlign: 'center',
              }
              if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
              let value = Math.round(record.rP1 * 100 / record.rTotal, 1);
              if (record.p1 === 'vip') value = 'vip';
              return <div style={textStyle}>{value}</div>
            }
          },
          { title: 'R П2', dataIndex: 'rP2', render: renderSummColums },
          {
            title: 'R П2 %',
            className: 'table-right-border',
            render: (text, record, index) => {
              const textStyle = {
                textAlign: 'center',
              }
              if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
              let value = Math.round(100 - record.rP1 * 100 / record.rTotal, 1);
              if (record.p1 === 'vip') value = 'vip';
              return <div style={textStyle}>{value}</div>
            }
          },
        ]
      },
      {
        title: 'А', key: 'a', className: 'table-left-right-border',
        children: [
          { title: 'A', dataIndex: 'aTotal', className: 'table-left-border', render: renderSummColums },
          {
            title: 'A %',
            render: (text, record, index) => {
              let textStyle = {
                paddingTop: '2px',
                paddingBottom: '2px',
                textAlign: 'center',
              };
              if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
              let value = Math.round(record.aTotal * 100 / record.pTotal, 1);
              if (record.p1 === 'vip') value = 'vip';
              if (value > 40) { textStyle.backgroundColor = '#FF8888' }
              if (value > 50) { textStyle.backgroundColor = '#FF4444' }
              return <div style={textStyle}>{value}</div>
            }
          },
          { title: 'A П1', dataIndex: 'aP1', render: renderSummColums },
          {
            title: 'A П1 %',
            render: (text, record, index) => {
              const textStyle = {
                textAlign: 'center',
              }
              if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
              let value = Math.round(record.aP1 * 100 / record.aTotal, 1);
              if (record.p1 === 'vip') value = 'vip';
              return <div style={textStyle}>{value}</div>
            }
          },
          { title: 'A П2', dataIndex: 'aP2', render: renderSummColums },
          {
            title: 'A П2 %',
            className: 'table-right-border',
            render: (text, record, index) => {
              const textStyle = {
                textAlign: 'center',
              }
              if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
              let value = Math.round(100 - record.aP1 * 100 / record.aTotal, 1);
              if (record.p1 === 'vip') value = 'vip';
              return <div style={textStyle}>{value}</div>
            }
          },
        ]
      },
      {
        title: 'S', key: 's', className: 'table-left-right-border',
        children: [
          { title: 'S', dataIndex: 'sTotal', className: 'table-left-border', render: renderSummColums },
          {
            title: 'S %',
            render: (text, record, index) => {
              let textStyle = {
                paddingTop: '2px',
                paddingBottom: '2px',
                textAlign: 'center'
              };
              if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
              let value = record.sTotal > 0 ? Math.round(record.sTotal * 100 / record.pTotal, 1) : 0;
              if (record.p1 === 'vip') value = 'vip';
              if (value > 30) { textStyle.backgroundColor = '#FF8888' }
              if (value > 40) { textStyle.backgroundColor = '#FF4444' }
              return <div style={textStyle}>{value}</div>
            }
          },
          { title: 'S П1', dataIndex: 'sP1', render: renderSummColums },
          {
            title: 'S П1 %',
            render: (text, record, index) => {
              const textStyle = {
                textAlign: 'center',
              }
              if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
              let value = Math.round(record.sP1 * 100 / record.sTotal, 1);
              if (record.p1 === 'vip') value = 'vip';
              return <div style={textStyle}>{value}</div>
            }
          },
          { title: 'S П2', dataIndex: 'sP2', render: renderSummColums },
          {
            title: 'S П2 %',
            className: 'table-right-border',
            render: (text, record, index) => {
              const textStyle = {
                textAlign: 'center',
              }
              if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
              let value = Math.round(100 - record.sP1 * 100 / record.sTotal, 1);
              if (record.p1 === 'vip') value = 'vip';
              return <div style={textStyle}>{value}</div>
            }
          },
        ]
      },
      {
        title: 'U', key: 'u', className: 'table-left-right-border',
        children: [
          { title: 'U', dataIndex: 'uTotal', className: 'table-left-border', render: renderSummColums },
          {
            title: 'U %',
            render: (text, record, index) => {
              let textStyle = {
                paddingTop: '2px',
                paddingBottom: '2px',
                textAlign: 'center',
              };
              if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
              let value = record.uTotal > 0 ? Math.round(record.uTotal * 100 / record.pTotal, 1) : 0;
              if (record.p1 === 'vip') value = 'vip';
              if (value > 30) { textStyle.backgroundColor = '#FF8888' }
              if (value > 40) { textStyle.backgroundColor = '#FF4444' }
              return <div style={textStyle}>{value}</div>
            }
          },
          { title: 'U П1', dataIndex: 'uP1', render: renderSummColums },
          {
            title: 'U П1 %',
            render: (text, record, index) => {
              const textStyle = {
                textAlign: 'center',
              }
              if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
              let value = Math.round(record.uP1 * 100 / record.uTotal, 1);
              if (record.p1 === 'vip') value = 'vip';
              return <div style={textStyle}>{value}</div>
            }
          },
          { title: 'U П2', dataIndex: 'uP2', render: renderSummColums },
          {
            title: 'U П2 %',
            className: 'table-right-border',
            render: (text, record, index) => {
              const textStyle = {
                textAlign: 'center',
              }
              if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
              let value = Math.round(100 - record.uP1 * 100 / record.uTotal, 1);
              if (record.p1 === 'vip') value = 'vip';
              return <div style={textStyle}>{value}</div>
            }
          },
        ]
      },
      {
        title: 'Время',
        render: (text, record, index) => {
          const textStyle = {}
          if (record.round === 'Сумма') { textStyle.fontWeight = 'bold'; }
          let value = Math.round(record.timeS / record.timeC, 1);
          if (record.p1 === 'vip') value = 'vip';
          return <div style={textStyle}>{value}</div>
        }
      },
    ]

    const addAnalizColums = [
      { title: `R`, dataIndex: `r`, align: 'center' },
      { title: `A`, dataIndex: `a`, align: 'center' },
      { title: `S`, dataIndex: `s`, align: 'center' },
      { title: `U`, dataIndex: `u`, align: 'center' },
    ]

    this.state = {
      analiz: [],
      loading: false,
      dataApiUrl: '/api/ki/analiz',
      oppApiUrl: '/api/ki/opp',
      columns: this.prepareColums(colums),
      totalStat: [{ sp1: 0, sp2: 0 }],
      totalStatColums: [
        { title: `П1`, dataIndex: `sp1`, align: 'center' },
        { title: `П2`, dataIndex: `sp2`, align: 'center' }
      ],
      addAnaliz: [{
        r: 0,
        f: 0,
        b: 0,
        bp1: 0,
        bp2: 0,
        b1: 0,
      }],
      addAnalizColums: addAnalizColums,
      opps: {},
      champs: {},
      oppsById: {},
      oppLoaded: false,
      filterOpp1: 0,
      filterOpp2: 0,
      filterFromDate: '2021-01-01'
    }

  }

  prepareColums(colums) {
    return colums.map(colum => {
      colum.align = 'center';

      return colum;
    });
  }

  fetchData(opp1 = 0, opp2 = 0, fromDate = '2016-01-01') {
    axios.post(
      this.state.dataApiUrl,
      {
        opp1: opp1,
        opp2: opp2,
        fromDate: fromDate
      }
    ).then(data => {
      const sumItem = data.data.analiz.reduce((result, round) => {
        Object.keys(round).forEach(propName => {
          if (!result[propName]) result[propName] = 0;
          result[propName] = result[propName] + round[propName];
        })
        return result;
      }, {})
      sumItem['round'] = 'Сумма';

      const addAnaliz = {
        r: sumItem.rTotal && sumItem.rTotal > 0 ? Math.round((sumItem.p1 + sumItem.p2) / sumItem.rTotal, 1) : 0,
        a: sumItem.aTotal && sumItem.aTotal > 0 ? Math.round((sumItem.p1 + sumItem.p2) / sumItem.aTotal, 1) : 0,
        s: sumItem.sTotal && sumItem.sTotal > 0 ? Math.round((sumItem.p1 + sumItem.p2) / sumItem.sTotal, 1) : 0,
        u: sumItem.uTotal && sumItem.uTotal > 0 ? Math.round((sumItem.p1 + sumItem.p2) / sumItem.uTotal, 1) : 0,
      }

      let analiz = [sumItem, ...data.data.analiz];
      let totalStat = data.data.totalStat
      const { user } = this.props

      if (user && user.vip === 0 && (opp1 > 0 || opp2 > 0)) {
        analiz = analiz.map(round => {
          return Object.keys(round).reduce((result, key) => {
            if (key !== 'round') result[key] = 'vip'
            return result;
          }, round)
        })

        totalStat = totalStat.map(row => {
          return Object.keys(row).reduce((result, key) => {
            result[key] = 'vip'
            return result;
          }, row)
        })

        Object.keys(addAnaliz).forEach(key => {
          addAnaliz[key] = 'vip'
        })
      }

      this.setState({
        loading: false,
        analiz: analiz,
        totalStat: totalStat,
        addAnaliz: [addAnaliz],
        filterOpp1: opp1,
        filterOpp2: opp2
      });
      console.log(data.data);
    }).catch(err => {
      this.setState({
        loading: false,
        analiz: [],
      });
    })
  }

  onChangeFromDate(date, dateString) {
    const { filterOpp1, filterOpp2 } = this.state
    this.fetchData(filterOpp1, filterOpp2, dateString)
  }

  componentDidMount() {
    const urlParams = window.location.search.substr(1);
    let paramsObj = {
      opp1: 0,
      opp2: 0,
    }
    if (urlParams !== "") {
      paramsObj = urlParams.split('&').reduce((result, param) => {
        if (param.indexOf('=') !== -1) {
          const [key, value] = param.split('=');
          result[key] = value;
        }
        return result;
      }, paramsObj)
    }
    console.log(paramsObj);
    this.fetchData(paramsObj.opp1, paramsObj.opp2);
    //this.loadOpp(0);
  }

  render() {
    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 8,
      xl: 8,
      // style: { marginBottom: 24 },
    };
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const dateFormat = 'YYYY-MM-DD';

    return (
      <div style={{ minHeight: 360 }} >
        <MetaTags>
          <title>Анализ KI</title>
          <meta name="description" content="Анализ киберигр Killer Instinct" />
          <meta name="keywords" content="Killer Instinct, KI, аналитика, прогноз" />
        </MetaTags>
        <Row gutter={16}>
          <Col {...topColResponsiveProps} >
            <Card title={'Пара'} bordered={false} style={{ minHeight: '120px', marginBottom: 24 }}>
              <Row gutter={6}>
                <Col span={8}>
                  <OppSelect
                    value={this.state.filterOpp1}
                    keyPrefix={'left_opp'}
                    oppApiUrl={'/api/ki/opp'}
                    onChange={value => {
                      this.fetchData(value, this.state.filterOpp2);
                    }}
                  />
                </Col>
                <Col span={8}>
                  <Button
                    style={{ width: '100%' }}
                    type="primary"
                    onClick={(event) => {
                      const { filterOpp1, filterOpp2 } = this.state;
                      this.setState({
                        filterOpp1: filterOpp2,
                        filterOpp2: filterOpp1,
                      });
                      this.fetchData(filterOpp2, filterOpp1)
                    }}
                  >
                    Поменять местами</Button>
                </Col>
                <Col span={8}>
                  <OppSelect
                    value={this.state.filterOpp2}
                    keyPrefix={'right_opp'}
                    oppApiUrl={'/api/ki/opp'}
                    onChange={value => {
                      this.fetchData(this.state.filterOpp1, value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Link to={`/ki/stat?clid_opp1=${this.state.filterOpp1}&clid_opp2=${this.state.filterOpp2}`} >
                    <Button
                      type="primary"
                      style={{ width: '100%', marginTop: '10px' }}
                    >
                      Статистика пары
                  </Button>
                  </Link>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col {...topColResponsiveProps}>
            <Card title={'Общая статистика'} bordered={false} style={{ minHeight: '120px', marginBottom: 24 }}>
              <Form onSubmit={this.handleSubmit}>
                <Form.Item {...formItemLayout} label={'Учитывать статистику от:'}>
                  <DatePicker
                    style={{ width: '100%' }}
                    defaultValue={moment(this.state.filterFromDate, dateFormat)} format={dateFormat}
                    onChange={(date, dateSting) => { this.onChangeFromDate(date, dateSting) }} />
                </Form.Item>
              </Form>
              <Table
                dataSource={this.state.totalStat}
                columns={this.state.totalStatColums}
                bordered={true}
                pagination={false}
                loading={this.state.loading && this.state.oppLoaded}
              />
            </Card>
          </Col>
          <Col {...topColResponsiveProps}>
            <Card title={'Доп. расчет'} bordered={false} style={{ minHeight: '120px', marginBottom: 24 }}>
              <Table
                dataSource={this.state.addAnaliz}
                columns={this.state.addAnalizColums}
                bordered={true}
                pagination={false}
                loading={this.state.loading && this.state.oppLoaded}
              />
            </Card>
          </Col>

        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card bordered={false}>
              <Table
                dataSource={this.state.analiz}
                columns={this.state.columns}
                bordered={true}
                pagination={false}
                loading={this.state.loading && this.state.oppLoaded}
                scroll={{ x: true }}
              />
            </Card>
          </Col>

        </Row>
      </div >
    )

  }
}

export default Analiz
