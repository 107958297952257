import React, { Component } from 'react';
import { Card, Form, Icon, Input, Button, Checkbox } from 'antd';
import './autorization.css';

import axios from 'axios';

import MetaTags from 'react-meta-tags';

const FormItem = Form.Item;

class Autorization extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //console.log('Received values: ', values);
        axios.post('/auth', {
          username: values.username,
          password: values.password
        })
          .then(data => {
            console.log(this.props);
            console.log(data);
            if (this.props.checkAuth) this.props.checkAuth();
          })
          .catch(err => {
            console.log(err.response);
          })
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const style = {
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'url(/img/auth_fon.jpg) fixed no-repeat center center',
      backgroundSize: 'cover'
    }

    return (
      <div style={style} >
        <MetaTags>
          <title>Bkstat.vip</title>
          <meta name="description" content="Сайт статистики ставок на киберспорт. Mortal Kombat, Fifa, Killer Instinct, MK, 21 очко" />
          <meta name="keywords" content="Mortal Kombat,Mortal Kombat X, Fifa, Killer Instinct, MK, 21 очко" />
        </MetaTags>
        <Card
          title="BKstat.VIP - Авторизация"
          bordered={true}
          style={{ borderRadius: '15px' }}
        >
          <a href="/auth/vk">
            <img style={{ width: '350px' }} src="/img/vk_long.jpg" />
          </a>
          {window.location.href.includes('localhost') && (
            <Form onSubmit={this.handleSubmit} className="login-form">
              <FormItem>
                {getFieldDecorator('username', {
                  rules: [{ required: true, message: 'Please input your username!' }],
                })(
                  <Input
                    prefix={<Icon type="user"
                      style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Username" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Please input your Password!' }],
                })(
                  <Input
                    prefix={<Icon type="lock"
                      style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password" placeholder="Password" />
                )}
              </FormItem>
              <FormItem style={{ marginBottom: '0px' }}>
                {getFieldDecorator('remember', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(
                  <Checkbox>Запомнить меня</Checkbox>
                )}
                <a className="login-form-forgot" href="">Забыли пароль?</a>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  Войти
              </Button>
                <a href="/auth/vk">Авторизация через ВК</a>
              </FormItem>
            </Form>)}
        </Card>
      </div >
    )
  }
}

const AutorizationLoginForm = Form.create()(Autorization);

export { AutorizationLoginForm, Autorization }