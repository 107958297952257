import React, { Component } from 'react';

import { Table, Card } from 'antd';

const addAnalizColumsTmpl = [
  {
    title: `R`,
    className: 'dobiv_r',
    children: [
      { title: `Об.`, dataIndex: `r`, align: 'center', className: 'dobiv_r' },
      { title: `П1`, dataIndex: `rp1`, align: 'center', className: 'dobiv_r' },
      { title: `П2`, dataIndex: `rp2`, align: 'center', className: 'dobiv_r' }
    ].map(child => ({
      ...child,
      render: (text, record, index) => {
        const textStyle = {};
        if (Number(text) >= 1 && Number(text) < 2) {
          textStyle.backgroundColor = '#AAAAAA';
        }
        return <div style={textStyle}>{text}</div>;
      }
    }))
  },
  {
    title: `A`,
    className: 'dobiv_a',
    children: [
      { title: `Об.`, dataIndex: `a`, align: 'center', className: 'dobiv_a' },
      { title: `П1`, dataIndex: `ap1`, align: 'center', className: 'dobiv_a' },
      { title: `П2`, dataIndex: `ap2`, align: 'center', className: 'dobiv_a' }
    ].map(child => ({
      ...child,
      render: (text, record, index) => {
        const textStyle = {};
        if (Number(text) >= 1 && Number(text) < 4) {
          textStyle.backgroundColor = '#FFAAAA';
        }
        return <div style={textStyle}>{text}</div>;
      }
    }))
  },
  {
    title: `S`,
    className: 'dobiv_s',
    children: [
      { title: `Об.`, dataIndex: `s`, align: 'center', className: 'dobiv_s' },
      { title: `П1`, dataIndex: `sp1`, align: 'center', className: 'dobiv_s' },
      { title: `П2`, dataIndex: `sp2`, align: 'center', className: 'dobiv_s' }
    ].map(child => ({
      ...child,
      render: (text, record, index) => {
        const textStyle = {};
        if (Number(text) >= 1 && Number(text) <= 6) {
          textStyle.backgroundColor = '#AAFFAA';
        }
        return <div style={textStyle}>{text}</div>;
      }
    }))
  },
  {
    title: `U`,
    className: 'dobiv_u',
    children: [
      { title: `Об.`, dataIndex: `u`, align: 'center', className: 'dobiv_u' },
      { title: `П1`, dataIndex: `up1`, align: 'center', className: 'dobiv_u' },
      { title: `П2`, dataIndex: `up2`, align: 'center', className: 'dobiv_u' }
    ].map(child => ({
      ...child,
      render: (text, record, index) => {
        const textStyle = {};
        if (Number(text) >= 1 && Number(text) <= 5) {
          textStyle.backgroundColor = '#AA00FF';
        }
        return <div style={textStyle}>{text}</div>;
      }
    }))
  },
  {
    title: `Время`,
    dataIndex: 'time'
  }
].map(coll => ({ ...coll, align: 'center' }));

export default class AvgStat extends Component {
  render() {
    const { loading, data } = this.props;

    const addAnalizColums = addAnalizColumsTmpl.map(item => item);

    return (
      <Card title="Среднее" style={{ marginBottom: 12 }} size="small">
        <Table
          style={{ marginTop: '10px' }}
          dataSource={data}
          columns={addAnalizColums}
          bordered
          pagination={false}
          loading={loading}
        />
      </Card>
    );
  }
}
