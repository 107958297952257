import React, { Component } from "react";
import { Row, Col, Card, Button, Switch } from "antd";
import MetaTags from "react-meta-tags";
import { inject, observer } from "mobx-react";
import { Select, Option } from "../../Common/Select";
import { StatsTableMK, StatColumnVisibleChanger } from "../../Common";
// import Math from "mathjs";
import AvgStat from "./AvgStat";
import BotPrognoz from "./BotPrognoz";

const roundResultMap = {
  0: "",
  10: "П1",
  11: "П1 R",
  12: "П1 F",
  13: "П1 B",
  14: "П1 FS",
  15: "П1 BB",
  16: "П1 A",
  17: "П1 H",
  20: "П2",
  21: "П2 R",
  22: "П2 F",
  23: "П2 B",
  24: "П2 FS",
  25: "П2 BB",
  26: "П2 A",
  27: "П2 H",
  1: "R",
  2: "F",
  3: "B",
  4: "FS",
  5: "BB",
  6: "A",
  7: "H",
};

const roundResultMapFunc = ({ value }) => {
  let resultText = "";
  resultText =
    typeof value.v !== "undefined" &&
      typeof roundResultMap[value.v] !== "undefined"
      ? roundResultMap[value.v]
      : resultText;
  resultText =
    typeof value.wt !== "undefined" && value.wt === 1
      ? `${resultText}/FW`.replace("R/", "")
      : resultText;
  return resultText;
};

const RoundFilter = (roundIndex, row) => {
  const { filter, onChange } = row;
  return (
    <Select
      defaultValue={filter && filter.value ? filter.value : 0}
      value={filter && filter.value ? filter.value : 0}
      style={{ width: "100%", minWidth: "50px" }}
      onChange={value => {
        onChange(value);
      }}
    >
      {Object.keys(roundResultMap).map(listID => (
        <Option key={`filter_${roundIndex}_${listID}`} value={listID}>
          {roundResultMap[listID]}
        </Option>
      ))}
    </Select>
  );
};

const napravFilter = row => {
  const { filter, onChange } = row;
  return (
    <Select
      defaultValue={filter && filter.value ? filter.value : 0}
      value={filter && filter.value ? filter.value : 0}
      style={{ width: "100%", minWidth: "30px" }}
      onChange={value => {
        onChange(value);
      }}
    >
      <Option key={`filter__naprav_0`} value="0">
        нет
      </Option>
      <Option key={`filter__naprav_1`} value="1">
        К
      </Option>
      <Option key={`filter__naprav_2`} value="2">
        С
      </Option>
      <Option key={`filter__naprav_3`} value="3">
        З
      </Option>
      <Option key={`filter__naprav_4`} value="4">
        Ж
      </Option>
    </Select>
  );
};

@inject("mkStore") @observer
class Stats extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    const columns = [
      {
        Header: "#пп",
        key: "n",
        accessor: "n",
        noHide: true,
        visible: true,
        width: 30,
        Filter: row => <div />
      },
      {
        Header: "#",
        key: "naprav",
        accessor: "naprav",
        type: "custom",
        noHide: true,
        visible: true,
        width: 30,
        Filter: !user || !user.vip ? <div /> : row => napravFilter(row),
        Cell: row => {
          const bColors = {
            "1": "#FF0000",
            "2": "#00b6ff",
            "3": "#00ff19",
            "4": "#ffff00"
          };
          const cellStyle = {
            backgroundColor: bColors[row.row.naprav.v] || "none",
            width: "100%",
            height: "100%"
          };
          return <div style={cellStyle} />;
        }
      },
      {
        Header: "Анализ",
        key: "analiz",
        noHide: true,
        type: "custom",
        visible: true,
        width: 70,
        Filter: row => <div />,
        Cell: row => {
          const cellStyle = {
            textAlign: "center",
            width: "100%",
            height: "100%"
          };
          return (
            <div style={cellStyle}>
              <Button
                type="primary"
                icon="line-chart"
                onClick={() => {
                  const url_params = [
                    `opp1=${row.row.clid_opp1.v}`,
                    `opp2=${row.row.clid_opp2.v}`,
                    `liga=${row.row.champId.v}`
                  ]
                  const win = window.open(
                    `/mk/analiz?${url_params.join('&')}`,
                    "_blank"
                  );
                  win.focus();
                }}
              />
            </div>
          );
        }
      },
      {
        Header: "Дата и время",
        key: "dateStart",
        accessor: "dateStart",
        visible: true,
        width: 100
      },
      {
        Header: "Лига",
        accessor: "champId",
        width: 180,
        type: "liga",
        visible: true
      },
      {
        Header: "Пара",
        key: "para",
        visible: true,
        HeaderSelectList: {
          no: "",
          win: "Победитель",
          F: "Фаталити",
          B: "Бруталити"
        },
        selectedBacklight: "win",
        columns: [
          {
            Header: "Левый",
            accessor: "clid_opp1",
            width: 130,
            type: "opp",
            Filter: !user || !user.vip ? <div /> : null
          },
          {
            Header: "Правый",
            accessor: "clid_opp2",
            width: 130,
            type: "opp",
            Filter: !user || !user.vip ? <div /> : null
          }
        ].map(col => ({
          ...col,
          cellStyle2: (textStyle, cellData) => {
            const newTextStyle = { ...textStyle };
            const { original, column } = cellData;
            const { selectedBacklight } = column;
            if (selectedBacklight === "no") return newTextStyle;
            switch (selectedBacklight) {
              case "win":
                if (original.champId.v === 1961974) {
                  if (
                    original.result.v.includes("1:") &&
                    column.id === "clid_opp1"
                  )
                    newTextStyle.backgroundColor = "#A3CCFA";
                  if (
                    original.result.v.includes(":1") &&
                    column.id === "clid_opp2"
                  )
                    newTextStyle.backgroundColor = "#A3CCFA";
                } else {
                  if (
                    original.result.v.includes("5:") &&
                    column.id === "clid_opp1"
                  )
                    newTextStyle.backgroundColor = "#A3CCFA";
                  if (
                    original.result.v.includes(":5") &&
                    column.id === "clid_opp2"
                  )
                    newTextStyle.backgroundColor = "#A3CCFA";
                }
                break;
              case "F":
                const doF = [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce(
                  (result, roundIndex) => {
                    if (original[`r${roundIndex}`].dobiv === "F") {
                      if (
                        column.id === "clid_opp1" &&
                        original[`r${roundIndex}`].winner === 1
                      )
                        return true;
                      if (
                        column.id === "clid_opp2" &&
                        original[`r${roundIndex}`].winner === 2
                      )
                        return true;
                    }
                    return result;
                  },
                  false
                );
                if (doF) newTextStyle.backgroundColor = "#FBA7A2";
                break;
              case "B":
                const doB = [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce(
                  (result, roundIndex) => {
                    if (original[`r${roundIndex}`].dobiv === "B") {
                      if (
                        column.id === "clid_opp1" &&
                        original[`r${roundIndex}`].winner === 1
                      )
                        return true;
                      if (
                        column.id === "clid_opp2" &&
                        original[`r${roundIndex}`].winner === 2
                      )
                        return true;
                    }
                    return result;
                  },
                  false
                );
                if (doB) newTextStyle.backgroundColor = "#8DD98D";
                break;
              default:
            }
            return newTextStyle;
          }
        }))
      },
      {
        Header: "Результат",
        key: "result",
        accessor: "result",
        width: 50,
        visible: true,
        cellStyle: (textStyle, value, row, column) => {
          if (typeof value.v !== "undefined") {
            if (value.v.indexOf(":5") === -1 && value.v.indexOf("5:") === -1) {
              textStyle.backgroundColor = "#FFFF55";
            }
            if (row.champId && row.champId.v === 1961974) {
              if (value.v.indexOf("1:") !== -1) {
                textStyle.backgroundColor = "#000000";
                textStyle.color = "#FFFFFF";
              }
              if (value.v.indexOf(":1") !== -1) {
                textStyle.backgroundColor = "#FD7F01";
              }
            } else {
              if (value.v.indexOf("5:") !== -1) {
                textStyle.backgroundColor = "#000000";
                textStyle.color = "#FFFFFF";
              }
              if (value.v.indexOf(":5") !== -1) {
                textStyle.backgroundColor = "#FD7F01";
              }
            }
            if (value.v === "5:0" || value.v === "0:5") {
              textStyle.backgroundColor = "#628DF5";
            }
          }
          return textStyle;
        }
      },
      {
        Header: "Раунды",
        key: "rounds",
        visible: true,
        HeaderSelectList: {
          no: "",
          F_B_W: "Фаталити+Бруталити+Чистая победа",
          F_B: "Фаталити+Бруталити",
          F: "Фаталити",
          B: "Бруталити",
          W: "Чистая победа",
          P1P2: "П1+П2",
          P1: "П1",
          P2: "П2",
          out: "Победил аутсайдер"
        },
        selectedBacklight: "F_B_W",
        columns: [1, 2, 3, 4, 5, 6, 7, 8, 9].map(roundIndex => {
          return {
            width: 50,
            accessor: `r${roundIndex}`,
            Header: `${roundIndex}`,
            dic: roundResultMapFunc,
            Filter:
              !user || !user.vip ? (
                <div />
              ) : (
                row => RoundFilter(roundIndex, row)
              ),
            cellStyle2: (textStyle, cellData) => {
              const newTextStyle = { ...textStyle };
              const { value, original, column } = cellData;
              const { selectedBacklight } = column;

              switch (selectedBacklight) {
                case "F_B_W":
                  if (value.wt) {
                    newTextStyle.backgroundColor = "rgb(255, 200, 0)";
                    if (value.dobiv === "F")
                      newTextStyle.background =
                        "linear-gradient(to left,rgb(255, 200, 0),#FBA7A2)";
                    if (value.dobiv === "B")
                      newTextStyle.background =
                        "linear-gradient(to left,rgb(255, 200, 0),#8DD98D)";
                  } else {
                    if (value.dobiv === "F")
                      newTextStyle.backgroundColor = "#FBA7A2";
                    if (value.dobiv === "B")
                      newTextStyle.backgroundColor = "#8DD98D";
                  }
                  if (value.dobiv === "FS")
                    newTextStyle.backgroundColor = "#5ed1f7";
                  if (value.dobiv === "BB")
                    newTextStyle.backgroundColor = "#db94ff";
                  if (value.dobiv === "A")
                    newTextStyle.backgroundColor = "#7aff59";
                  if (value.dobiv === "H")
                    newTextStyle.backgroundColor = "#ffff59";
                  break;
                case "F_B":
                  if (value.dobiv === "F")
                    newTextStyle.backgroundColor = "#FBA7A2";
                  if (value.dobiv === "B")
                    newTextStyle.backgroundColor = "#8DD98D";
                  break;
                case "F":
                  if (value.dobiv === "F")
                    newTextStyle.backgroundColor = "#FBA7A2";
                  break;
                case "B":
                  if (value.dobiv === "B")
                    newTextStyle.backgroundColor = "#8DD98D";
                  break;
                case "W":
                  if (value.wt)
                    newTextStyle.backgroundColor = "rgb(255, 200, 0)";
                  break;
                case "out":
                  if (original.sp1.v > original.sp2.v && value.winner === 1) {
                    newTextStyle.backgroundColor = "#000000";
                    newTextStyle.color = "#FFFFFF";
                  }
                  if (original.sp1.v < original.sp2.v && value.winner === 2) {
                    newTextStyle.backgroundColor = "#000000";
                    newTextStyle.color = "#FFFFFF";
                  }
                  break;
                case "P1P2":
                  if (value.winner === 1) {
                    newTextStyle.backgroundColor = "#000000";
                    newTextStyle.color = "#FFFFFF";
                  }
                  if (value.winner === 2)
                    newTextStyle.backgroundColor = "#FD7F01";
                  break;
                case "P1":
                  if (value.winner === 1) {
                    newTextStyle.backgroundColor = "#000000";
                    newTextStyle.color = "#FFFFFF";
                  }
                  break;
                case "P2":
                  if (value.winner === 2)
                    newTextStyle.backgroundColor = "#FD7F01";
                  break;
                default:
              }
              return newTextStyle;
            }
          };
        })
      },
      {
        Header: "Кефы раунд",
        key: "kef_rounds",
        visible: true,
        onlyVip: true,
        HeaderSelectList: {
          no: "",
          "1": "1 раунд",
          "2": "2 раунд",
          "3": "3 раунд",
          "4": "4 раунд",
          "5": "5 раунд",
          "6": "6 раунд",
          "7": "7 раунд",
          "8": "8 раунд",
          "9": "9 раунд"
        },
        columns: [
          { Header: `П1`, accessor: `sp1` },
          { Header: `П2`, accessor: `sp2` }
        ].map(item => {
          return {
            ...item,
            cellStyle2: (textStyle, cellData) => {
              const newTextStyle = { ...textStyle };
              const { column, original } = cellData;

              const { selectedBacklight, id } = column;

              if (selectedBacklight === "no") return newTextStyle;
              if (
                id === "sp1" &&
                original[`r${selectedBacklight}`].winner === 1
              ) {
                newTextStyle.backgroundColor = "#C6B199";
              }
              if (
                id === "sp2" &&
                original[`r${selectedBacklight}`].winner === 2
              ) {
                newTextStyle.backgroundColor = "#C6B199";
              }
              return newTextStyle;
            }
          };
        })
      },
      {
        Header: "Кефы добив.",
        key: "kef_dobiv",
        visible: true,
        onlyVip: true,
        columns: [
          { Header: `R`, accessor: `srr` },
          { Header: `F`, accessor: `sfr` },
          { Header: `B`, accessor: `sbr` },
          { Header: `FS`, accessor: `sfsr` },
          { Header: `BB`, accessor: `sbbr` },
          { Header: `A`, accessor: `sar` },
          { Header: `H`, accessor: `shr` }
        ]
      },
      {
        Header: "Продолжительность",
        key: "times",
        onlyVip: true,
        HeaderSelectList: {
          no: "",
          min: "Меньшее",
          avg: "Среднее",
          max: "Максимальное",
          min_avg_max: "Мин + Сред + Макс"
        },
        selectedBacklight: "min_avg_max",
        columns: [1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => {
          return {
            Header: `${index}`,
            accessor: `r${index}time`,
            dic: { 0: " " },
            cellStyle2: (textStyle, cellData) => {
              const newTextStyle = { ...textStyle };
              const { value, original, column } = cellData;

              if (!value.v || value.v === "0") return newTextStyle;

              const { selectedBacklight } = column;

              if (
                selectedBacklight.includes("min") ||
                selectedBacklight.includes("avg")
              ) {
                if (
                  original.timep2m &&
                  original.timep2m.v &&
                  value.v < original.timep2m.v
                ) {
                  newTextStyle.backgroundColor = "#AAFFAA";
                }
              }
              if (selectedBacklight.includes("min")) {
                if (
                  original.timep1m &&
                  original.timep1m.v &&
                  value.v < original.timep1m.v
                ) {
                  newTextStyle.backgroundColor = "#00AA00";
                }
              }
              if (
                selectedBacklight.includes("max") ||
                selectedBacklight.includes("avg")
              ) {
                if (
                  original.timep2m &&
                  original.timep2m.v &&
                  value.v > original.timep2m.v
                ) {
                  newTextStyle.backgroundColor = "#44b4ff";
                }
              }

              if (selectedBacklight.includes("max")) {
                if (
                  original.timep3m &&
                  original.timep3m.v &&
                  value.v > original.timep3m.v
                ) {
                  newTextStyle.backgroundColor = "#0060dd";
                  newTextStyle.color = "#FFFFFF";
                }
              }

              return newTextStyle;
            }
          };
        })
      },

      {
        Header: "Время мин",
        key: "timeMin",
        onlyVip: true,
        columns: [
          { Header: "М", accessor: `time1m` },
          { Header: "время", accessor: `timep1m` },
          { Header: "Б", accessor: `time1b` }
        ]
      },
      {
        Header: "Время сред",
        key: "timeAvg",
        onlyVip: true,
        columns: [
          { Header: "М", accessor: `time2m` },
          { Header: "время", accessor: `timep2m` },
          { Header: "Б", accessor: `time2b` }
        ]
      },
      {
        Header: "Время макс",
        key: "timeMax",
        onlyVip: true,
        columns: [
          { Header: "М", accessor: `time3m` },
          { Header: "время", accessor: `timep3m` },
          { Header: "Б", accessor: `time3b` }
        ]
      },
      {
        Header: "Кефы тотал F",
        key: "kef_total_f",
        onlyVip: true,
        HeaderSelectList: {
          no: "",
          yes: "Был проход"
        },
        selectedBacklight: "yes",
        columns: [
          { Header: `0,5М`, accessor: `tf05m` },
          { Header: `0,5Б`, accessor: `tf05b` },
          { Header: `1,5М`, accessor: `tf15m` },
          { Header: `1,5Б`, accessor: `tf15b` },
          { Header: `2,5М`, accessor: `tf25m` },
          { Header: `2,5Б`, accessor: `tf25b` },
          { Header: `3,5М`, accessor: `tf35m` },
          { Header: `3,5Б`, accessor: `tf35b` },
          { Header: `4,5М`, accessor: `tf45m` },
          { Header: `4,5Б`, accessor: `tf45b` }
        ].map(col => ({
          ...col,
          cellStyle2: (textStyle, cellData) => {
            const newTextStyle = { ...textStyle };
            const { value, original, column } = cellData;
            const { selectedBacklight, id } = column;
            if (selectedBacklight !== "yes") return newTextStyle;
            if (value.v === 0) return newTextStyle;

            const totalF = [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce(
              (result, roundIndex) => {
                if (
                  original[`r${roundIndex}`] &&
                  original[`r${roundIndex}`].dobiv === "F"
                )
                  return result + 1;
                return result;
              },
              0
            );

            const val =
              Number(
                id
                  .replace("tf", "")
                  .replace("m", "")
                  .replace("b", "")
              ) / 10;
            if (id.includes("m") && totalF < val)
              newTextStyle.backgroundColor = "#FBA7A2";
            if (id.includes("b") && totalF > val)
              newTextStyle.backgroundColor = "#FBA7A2";

            return newTextStyle;
          }
        }))
      },
      {
        Header: "Кефы тотал B",
        key: "kef_total_b",
        onlyVip: true,
        HeaderSelectList: {
          no: "",
          yes: "Был проход"
        },
        selectedBacklight: "yes",
        columns: [
          { Header: `0,5М`, accessor: `tb05m` },
          { Header: `0,5Б`, accessor: `tb05b` },
          { Header: `1,5М`, accessor: `tb15m` },
          { Header: `1,5Б`, accessor: `tb15b` },
          { Header: `2,5М`, accessor: `tb25m` },
          { Header: `2,5Б`, accessor: `tb25b` },
          { Header: `3,5М`, accessor: `tb35m` },
          { Header: `3,5Б`, accessor: `tb35b` }
        ].map(col => ({
          ...col,
          cellStyle2: (textStyle, cellData) => {
            const newTextStyle = { ...textStyle };
            const { value, original, column } = cellData;
            const { selectedBacklight, id } = column;
            if (selectedBacklight !== "yes") return newTextStyle;
            if (value.v === 0) return newTextStyle;

            const totalB = [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce(
              (result, roundIndex) => {
                if (
                  original[`r${roundIndex}`] &&
                  original[`r${roundIndex}`].dobiv === "B"
                )
                  return result + 1;
                return result;
              },
              0
            );

            const val =
              Number(
                id
                  .replace("tb", "")
                  .replace("m", "")
                  .replace("b", "")
              ) / 10;
            const _id = id.replace("tb", "");
            if (_id.includes("m") && totalB < val)
              newTextStyle.backgroundColor = "#8DD98D";
            if (_id.includes("b") && totalB > val)
              newTextStyle.backgroundColor = "#8DD98D";

            return newTextStyle;
          }
        }))
      },
      {
        Header: "Общий тотал",
        key: "total_common",
        onlyVip: true,
        HeaderSelectList: {
          no: "",
          yes: "Был проход"
        },
        selectedBacklight: "yes",
        columns: [
          { Header: `5,5М`, accessor: `t55m` },
          { Header: `5,5Б`, accessor: `t55b` },
          { Header: `6,5М`, accessor: `t65m` },
          { Header: `6,5Б`, accessor: `t65b` },
          { Header: `7,5М`, accessor: `t75m` },
          { Header: `7,5Б`, accessor: `t75b` },
          { Header: `8,5М`, accessor: `t85m` },
          { Header: `8,5Б`, accessor: `t85b` }
        ].map(col => ({
          ...col,
          cellStyle2: (textStyle, cellData) => {
            const newTextStyle = { ...textStyle };
            const { value, original, column } = cellData;
            const { selectedBacklight, id } = column;
            if (selectedBacklight !== "yes") return newTextStyle;
            if (value.v === 0) return newTextStyle;

            const [resultP1, resultP2] = original.result.v.split(":");
            if (typeof resultP1 === "undefined") return newTextStyle;
            if (typeof resultP2 === "undefined") return newTextStyle;

            const total = Number(resultP1) + Number(resultP2);

            const val =
              Number(
                id
                  .replace("t", "")
                  .replace("m", "")
                  .replace("b", "")
              ) / 10;
            if (id.includes("m") && total < val)
              newTextStyle.backgroundColor = "#A3CCFA";
            if (id.includes("b") && total > val)
              newTextStyle.backgroundColor = "#A3CCFA";

            return newTextStyle;
          }
        }))
      },
      {
        Header: "Инд. тотал П1",
        key: "i1_total_common",
        onlyVip: true,
        HeaderSelectList: {
          no: "",
          yes: "Был проход"
        },
        selectedBacklight: "yes",
        columns: [
          { Header: `0,5М`, accessor: `i1t05m` },
          { Header: `0,5Б`, accessor: `i1t05b` },
          { Header: `1,5М`, accessor: `i1t15m` },
          { Header: `1,5Б`, accessor: `i1t15b` },
          { Header: `2,5М`, accessor: `i1t25m` },
          { Header: `2,5Б`, accessor: `i1t25b` },
          { Header: `3,5М`, accessor: `i1t35m` },
          { Header: `3,5Б`, accessor: `i1t35b` },
          { Header: `4,5М`, accessor: `i1t45m` },
          { Header: `4,5Б`, accessor: `i1t45b` },
        ].map(col => ({
          ...col,
          cellStyle2: (textStyle, cellData) => {
            const newTextStyle = { ...textStyle };
            const { value, original, column } = cellData;
            const { selectedBacklight, id } = column;
            if (selectedBacklight !== "yes") return newTextStyle;
            if (value.v === 0) return newTextStyle;

            const [resultP1, resultP2] = original.result.v.split(":");
            if (typeof resultP1 === "undefined") return newTextStyle;
            if (typeof resultP2 === "undefined") return newTextStyle;

            const total = Number(resultP1);

            const val =
              Number(
                id
                  .replace("i1t", "")
                  .replace("m", "")
                  .replace("b", "")
              ) / 10;
            if (id.includes("m") && total < val)
              newTextStyle.backgroundColor = "#A3CCFA";
            if (id.includes("b") && total > val)
              newTextStyle.backgroundColor = "#A3CCFA";

            return newTextStyle;
          }
        }))
      },
      {
        Header: "Инд. тотал П2",
        key: "i2_total_common",
        onlyVip: true,
        HeaderSelectList: {
          no: "",
          yes: "Был проход"
        },
        selectedBacklight: "yes",
        columns: [
          { Header: `0,5М`, accessor: `i2t05m` },
          { Header: `0,5Б`, accessor: `i2t05b` },
          { Header: `1,5М`, accessor: `i2t15m` },
          { Header: `1,5Б`, accessor: `i2t15b` },
          { Header: `2,5М`, accessor: `i2t25m` },
          { Header: `2,5Б`, accessor: `i2t25b` },
          { Header: `3,5М`, accessor: `i2t35m` },
          { Header: `3,5Б`, accessor: `i2t35b` },
          { Header: `4,5М`, accessor: `i2t45m` },
          { Header: `4,5Б`, accessor: `i2t45b` },
        ].map(col => ({
          ...col,
          cellStyle2: (textStyle, cellData) => {
            const newTextStyle = { ...textStyle };
            const { value, original, column } = cellData;
            const { selectedBacklight, id } = column;
            if (selectedBacklight !== "yes") return newTextStyle;
            if (value.v === 0) return newTextStyle;

            const [resultP1, resultP2] = original.result.v.split(":");
            if (typeof resultP1 === "undefined") return newTextStyle;
            if (typeof resultP2 === "undefined") return newTextStyle;

            const total = Number(resultP2);

            const val =
              Number(
                id
                  .replace("i2t", "")
                  .replace("m", "")
                  .replace("b", "")
              ) / 10;
            if (id.includes("m") && total < val)
              newTextStyle.backgroundColor = "#A3CCFA";
            if (id.includes("b") && total > val)
              newTextStyle.backgroundColor = "#A3CCFA";

            return newTextStyle;
          }
        }))
      },
      {
        Header: "Кефы 1x2",
        key: "kef_1x2",
        onlyVip: true,
        columns: [
          { Header: `П1`, accessor: `x2p1` },
          { Header: `П2`, accessor: `x2p2` }
        ]
      },
      {
        Header: "ЧП",
        key: "fw",
        onlyVip: true,
        HeaderSelectList: {
          no: "",
          yes: "Был проход"
        },
        selectedBacklight: "yes",

        columns: [
          { Header: `ДА`, accessor: `scp` },
          {
            Header: `0.5Б`,
            accessor: `tfw05b`,
            cellStyle2: (textStyle, cellData) => {
              const newTextStyle = { ...textStyle };
              const { original, column } = cellData;
              const { selectedBacklight } = column;

              if (selectedBacklight !== "yes") return newTextStyle;

              const fwDo = [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce(
                (result, index) => {
                  if (original[`r${index}`] && original[`r${index}`].wt)
                    return true;
                  return result;
                },
                false
              );

              if (fwDo) newTextStyle.backgroundColor = "#0060dd";

              return newTextStyle;
            }
          }
        ]
      }
    ];

    this.state = {
      columns,
      botPrognoz: [],
    };
  }

  componentDidMount() {
    this.loadSettings();
  }

  handleChangeVisibleColumn(visibleColumnKeys) {
    const { columns } = this.state;
    const newColumns = columns.map(column => ({
      ...column,
      visible: column.noHide ? true : visibleColumnKeys.includes(column.key)
    }));
    this.setState({
      columns: newColumns
    });
    this.saveSettings({ columns: newColumns });
  }

  loadSettings() {
    const settingSrc = localStorage.getItem(`MK_store_table_visible`);
    if (settingSrc === null) return false;
    try {
      const setting = JSON.parse(settingSrc);
      console.log(setting);
      if (!setting.visibleColumn) return false;
      const visObj = setting.visibleColumn.reduce((result, vis) => {
        result[vis.key] = vis.visible;
        return result;
      }, {});

      const newColumns = this.state.columns.map(col => {
        if (typeof visObj[col.key] !== "undefined")
          col.visible = visObj[col.key];
        return col;
      });
      this.setState({
        columns: newColumns
      });
    } catch (err) {
      console.log(err);
    }
    return true;
  }

  saveSettings(customSetting = {}) {
    const { columns } = this.state;
    const cols = customSetting.columns ? customSetting.columns : columns;

    const setting = {
      visibleColumn: cols.map(column => ({
        key: column.key,
        visible: column.visible ? column.visible : false
      }))
    };

    localStorage.setItem(`MK_store_table_visible`, JSON.stringify(setting));
  }

  render() {
    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 8
    };

    const presetColumns = [
      {
        key: "ishod",
        title: "Исход",
        columnsKeys: [
          "dateStart",
          "para",
          "result",
          "rounds",
          "kef_rounds",
          "kef_dobiv",
          "kef_1x2",
          "fw"
        ]
      },
      {
        key: "time",
        title: "Время",
        columnsKeys: [
          "dateStart",
          "para",
          "result",
          "times",
          "timeMin",
          "timeAvg",
          "timeMax"
        ]
      },
      {
        key: "total",
        title: "Тотал",
        columnsKeys: [
          "dateStart",
          "para",
          "result",
          "kef_total_f",
          "kef_total_b",
          "total_common"
        ]
      }
    ];

    const { user } = this.props;
    let { columns } = this.state;
    const { setAutoUpdateStats, autoUpdateStats } = this.props.mkStore;

    if (user && user.vip === 0) {
      columns = columns.filter(column => !column.onlyVip);
    }
    const visibleColumns = columns.filter(column => column.visible);

    return (
      <div style={{ minHeight: 360 }}>
        <MetaTags>
          <title>Статистика MK X</title>
          <meta
            name="description"
            content="Статистика киберигр Mortal Kombat X"
          />
          <meta
            name="keywords"
            content="Mortal Kombat,Mortal Kombat X, MK, аналитика"
          />
        </MetaTags>
        <Row gutter={16}>
          <Col {...topColResponsiveProps}>
            <StatColumnVisibleChanger
              storagePrefix="mk"
              columns={columns}
              presetColumns={presetColumns}
              handleChangeVisibleColumn={(key, value) => {
                this.handleChangeVisibleColumn(key, value);
              }}
            />
          </Col>
          <Col {...topColResponsiveProps}>
            <Card title="Настройки" size="small" style={{ marginBottom: 3 }}>
              <div style={{ padding: "5px 0", whiteSpace: "nowrap" }}>
                <Switch
                  key={`auto_update`}
                  checked={autoUpdateStats}
                  onChange={value => {
                    // this.setState({
                    //   autoUpdateStats: value
                    // });
                    setAutoUpdateStats(value);
                    // localStorage.setItem(`autoUpdateStats`, value);
                  }}
                />
                <span style={{ padding: "5px", whiteSpace: "nowrap" }}>
                  Автообновление статистики
                </span>
              </div>
            </Card>
            <AvgStat user={user} />
          </Col>
          {(user && user.vip !== 0 && (<Col {...topColResponsiveProps}>
            <BotPrognoz user={user} />
          </Col>))}
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card bordered={false}>
              <StatsTableMK
                user={user}
                columns={visibleColumns}
                localStoreKey="MK_store"
              // dataApiUrl="/api/mk/stat"
              // oppApiUrl="/api/mk/opp"
              // wsUrl={autoUpdateStats ? "/ws/mk" : false}
              // defaultFilter = {{
              //   champId: 1252965
              // }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Stats;
