import { action, makeAutoObservable, autorun } from "mobx"

class MenuStore {
  init = false

  menuShow = true

  constructor() {
    makeAutoObservable(this)

    this.changeMenuState = this.changeMenuState.bind(this)

    autorun(() => { this.loadSettings() })
    autorun(() => { this.saveSettings() })
  }

  @action changeMenuState() {
    this.menuShow = !this.menuShow
  }

  saveSettings() {
    if (!this.init) return
    const setting = {
      menuShow: this.menuShow
    }

    localStorage.setItem(
      `menu_setting`,
      JSON.stringify(setting)
    );
  }

  loadSettings() {
    if (this.init) return

    const settingSrc = localStorage.getItem(`menu_setting`);
    if (settingSrc !== null) {

      try {
        const setting = JSON.parse(settingSrc);

        this.menuShow = setting.menuShow

      } catch (err) {
        console.error(err);
        localStorage.removeItem(`menu_setting`);
      }

    }
    this.init = true
  }

}

export default new MenuStore()