import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ReactApexChart from "react-apexcharts";
import { Row, Col, Switch, Card, Button, Icon } from "antd";

import { Select, Option } from "../../Common/Select";

@inject("fifaStore")
@observer
class StatGraph extends Component {
  render() {
    const { series, yMax, yMin, groupSize, graphType} = this.props.fifaStore.graph;
    const { setGroupSize,setGraphType } = this.props.fifaStore;
    const graphOptions = {
      stroke: {
        width: 5,
        curve: "smooth",
      },
      markers: {
        size: 4,
        colors: ['#FFA41B'],
        strokeColors: '#fff',
        strokeWidth: 1,
        hover: {
          size: 7
        }
      },
      yaxis: [
        {
          min: yMin,
          max: yMax,
        },
      ],
    };
    return (
      <Card
        // className='StatColumnVisibleChanger'
        title="Графики"
        // extra={this.headerButtons()}
        style={{ marginBottom: 0 }}
        size="small"
      >
        <Row gutter={6}>
          <Col span={6}>
            <Select
              style={{ width: "100%" }}
              value={graphType}
              onChange={(value) => {
                setGraphType(value);
              }}
            >
              <Option value={"total"}>Тотал</Option>
              <Option value={"totalP1"}>Тотал П1</Option>
              <Option value={"totalP2"}>Тотал П2</Option>
              <Option value={"time1"}>Тайм 1</Option>
              <Option value={"time2"}>Тайм 2</Option>
              <Option value={"totalP1time1"}>Тотал П1 Тайм 1</Option>
              <Option value={"totalP2time1"}>Тотал П2 Тайм 1</Option>
              <Option value={"totalP1time2"}>Тотал П1 Тайм 2</Option>
              <Option value={"totalP2time2"}>Тотал П2 Тайм 2</Option>
            </Select>
            <Select
              style={{ width: "100%" }}
              value={groupSize}
              onChange={(value) => {
                setGroupSize(value);
              }}
            >
              <Option value={1}>1 игра</Option>
              <Option value={2}>2 игры</Option>
              <Option value={3}>3 игры</Option>
              <Option value={4}>4 игры</Option>
              <Option value={5}>5 игр</Option>
              <Option value={6}>6 игр</Option>
              <Option value={7}>7 игр</Option>
              <Option value={8}>8 игр</Option>
              <Option value={9}>9 игр</Option>
              <Option value={10}>10 игр</Option>
              <Option value={11}>11 игр</Option>
              <Option value={12}>12 игр</Option>
              <Option value={13}>13 игр</Option>
              <Option value={14}>14 игр</Option>
              <Option value={15}>15 игр</Option>
            </Select>
          </Col>
          <Col span={18}>
            <ReactApexChart
              options={graphOptions}
              series={series}
              type="line"
              height={209}
              width="100%"
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
export default StatGraph;
