import React, { Component } from 'react';
import { Select, Option } from './Select';
import axios from 'axios';

export default class LigaSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oppsItems: {},
      value: this.props.value ? this.props.value : 0
    }
  }

  extractOppDataFromSrc(data, ligaID = 0) {
    return Object.keys(data).reduce((obj, champId) => {
      obj.champs[champId] = data[champId].name;
      return obj;
    }, {
        champs: {},
        opps: {},
        oppsById: {}
      });
  }

  loadOpp() {
    const { oppApiUrl } = this.props;
    if (!window.oppCache) {
      window.oppCache = {}
    }
    if (window.oppCache[oppApiUrl]) {
      this.setState({
        oppsItems: window.oppCache[oppApiUrl]
      });
    } else {
      if (oppApiUrl) {
        axios.post(oppApiUrl, {}).then(response => {
          const data = response.data;
          this.setState({
            oppsItems: data.items
          });
          console.log('newData', data.items);
          window.oppCache[oppApiUrl] = data.items;
        }).catch(err => {
          this.setState({
            oppsItems: {}
          });
        });
      }
    }
  }

  componentDidMount() {
    this.loadOpp();
  }

  render() {
    const { oppsItems } = this.state;
    const { onChange, keyPrefix } = this.props;
    const { champs } = this.extractOppDataFromSrc(oppsItems)
    const prefixKey = `opp_${keyPrefix}`
    const value = typeof this.props.value !== "undefined" ? this.props.value : this.state.value

    return <Select
      style={{ width: '100%' }}
      value={value}
      onChange={value => {
        onChange(value);
        this.setState({
          value
        })
      }}>
      <Option key={`${prefixKey}_0`} value={0}>Все</Option>
      {
        Object.keys(champs).sort((a, b) => champs[b] > champs[a] ? -1 : 1).filter(listID => parseInt(listID, 10) !== 0).map(listID =>
          <Option key={`${prefixKey}_${listID}`} value={listID}>{champs[listID]}</Option>
        )
      }
    </Select>
  }

} 