import React from 'react';
import { Form, Input, Select, Button } from 'antd';

const FormItem = Form.Item;
const Option = Select.Option;
const durationData = ["1 month", "2 month", "3 month"];
const durationToCost = {
  "1 month": ['500'],
  "2 month": ['1000'],
  "3 month": ['1500']
};

class FormToSale extends React.Component {
  state = {
    telegramId:0,
    login:'',
    daysLeft:7
  }

  handleChangeTelegramId = (e) => {
    this.setState({
      telegramId: [e.target.value],
    });
  }

  handleChangeLogin= (e) => {
    this.setState({
      login: [e.target.value],
    });
  }

  handleDaysLeft= (e) => {
    this.setState({
      daysLeft: [e.target.value],
    });
  }


  handleAdd = () => {
    const { telegramId,login,daysLeft } = this.state

    const dataToTable = {
      telegramId,login,daysLeft
    }
    this.props.updateData(dataToTable)
  }

  handleCancel = () => {
    this.props.closeModal({ visible: false })
  }

  render() {
    const { telegramId,login,daysLeft } = this.state;
    return (
      <Form layout="horizontal" onSubmit={this.handleSubmit}>
        <FormItem>
          <Input
            value={telegramId}
            placeholder="telegramId"
            onChange={this.handleChangeTelegramId}
          />
        </FormItem>
        <FormItem>
          <Input
            value={login}
            placeholder="login"
            onChange={this.handleChangeLogin}
          />
        </FormItem>
        <FormItem>
          <Input
            defaultValue={7}
            value={daysLeft}
            placeholder="daysLeft"
            onChange={this.handleDaysLeft}
          />
        </FormItem>
        <Button key="back" onClick={this.handleCancel}>Отмена</Button>,
            <Button key="submit" type="primary" onClick={this.handleAdd}>
          Подтвердить
            </Button>
      </Form>
    );
  }
}

export { FormToSale }
