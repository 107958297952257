import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Card } from "antd";

import { StatsTableFIFA, StatColumnVisibleChanger } from "../../Common";

import StatGraph from "./StatGraph";


import MetaTags from "react-meta-tags";

const zabilGolStyle = (textStyle, value, row, column) => {
  if (value && typeof value.v !== "undefined") {
    switch (value.v) {
      case 0:
        break;
      case 1:
        textStyle.backgroundColor = "#AAFFAA";
        break;
      case 2:
        textStyle.backgroundColor = "#FFAAAA";
        break;
    }
  }
  return textStyle;
};

const zabilGolTimeStyle = (textStyle, value, row, column) => {
  console.log(value);
  if (value && typeof value.v !== "undefined") {
    if (value.v > 0 && value.v < 30) {
      textStyle.backgroundColor = "#00bfff";
    }
    if (value.v > 29) {
      textStyle.backgroundColor = "#d38d00";
    }
  }
  return textStyle;
};

const columns = [
  {
    Header: "Дата и время",
    key: "dateStart",
    accessor: "dateStart",
    visible: true,
    width: 100
  },
  {
    Header: "Лига",
    accessor: "champId",
    key: "champId",
    visible: true,
    width: 180,
    type: "liga"
  },
  {
    Header: "Пара",
    key: "para",
    visible: true,
    columns: [
      { Header: "Команда 1", accessor: "clid_opp1", width: 130, type: "opp" },
      { Header: "Команда 2", accessor: "clid_opp2", width: 130, type: "opp" }
    ]
  },
  {
    Header: "Результат",
    key: "result",
    accessor: "result",
    visible: true,
    width: 75
  },
  {
    Header: "1й тайм",
    key: "1time",
    visible: true,
    columns: [
      {
        Header: "К1",
        accessor: "r1p1",
        cellStyle: (textStyle, value, row, column) => {
          if (value && typeof value.v !== "undefined") {
            switch (value.v) {
              case 0:
                break;
              case 1:
                textStyle.backgroundColor = "#AAFFAA";
                break;
              case 2:
                textStyle.backgroundColor = "#55FF55";
                break;
              default:
                textStyle.backgroundColor = "#00AA00";
            }
          }
          return textStyle;
        }
      },
      {
        Header: "К2",
        accessor: "r1p2",
        cellStyle: (textStyle, value, row, column) => {
          if (value && typeof value.v !== "undefined") {
            switch (value.v) {
              case 0:
                break;
              case 1:
                textStyle.backgroundColor = "#FFAAAA";
                break;
              case 2:
                textStyle.backgroundColor = "#FF5555";
                break;
              default:
                textStyle.backgroundColor = "#AA0000";
            }
          }
          return textStyle;
        }
      }
    ]
  },
  {
    Header: "2й тайм",
    key: "2time",
    visible: true,
    columns: [
      {
        Header: "К1",
        accessor: "r2p1",
        cellStyle: (textStyle, value, row, column) => {
          if (value && typeof value.v !== "undefined") {
            switch (value.v) {
              case 0:
                break;
              case 1:
                textStyle.backgroundColor = "#AAFFAA";
                break;
              case 2:
                textStyle.backgroundColor = "#55FF55";
                break;
              default:
                textStyle.backgroundColor = "#00AA00";
            }
          }
          return textStyle;
        }
      },
      {
        Header: "К2",
        accessor: "r2p2",
        cellStyle: (textStyle, value, row, column) => {
          if (value && typeof value.v !== "undefined") {
            switch (value.v) {
              case 0:
                break;
              case 1:
                textStyle.backgroundColor = "#FFAAAA";
                break;
              case 2:
                textStyle.backgroundColor = "#FF5555";
                break;
              default:
                textStyle.backgroundColor = "#AA0000";
            }
          }
          return textStyle;
        }
      }
    ]
  },
  {
    Header: "1x2",
    key: "1x2",
    onlyVip: true,
    columns: [
      { Header: "П1", accessor: "x2p1" },
      { Header: "X", accessor: "x2x" },
      { Header: "П2", accessor: "x2p2" }
    ]
  },
  {
    Header: "Двойной шанс",
    key: "2shans",
    onlyVip: true,
    columns: [
      { Header: "1X", accessor: "sh21x" },
      { Header: "12", accessor: "sh212" },
      { Header: "2X", accessor: "sh22x" }
    ]
  },
  {
    Header: "1й гол",
    key: "1gol",
    onlyVip: true,
    columns: [
      {
        Header: "Забил",
        accessor: `gol1`,
        cellStyle: zabilGolStyle
      },
      { Header: "Время", accessor: `gol1time`, cellStyle: zabilGolTimeStyle }
    ]
  },
  {
    Header: "2й гол",
    key: "2gol",
    onlyVip: true,
    columns: [
      { Header: "Забил", accessor: `gol2`, cellStyle: zabilGolStyle },
      { Header: "Время", accessor: `gol2time`, cellStyle: zabilGolTimeStyle }
    ]
  },
  {
    Header: "3й гол",
    key: "3gol",
    onlyVip: true,
    columns: [
      { Header: "Забил", accessor: `gol3`, cellStyle: zabilGolStyle },
      { Header: "Время", accessor: `gol3time`, cellStyle: zabilGolTimeStyle }
    ]
  },
  {
    Header: "4й гол",
    key: "4gol",
    onlyVip: true,
    columns: [
      { Header: "Забил", accessor: `gol4`, cellStyle: zabilGolStyle },
      { Header: "Время", accessor: `gol4time`, cellStyle: zabilGolTimeStyle }
    ]
  },
  {
    Header: "Тотал",
    key: "total",
    visible: true,
    onlyVip: true,
    columns: ["0,5", "1,5", "2,5", "3,5", "4,5", "5,5"].reduce(
      (result, item) => {
        const slimItem = item.replace(",", "");
        const intItem = parseFloat(item.replace(",", "."));

        result.push({
          Header: `${item}М`,
          key: `t${slimItem}m`,
          accessor: `t${slimItem}m`,
          cellStyle: (textStyle, value, row, column) => {
            try {
              const [sp1, sp2] = row._original.result.v
                .split(":")
                .map(item => Number(item));
              if (typeof sp1 !== "undefined" && typeof sp2 !== "undefined") {
                if (sp1 + sp2 < intItem) {
                  textStyle.backgroundColor = "#A3CCFA";
                }
              }
            } catch (e) {
              console.log(e);
            }
            return textStyle;
          }
        });
        result.push({
          Header: `${item}Б`,
          key: `t${slimItem}b`,
          accessor: `t${slimItem}b`,
          cellStyle: (textStyle, value, row, column) => {
            try {
              const [sp1, sp2] = row._original.result.v
                .split(":")
                .map(item => Number(item));
              if (typeof sp1 !== "undefined" && typeof sp2 !== "undefined") {
                if (sp1 + sp2 > intItem) {
                  textStyle.backgroundColor = "#A3CCFA";
                }
              }
            } catch (e) {
              console.log(e);
            }
            return textStyle;
          }
        });
        return result;
      },
      []
    )
  },
  {
    Header: "Результативность таймов",
    key: "restime",
    onlyVip: true,
    columns: [
      {
        Header: "1>2",
        accessor: "rt1b2",
        cellStyle: (textStyle, value, row, column) => {
          if (
            row._original.r1p1.v + row._original.r1p2.v >
            row._original.r2p1.v + row._original.r2p2.v
          ) {
            textStyle.backgroundColor = "#16FF00";
          }
          return textStyle;
        }
      },
      {
        Header: "1=2",
        accessor: "rt1r2",
        cellStyle: (textStyle, value, row, column) => {
          if (
            row._original.r1p1.v + row._original.r1p2.v ===
            row._original.r2p1.v + row._original.r2p2.v
          ) {
            textStyle.backgroundColor = "#16FF00";
          }
          return textStyle;
        }
      },
      {
        Header: "1<2",
        accessor: "rt1m2",
        cellStyle: (textStyle, value, row, column) => {
          if (
            row._original.r1p1.v + row._original.r1p2.v <
            row._original.r2p1.v + row._original.r2p2.v
          ) {
            textStyle.backgroundColor = "#16FF00";
          }
          return textStyle;
        }
      }
    ]
  }
];

function clearFilerInColums(columns) {
  console.log(columns);
  return columns.map(column => {
    if (column.columns) {
      column.columns = clearFilerInColums(column.columns);
    } else {
      column.Filter = <div />;
    }
    return column;
  });
}

@inject("fifaStore") @observer
class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: columns
    };
  }

  handleChangeVisibleColumn(visibleColumnKeys) {
    const { columns } = this.state;
    const newColumns = columns.map(column => ({
      ...column,
      visible: column.noHide ? true : visibleColumnKeys.includes(column.key)
    }));
    this.setState({
      columns: newColumns
    });
    // this.saveSettings({ columns: newColumns });
  }

  render() {
    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 8
    };
    const topColResponsiveProps2 = {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 16
    };

    const { user } = this.props;
    let { columns } = this.state;
    if (user && user.vip === 0) {
      columns = columns.filter(column => !column.onlyVip);
      columns = clearFilerInColums(columns);
    }
    const visibleColumns = columns.filter(column => column.visible);

    return (
      <div style={{ padding: 14, background: "#fff", minHeight: 360 }}>
        <MetaTags>
          <title>Статистика Fifa</title>
          <meta name="description" content="Статистика киберигр Fifa" />
          <meta name="keywords" content="Fifa статистика, анализ, прогноз" />
        </MetaTags>
        <Row gutter={16}>
          <Col {...topColResponsiveProps}>
            <StatColumnVisibleChanger
              storagePrefix="mk"
              columns={columns}
              //presetColumns={presetColumns}
              handleChangeVisibleColumn={(key, value) => {
                this.handleChangeVisibleColumn(key, value);
              }}
            />
          </Col>
          <Col {...topColResponsiveProps2}>
          {(user && user.vip > 0) && (<StatGraph />)}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card bordered={false}>
              <StatsTableFIFA
                columns={visibleColumns}
                localStoreKey="FIFA_store"
                // dataApiUrl="/api/fifa/stat"
                // oppApiUrl="/api/fifa/opp"
                // wsUrl="/ws/fifa"
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Stats;
