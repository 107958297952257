import React from 'react';
import ReactTable from 'react-table';
import {  Row, Col } from 'antd';
import axios from 'axios';

import 'react-table/react-table.css';

import './users.css';


const requestData = () => new Promise((resolve, reject) => {
  axios('/api/admin/sessions').then((data) => {
    resolve(data.data);
  }).catch(err => {
    reject(err);
  })
});

class Users extends React.Component {
  constructor(props) {
    super(props);
    const columns = [
      { Header: 'username', accessor: 'username' },
      { Header: 'photoUrl', accessor: 'photoUrl', width: 600 },
      {
        Header: 'profileUrl', accessor: 'profileUrl',
        Cell: ({ value }) => <a href={value}>{value}</a>
      },
      { Header: 'vkid', accessor: 'vkid', width: 100 },
      { Header: 'vip', accessor: 'vip', width: 40 },
      { Header: 'admin', accessor: 'admin', width: 40 },
      { Header: 'key', accessor: 'key', width: 300 },


    ]

    this.state = {
      columns,
      data: [],
      pages: null,
      loading: true,
      fastPayVisible: false,
      errorVisible: false,
      errorMsg: '',
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    requestData().then(data => {
      this.setState({
        data: data.sort((a, b) => { return a.vkid < b.vkid ? 1 : -1 }),
        loading: false
      });
    }).catch(err => {
      console.log(err);
    })

  }


  // rowstyle = row => {
  //   const res = {
  //     textAlign: 'center',
  //     width: '100%',
  //     height: '100%',
  //     lineHeight: '30px'
  //   };
  //   if (row.value.bcolor) {
  //     res.backgroundColor = row.value.bcolor;
  //   }
  //   if (row.value.tcolor) {
  //     res.color = row.value.tcolor;
  //   }
  //   return res;
  // };


  fetchData(state, instance) {
    this.setState({ loading: true });
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false,
      });
    }).catch(err => {
      this.setState({
        data: [],
        pages: 0,
        loading: false,
      });
    })
  }

  render() {
    const { data, pages, loading, columns } = this.state;
    return <div>

      <Row>
        <Col>
          <ReactTable
            showPagination={false}
            columns={columns}
            manual
            data={data}
            pages={pages}
            loading={loading}
            defaultPageSize={10}
          />
        </Col>
      </Row>
    </div>
  }
}

export default Users
