import { action, makeAutoObservable, runInAction, autorun, computed } from "mobx"
import SockJS from "sockjs-client";
import { getOppFIFA, getStatFIFA, getGraphFIFA } from "../../api";

class fifaStore {
  init = false
  champs = {}
  opp = {}
  // opps = {}
  opps_py_id = {}

  autoUpdateStats = false

  stat = {
    loading: false,
    rows: [],
    pagesCount: 0,

    page: 0,
    pageSize: 10,
    sorted: [],
    filtered: [],
  }

  graph = {
    series:[],
    yMin: 0,
    yMax: 0,
    groupSize: 5,
    graphType: "total"
  }

  sockjs = null

  constructor() {
    makeAutoObservable(this)

    // this.changeMenuState = this.changeMenuState.bind(this)
    this.initStore = this.initStore.bind(this)
    this.setStatFiltered = this.setStatFiltered.bind(this)
    this.setStatPage = this.setStatPage.bind(this)
    this.setStatSorted = this.setStatSorted.bind(this)
    this.setStatPageSize = this.setStatPageSize.bind(this)
    this.setAutoUpdateStats = this.setAutoUpdateStats.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.setStatFilter = this.setStatFilter.bind(this)
    this.setGroupSize = this.setGroupSize.bind(this)
    this.setGraphType = this.setGraphType.bind(this)

    autorun(() => { this.loadSettings() })
    autorun(() => { this.fetchStat() })
    autorun(() => { this.fetchGraph() })
    autorun(() => { this.updateWS() })
    autorun(() => { this.saveSettings() })
  }

  @action initStore() {
    if (this.init) return
    this.fetchOop()
  }

  @action setStatFiltered(filtered) {
    this.stat.filtered = filtered
  }

  @action setStatPage(page) {
    this.stat.page = page

  }

  @action setStatSorted(sorted) {
    this.stat.sorted = sorted
  }

  @action setStatPageSize(pageSize) {
    this.stat.pageSize = pageSize
  }

  @action setAutoUpdateStats(auto_update_state) {
    this.autoUpdateStats = auto_update_state
  }

  @action resetFilters() {
    this.stat.filtered = this.stat.filtered.filter(item => item.id === "champId")
  }

  @action setStatFilter(filter) {
    console.log(filter)
    this.stat.filtered = filter.reduce((r, f) => {
      return [
        ...r.filter(item => item.id !== f.id),
        { id: f.id, value: f.value }
      ]
    }, this.stat.filtered)

  }

  @action setGroupSize(groupSize) {
    this.graph.groupSize = groupSize
  }
  @action setGraphType(graphType) {
    this.graph.graphType = graphType
  }

  @computed get opps() {
    const selected_champ = this.stat.filtered.reduce((chamdId, item) => item.id === "champId" ? item.value : chamdId, 0)

    return Object.keys(this.opp).reduce((obj, champId) => {
      if ((selected_champ > 0) && (selected_champ !== champId)) { return obj; }
      return this.opp[champId].opp.reduce((opps, oppObj) => {
        if (opps[oppObj.opp] && opps[oppObj.opp] !== `${oppObj.clid_opp}`) {
          opps[oppObj.opp] = `${opps[oppObj.opp]},${oppObj.clid_opp}`
        } else {
          opps[oppObj.opp] = `${oppObj.clid_opp}`
        }
        return opps;
      }, obj);
    }, {});
  }

  @computed get champs_inv(){
    return Object.keys(this.champs).reduce((obj,champId)=>{
      obj[this.champs[champId]]=champId
      return obj
    },{})
  }

  fetchStat(showSpinner = true) {
    if (!this.init) return

    runInAction(() => {
      this.stat.loading = showSpinner;
    })
    getStatFIFA(this.stat.page, this.stat.pageSize, this.stat.filtered, this.stat.sorted)
      .then(response => {
        const { rows, pages } = response.data;
        runInAction(() => {
          this.stat.loading = false
          this.stat.pagesCount = pages
          this.stat.rows = rows
        })
      })
      .catch(err => {
        console.error(err)
        runInAction(() => {
          this.stat.loading = false
        })
      });
  }

  fetchGraph() {
    if (!this.init) return
    getGraphFIFA(this.stat.filtered, this.graph.groupSize, this.graph.graphType)
      .then(response => {
        const { avgLine, y } = response.data;
        runInAction(() => {
          this.graph.series = [
            {
              name: "Значение",
              data: y
            },
            {
              name: "Среднее",
              data: avgLine
            },

          ]
          this.graph.yMax = Math.max(...y)
          this.graph.yMin = Math.min(...y)
        })
      })
      .catch(err => {
        console.error(err)
      });
  }

  updateWS() {
    if (this.autoUpdateStats) {
      if (!this.sockjs) {
        this.sockjs = new SockJS('/ws/fifa/', null, {});
        this.sockjs.onmessage = e => {
          this.onWSMessage(e.data);
        };
      }
    } else {
      if (this.sockjs) {
        this.sockjs.close();
      }
      this.sockjs = null;
    }
  }


  onWSMessage(srcWSData) {
    const wsData = JSON.parse(srcWSData);
    if (!wsData.type) return;
    if (!wsData.data) return;

    const { page, rows } = this.stat;

    const updatedGames = rows.filter(gm =>
      wsData.data.dateStart.includes(gm.dateStart.v)
    );

    switch (wsData.type) {
      case "newGame":
        if (page === 1) this.fetchStat(false);
        break;
      case "updateGame":
        if (updatedGames.length > 0) this.fetchStat(false);
        break;
      default:
    }

  }

  fetchOop() {
    getOppFIFA().then(response => {
      runInAction(() => {
        this.champs = this.extractChampsFromOop(response.data.items)
        this.opps_by_id = this.extractOppsFromOop(response.data.items)
        this.opp = response.data.items
        this.init = true
      })
    })
      .catch(err => {
        console.error(err)
      });
  }

  extractChampsFromOop(data) {
    return Object.keys(data).reduce((champs, chapmID) => {
      champs[chapmID] = data[chapmID].name
      return champs
    }, {})
  }

  extractOppsFromOop(data) {
    return Object.keys(data).reduce((opps, chapmID) => {

      opps = data[chapmID].opp.reduce((opps, opp) => {
        opps[opp.clid_opp] = opp.opp
        return opps
      }, opps)
      return opps
    }, {})
  }

  saveSettings() {
    if (!this.init) return
    const { filtered, pageSize } = this.stat
    const { autoUpdateStats } = this
    const setting = {
      filtered,
      pageSize,
      autoUpdateStats
    }

    localStorage.setItem(
      `fifa_stat_setting`,
      JSON.stringify(setting)
    );
  }

  loadSettings() {
    if (this.init) return

    const settingSrc = localStorage.getItem(`fifa_stat_setting`);
    if (settingSrc === null) return;

    try {
      const setting = JSON.parse(settingSrc);

      this.stat.filtered = setting.filtered
      this.stat.pageSize = setting.pageSize
      this.autoUpdateStats = setting.autoUpdateStats

    } catch (err) {
      console.error(err);
      localStorage.removeItem(`fifa_stat_setting`);
    }

  }

}

export default new fifaStore()