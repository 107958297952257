import React, { Component } from 'react';


export default class Ladder extends Component {
  constructor(prop) {
    super(prop)
    this.state = {

    }
  }

  render() {
    return <div>"ARBIDOL"</div>
  }
}