import React, { Component } from 'react';
import ym, { YMInitializer } from 'react-yandex-metrika';
//import { browserHistory } from 'react-router';
import { withRouter } from "react-router-dom";

class YandexM extends Component {
  componentDidMount() {
    // browserHistory.listen(location => {
    //   console.log(location);
    // });
    const { history } = this.props;
    history.listen((location) => {
      ym('hit', location.pathname);
    })
  }
  componentWillUnmount() {

  }
  render() {
    return <div>
      <YMInitializer
        accounts={[51278098]}
        options={{
          webvisor: true, clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true
        }}
        version="2" />
    </div>
  }
}

export default withRouter(YandexM);
