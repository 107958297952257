import React, { Component } from 'react';
import { Switch, Route } from 'react-router'
import Stats from './Stats.jsx'
import Analiz from './Analiz.jsx'

import { PageLoading } from '../../Common';

import axios from 'axios';
export default class KI extends Component {
  constructor(prop) {
    super(prop)
    this.state = {
      loading: false,
      inGroup: true
    }
  }
  checkInGroup() {
    const { user } = this.props
    if (user.vkid) {
      if (user.vkid === -1) {
        this.setState({
          loading: false,
          inGroup: true
        })
      } else {
        axios.post(`/auth/vk/ismember`, { groupId: 142104089, userId: user.vkid })
          .then(data => {
            console.log(data);
            if (data && data.data && data.data.result) {
              this.setState({
                loading: false,
                inGroup: true
              })
            } else {
              this.setState({
                loading: false,
                inGroup: false
              })
            }
          }).catch(err => {
            console.log(err);
            this.setState({
              loading: false,
              inGroup: false
            })
          })
      }
    } else {
      this.setState({
        loading: false,
        inGroup: false
      })
    }
  }
  componentDidMount() {
    //this.checkInGroup();
  }
  render() {
    const { user } = this.props
    const { loading, inGroup } = this.state
    return loading ? <PageLoading /> : !inGroup ? (<div>
      Для доступа, нужно вступить в
      <a href={'https://vk.com/stavkamkx'}>https://vk.com/stavkamkx</a>
    </div>) : (<Switch>
      <Route exact path="/ki"
        render={(props) => <Stats {...props} user={user} />}
      />
      <Route path="/ki/stat"
        render={(props) => <Stats {...props} user={user} />}
      />
      <Route path="/ki/analiz"
        render={(props) => <Analiz {...props} user={user} />}
      />
    </Switch>)
  }

}