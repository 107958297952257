import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Card } from 'antd';
import { inject, observer } from "mobx-react";

import './index.css';

const PrognozCart = ({prognoz})=>{
  return <div className="prognozCard">
    <a href={`/mk/stat?clid_opp1=${prognoz.clid_opp1}&clid_opp2=${prognoz.clid_opp2}`} >
      <div>{prognoz.opp1} - {prognoz.opp2} </div>
    </a>
    
    {JSON.parse(prognoz.prognoz).map(item=><div>{item.textMsg}</div>)}
  </div>
}

@inject("mkStore") @observer
class BotPrognoz extends Component {

  render() {
    const { BotPrognoz } = this.props.mkStore.stat;
    return <Card
      title="Прогноз от бота"
      style={{ marginBottom: 12 }}
      size='small'
    >
      {BotPrognoz.map(prognoz=><PrognozCart prognoz={prognoz} /> )}
    </Card>
  }
}

export default BotPrognoz