import axios from 'axios';

const rootUrl = '';

export const getUserInfo = () => axios('/api/user/info');

export const getUsers = (data) => axios.post(`${rootUrl}/api/admin/users`, data);
export const updateUser = (data) => axios.post(`${rootUrl}/api/admin/useredit`, data);
export const paymentAdd = (data) => axios.post(`${rootUrl}/api/admin/payments/add`, data);

export const updateUserTenis = (data) => axios.post(`${rootUrl}/api/admin/useredit_tenis`, data);
export const deleteUserTenis = (data) => axios.post(`${rootUrl}/api/admin/userdel_tenis`, data);

export const updateUserFifa = (data) => axios.post(`${rootUrl}/api/admin/useredit_fifa`, data);
export const deleteUserFifa = (data) => axios.post(`${rootUrl}/api/admin/userdel_fifa`, data);

export const updateUserKI = (data) => axios.post(`${rootUrl}/api/admin/useredit_ki`, data);
export const deleteUserKI = (data) => axios.post(`${rootUrl}/api/admin/userdel_ki`, data);

export const getOppMK = () => axios.post(`/api/mk/opp`, {});
export const getStatMK = (page = 0, pageSize = 10, filtered = [], sorted = []) => axios.post(`/api/mk/stat`, { pageSize, page, sorted, filtered });

export const getOppFIFA = () => axios.post(`/api/fifa/opp`, {});
export const getStatFIFA = (page = 0, pageSize = 10, filtered = [], sorted = []) => axios.post(`/api/fifa/stat`, { pageSize, page, sorted, filtered });
export const getGraphFIFA = (filtered = [], groupSize = 5, graphType = "total" ) => axios.post(`/api/fifa/graph`, { filtered, groupSize, graphType });