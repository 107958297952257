import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import MetaTags from 'react-meta-tags';
import { Select, Option } from '../../Common/Select';
import { StatsTable, StatColumnVisibleChanger } from '../../Common';


const columnsPara = {
  Header: 'Пара',
  key: 'para', visible: true,
  HeaderSelectList: {
    'no': '',
    'win': 'Победитель',
  },
  selectedBacklight: 'win',
  columns: [
    { Header: 'Команда 1', accessor: 'clid_opp1', width: 130, type: 'opp' },
    { Header: 'Команда 2', accessor: 'clid_opp2', width: 130, type: 'opp' }
  ].map(col => ({
    ...col,
    cellStyle2: (textStyle, cellData) => {
      const newTextStyle = { ...textStyle };
      const { original, column } = cellData;
      const { selectedBacklight } = column;
      if (selectedBacklight === 'no') return newTextStyle;
      switch (selectedBacklight) {
        case 'win':
          if ((original.result.v.includes('5:')) && column.id === 'clid_opp1') newTextStyle.backgroundColor = '#A3CCFA'
          if ((original.result.v.includes(':5')) && column.id === 'clid_opp2') newTextStyle.backgroundColor = '#A3CCFA'
          break
        default:
      }
      return newTextStyle;
    }
  }))
}

const columnsResult = {
  Header: 'Результат', key: 'result', accessor: 'result', width: 50, visible: true,
  cellStyle: (textStyle, value, row, column) => {
    const newStyle = { ...textStyle }
    if (typeof value.v !== "undefined") {
      if (value.v.indexOf('5:') !== -1) { newStyle.backgroundColor = '#000000'; newStyle.color = '#FFFFFF' };
      if (value.v.indexOf(':5') !== -1) { newStyle.backgroundColor = '#FD7F01' };
      if ((value.v === '5:0') || (value.v === '0:5')) { newStyle.backgroundColor = '#628DF5' };
      if ((value.v.indexOf(':5') === -1) && (value.v.indexOf('5:') === -1)) { newStyle.backgroundColor = '#FFFF55' };
    }
    return newStyle;
  }
}

const RoundFilter = ({ filter, column, onChange }, roundIndex) => <Select
  defaultValue={filter && filter.value ? filter.value : 0}
  value={filter && filter.value ? filter.value : 0}
  style={{ width: '100%', minWidth: '50px' }}
  onChange={value => {
    onChange(value)
  }}
>
  {
    Object.keys(column.dic).map(listID =>
      <Option key={`filter_${roundIndex}_${listID}`} value={listID}>{column.dic[listID]}</Option>
    )
  }
</Select >

const columnsRounds = {
  Header: 'Раунды', key: 'rounds', visible: true,
  HeaderSelectList: {
    'no': '',
    'P1P2': 'П1+П2',
    'P1': 'П1',
    'P2': 'П2',
    'out': 'Победил аутсайдер',
  },
  selectedBacklight: 'F_B_W',
  columns: [1, 2, 3, 4, 5, 6, 7, 8, 9].map(roundIndex => {
    return {
      width: 50,
      accessor: `r${roundIndex}w`,
      Header: `${roundIndex}`,
      dic: { 0: ' ', 1: '1:0', 2: '0:1' },
      Filter: row => RoundFilter(row, roundIndex),
      cellStyle2: (textStyle, cellData) => {
        const newTextStyle = { ...textStyle };
        const { value, original, column } = cellData;
        const { selectedBacklight } = column;

        switch (selectedBacklight) {
          case 'out':
            if ((original.sp1.v > original.sp2.v) && (value.v === 1)) { newTextStyle.backgroundColor = '#000000'; newTextStyle.color = '#FFFFFF' };
            if ((original.sp1.v < original.sp2.v) && (value.v === 2)) { newTextStyle.backgroundColor = '#000000'; newTextStyle.color = '#FFFFFF' };
            break;
          case 'P1P2':
            if ((value.v === 1)) { newTextStyle.backgroundColor = '#000000'; newTextStyle.color = '#FFFFFF' };
            if ((value.v === 2)) newTextStyle.backgroundColor = '#FD7F01';
            break;
          case 'P1':
            if ((value.v === 1)) { newTextStyle.backgroundColor = '#000000'; newTextStyle.color = '#FFFFFF' };
            break;
          case 'P2':
            if ((value.v === 2)) newTextStyle.backgroundColor = '#FD7F01';
            break;
          default:
        }
        return newTextStyle;
      }
    }
  })
}

const columnsRoundKefs = {
  Header: 'Кефы раунд', key: 'kef_rounds', visible: true, onlyVip: true,
  columns: [
    { Header: `П1`, accessor: `sp1` },
    { Header: `П2`, accessor: `sp2` }
  ]
}

const columnsTimeMin = {
  Header: 'Время мин', key: 'timeMin', onlyVip: true,
  columns: [
    { Header: 'М', accessor: `time1m` },
    { Header: 'время', accessor: `timep1m` },
    { Header: 'Б', accessor: `time1b` },
  ]
}

const columnsTimeAvg = {
  Header: 'Время сред', key: 'timeAvg', onlyVip: true,
  columns: [
    { Header: 'М', accessor: `time2m` },
    { Header: 'время', accessor: `timep2m` },
    { Header: 'Б', accessor: `time2b` },
  ]
}

const columnsTimeMax = {
  Header: 'Время макс', key: 'timeMax', onlyVip: true,
  columns: [
    { Header: 'М', accessor: `time3m` },
    { Header: 'время', accessor: `timep3m` },
    { Header: 'Б', accessor: `time3b` },
  ]
}

const columnsTotal = {
  Header: 'Общий тотал', key: 'total_common',
  onlyVip: true,
  HeaderSelectList: {
    'no': '',
    'yes': 'Был проход'
  },
  selectedBacklight: 'yes',
  columns: [
    { Header: `5,5М`, accessor: `t55m` },
    { Header: `5,5Б`, accessor: `t55b` },
    { Header: `6,5М`, accessor: `t65m` },
    { Header: `6,5Б`, accessor: `t65b` },
    { Header: `7,5М`, accessor: `t75m` },
    { Header: `7,5Б`, accessor: `t75b` },
    { Header: `8,5М`, accessor: `t85m` },
    { Header: `8,5Б`, accessor: `t85b` },
  ].map(col => ({
    ...col,
    cellStyle2: (textStyle, cellData) => {
      const newTextStyle = { ...textStyle };
      const { value, original, column } = cellData;
      const { selectedBacklight, id } = column;
      if (selectedBacklight !== 'yes') return newTextStyle;
      if (value.v === 0) return newTextStyle;

      const [resultP1, resultP2] = original.result.v.split(':');
      if (typeof resultP1 === "undefined") return newTextStyle
      if (typeof resultP2 === "undefined") return newTextStyle

      const total = Number(resultP1) + Number(resultP2)

      const val = Number(id.replace('t', '').replace('m', '').replace('b', '')) / 10;
      if (id.includes('m') && total < val) newTextStyle.backgroundColor = '#A3CCFA'
      if (id.includes('b') && total > val) newTextStyle.backgroundColor = '#A3CCFA'

      return newTextStyle
    }
  }))
}

const columnsTotalP1 = {
  Header: 'Инд. тотал П1', key: 'P1_total',
  onlyVip: true,
  HeaderSelectList: {
    'no': '',
    'yes': 'Был проход'
  },
  selectedBacklight: 'yes',
  columns: [
    { Header: `0,5М`, accessor: `p1t05m` },
    { Header: `0,5Б`, accessor: `p1t05b` },
    { Header: `1,5М`, accessor: `p1t15m` },
    { Header: `1,5Б`, accessor: `p1t15b` },
    { Header: `2,5М`, accessor: `p1t25m` },
    { Header: `2,5Б`, accessor: `p1t25b` },
    { Header: `3,5М`, accessor: `p1t35m` },
    { Header: `3,5Б`, accessor: `p1t35b` },
    { Header: `4,5М`, accessor: `p1t45m` },
    { Header: `4,5Б`, accessor: `p1t45b` },
  ].map(col => ({
    ...col,
    cellStyle2: (textStyle, cellData) => {
      const newTextStyle = { ...textStyle };
      const { value, original, column } = cellData;
      const { selectedBacklight, id } = column;
      if (selectedBacklight !== 'yes') return newTextStyle;
      if (value.v === 0) return newTextStyle;

      const [resultP1, resultP2] = original.result.v.split(':');
      if (typeof resultP1 === "undefined") return newTextStyle
      if (typeof resultP2 === "undefined") return newTextStyle

      const val = Number(id.replace('p1t', '').replace('m', '').replace('b', '')) / 10;
      if (id.includes('m') && resultP1 < val) newTextStyle.backgroundColor = '#A3CCFA'
      if (id.includes('b') && resultP1 > val) newTextStyle.backgroundColor = '#A3CCFA'

      return newTextStyle
    }
  }))
}

const columnsTotalP2 = {
  Header: 'Инд. тотал П2', key: 'P2_total',
  onlyVip: true,
  HeaderSelectList: {
    'no': '',
    'yes': 'Был проход'
  },
  selectedBacklight: 'yes',
  columns: [
    { Header: `0,5М`, accessor: `p2t05m` },
    { Header: `0,5Б`, accessor: `p2t05b` },
    { Header: `1,5М`, accessor: `p2t15m` },
    { Header: `1,5Б`, accessor: `p2t15b` },
    { Header: `2,5М`, accessor: `p2t25m` },
    { Header: `2,5Б`, accessor: `p2t25b` },
    { Header: `3,5М`, accessor: `p2t35m` },
    { Header: `3,5Б`, accessor: `p2t35b` },
    { Header: `4,5М`, accessor: `p2t45m` },
    { Header: `4,5Б`, accessor: `p2t45b` },
  ].map(col => ({
    ...col,
    cellStyle2: (textStyle, cellData) => {
      const newTextStyle = { ...textStyle };
      const { value, original, column } = cellData;
      const { selectedBacklight, id } = column;
      if (selectedBacklight !== 'yes') return newTextStyle;
      if (value.v === 0) return newTextStyle;

      const [resultP1, resultP2] = original.result.v.split(':');
      if (typeof resultP1 === "undefined") return newTextStyle
      if (typeof resultP2 === "undefined") return newTextStyle

      const val = Number(id.replace('p2t', '').replace('m', '').replace('b', '')) / 10;
      if (id.includes('m') && resultP2 < val) newTextStyle.backgroundColor = '#A3CCFA'
      if (id.includes('b') && resultP2 > val) newTextStyle.backgroundColor = '#A3CCFA'

      return newTextStyle
    }
  }))
}

const columnsChet = {
  Header: 'Тотал чет./нечет.', key: 'chet_nechet',
  onlyVip: true,
  HeaderSelectList: {
    'no': '',
    'yes': 'Был проход'
  },
  selectedBacklight: 'yes',
  columns: [
    { Header: `Чет.`, accessor: `chet` },
    { Header: `Нечет.`, accessor: `nechet` },
  ].map(col => ({
    ...col,
    cellStyle2: (textStyle, cellData) => {
      const newTextStyle = { ...textStyle };
      const { value, original, column } = cellData;
      const { selectedBacklight, id } = column;
      if (selectedBacklight !== 'yes') return newTextStyle;
      if (value.v === 0) return newTextStyle;

      const [resultP1, resultP2] = original.result.v.split(':');
      if (typeof resultP1 === "undefined") return newTextStyle
      if (typeof resultP2 === "undefined") return newTextStyle

      const total = Number(resultP1) + Number(resultP2)

      if (total % 2 === 0) {
        if (id === `chet`) { newTextStyle.backgroundColor = '#A3CCFA' }
      } else {
        if (id === `nechet`) { newTextStyle.backgroundColor = '#A3CCFA' }
      }

      return newTextStyle
    }
  }))
}

const baseColumns = [
  { Header: 'Дата и время', key: 'dateStart', accessor: 'dateStart', width: 100, type: 'base', visible: true },
  columnsPara,
  columnsResult,
  columnsRounds,
  columnsRoundKefs,
  columnsTimeMin,
  columnsTimeAvg,
  columnsTimeMax,
  columnsTotal,
  columnsTotalP1,
  columnsTotalP2,
  columnsChet
];

class Stats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: baseColumns,
    }
    this.handleChangeVisibleColumn = this.handleChangeVisibleColumn.bind(this);
  }

  componentDidMount() {
    this.loadSettings()
  }

  handleChangeVisibleColumn(visibleColumnKeys) {
    const { columns } = this.state;
    const newColumns = columns.map(column => ({ ...column, visible: column.noHide ? true : visibleColumnKeys.includes(column.key) }));
    this.setState({
      columns: newColumns
    })
    this.saveSettings({ columns: newColumns })
  }

  loadSettings() {
    const settingSrc = localStorage.getItem(`Tekken_store_table_visible`);
    if (settingSrc === null) return false;
    try {
      const setting = JSON.parse(settingSrc);

      if (!setting.visibleColumn) return false;
      const visObj = setting.visibleColumn.reduce((result, vis) => {
        result[vis.key] = vis.visible;
        return result;
      }, {})

      const newColumns = this.state.columns.map(col => {
        if (typeof visObj[col.key] !== "undefined") col.visible = visObj[col.key];
        return col
      })

      this.setState({
        columns: newColumns
      });
    } catch (err) {
      console.log(err)
    }
    return true;
  }

  saveSettings(customSetting = {}) {
    const { columns } = this.state;
    const cols = customSetting.columns ? customSetting.columns : columns

    const setting = {
      visibleColumn: cols.map(column => ({
        key: column.key,
        visible: column.visible ? column.visible : false
      }))
    }

    localStorage.setItem(`Tekken_store_table_visible`, JSON.stringify(setting))
  }

  render() {
    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 8,
    };

    const { user } = this.props;
    let { columns } = this.state;

    const presetColumns = [
      { key: 'ishod', title: 'Исход', columnsKeys: ['dateStart', 'para', 'result', 'rounds', 'kef_rounds', 'chet_nechet'] },
      { key: 'time', title: 'Время', columnsKeys: ['dateStart', 'para', 'result', 'rounds', 'timeMin', 'timeAvg', 'timeMax'] },
      { key: 'total', title: 'Тотал', columnsKeys: ['dateStart', 'para', 'result', 'total_common', 'P1_total', 'P2_total'] }
    ]

    const visibleColumns = columns.filter(column => column.visible)

    return (
      <div style={{ minHeight: 360 }} >
        <MetaTags>
          <title>Статистика MK X</title>
          <meta name="description" content="Статистика киберигр Tekken" />
          <meta name="keywords" content="Tekken, Теккен, аналитика, ставки" />
        </MetaTags>
        <Row gutter={16}>
          <Col {...topColResponsiveProps}>
            <StatColumnVisibleChanger
              storagePrefix='tekken'
              columns={columns}
              presetColumns={presetColumns}
              handleChangeVisibleColumn={(key, value) => { this.handleChangeVisibleColumn(key, value) }}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card bordered={false}>
              <StatsTable
                user={user}
                columns={visibleColumns}
                localStoreKey='Tekken_store'
                dataApiUrl='/api/tekken/stat'
                oppApiUrl='/api/tekken/opp'
                wsUrl='/ws/tekken'
              />
            </Card>
          </Col>
        </Row>

      </div >
    )
  }
}

export default Stats