import React, { Component } from "react";
import ReactTable from "react-table";
import { Button } from "antd";
import { inject, observer } from "mobx-react";
// import SockJS from "sockjs-client";

import SelectMobx from "./SelectMobx";
import { Select, Option } from "./Select";

import "react-table/react-table.css";
import "./StatsTable.less";
import "./StatsTable.css";

const LigaSelect = inject(stores => ({
  items: stores.mkStore.champs_inv,
}))(SelectMobx)

const OppSelect = inject(stores => ({
  items: stores.mkStore.opps,
}))(SelectMobx)

@inject("mkStore") @observer
class LigaCell extends Component {
  render() {
    const { value, cellStyle } = this.props;
    const { champs } = this.props.mkStore;
    let renderValue = champs[value]
      ? champs[value]
      : value;
    return <div style={cellStyle}>{renderValue}</div>;
  }
}

@inject("mkStore") @observer
class OppCell extends Component {
  render() {
    const { value, cellStyle } = this.props;
    const { opps_by_id } = this.props.mkStore;
    let renderValue = opps_by_id[value]
      ? opps_by_id[value]
      : value;
    return <div style={cellStyle}>{renderValue}</div>;
  }
}

class TextFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.filter && props.filter.value ? props.filter.value : ""
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter === this.props.filter) return

    this.setState({
      value: this.props.filter && this.props.filter.value ? this.props.filter.value : ""
    })
  }

  render() {
    const { onChange } = this.props
    return <input
      type="text"
      value={this.state.value}
      onChange={event => {
        window.filter_changed = true;
        this.setState({ value: event.target.value })
      }}
      onBlur={event => {
        if (window.filter_changed) {
          window.filter_changed = false;
          onChange(event.target.value);
        }
      }}
      onKeyDown={event => {
        if (event.keyCode === 13) {
          window.filter_changed = false;
          onChange(event.target.value);
        }
      }}
      style={{
        width: "100%"
      }}
    />
  }
}


@inject("mkStore") @observer
class StatsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // data: [],
      // pages: null,
      // page: 0,
      // filtered: [],
      // sorted: [],
      // pageSize: 10,

      // loading: true,
      // oppLoaded: false,
      // selectedLiga: 0,
      columns: this.prepareColums(this.props.columns)
    };
    // this.fetchData = this.fetchData.bind(this);
  }

  // componentDidMount() {
  //   const { filtered: defFiltered, pageSize: defPageSize } = this.state;
  //   const { defaultFilter } = this.props;
  //   // const setting = this.loadSettings();
  //   let filtered = defFiltered;

  //   const urlParams = window.location.search.substr(1);
  //   let paramsObj = {};
  //   if (urlParams !== "") {
  //     paramsObj = urlParams.split("&").reduce((result, param) => {
  //       if (param.indexOf("=") !== -1) {
  //         const [key, value] = param.split("=");
  //         result[key] = value;
  //       }
  //       return result;
  //     }, paramsObj);
  //   }

  //   if (defaultFilter) {
  //     Object.keys(defaultFilter).forEach(key => {
  //       paramsObj[key] = defaultFilter[key];
  //     });
  //   }

  //   Object.keys(paramsObj).forEach(key => {
  //     filtered = [
  //       ...filtered.filter(item => item.id !== key),
  //       { id: key, value: paramsObj[key] }
  //     ];
  //   });

  // }

  componentWillReceiveProps(nextProps) {
    const { columns } = nextProps;
    const { columns: localColumns } = this.state;
    const migratedColumns = columns.map(column => {
      if (!column.HeaderSelectList) return column;
      const oldColumn = localColumns.filter(
        locColumn => locColumn.key === column.key
      )[0];
      if (oldColumn)
        return { ...column, selectedBacklight: oldColumn.selectedBacklight };
      return column;
    });
    this.setState({
      columns: this.prepareColums(migratedColumns)
    });
  }

  // componentWillUnmount() {
  //   if (this.sockjs) this.sockjs.close();
  // }

  onWSMessage(srcWSData) {
    const wsData = JSON.parse(srcWSData);
    console.log(wsData);
    if (!wsData.type) return;

    const { page, data } = this.state;
    console.log(data);

    const updatedGames = data.filter(gm =>
      wsData.data.dateStart.includes(gm.dateStart.v)
    );

    switch (wsData.type) {
      case "newGame":
        if (page === 0) this.refreshData({});
        break;
      case "updateGame":
        if (updatedGames.length > 0) this.refreshData({});
        break;
      default:
    }
  }

  onChangeBacklight(key, value) {
    const { columns } = this.state;
    this.setState({
      columns: columns.map(column => {
        if (column.key !== key) return column;
        const newColumn = { ...column };
        newColumn.selectedBacklight = value;
        if (newColumn.columns) {
          newColumn.columns = newColumn.columns.map(childColumn => ({
            ...childColumn,
            selectedBacklight: value
          }));
        }
        return newColumn;
      })
    });
  }

  rowstyle = (row, column) => {
    const res = {
      textAlign: "center",
      width: "100%",
      height: "100%",
      lineHeight: "20px",
      paddingTop: "5px",
      paddingBottom: "3px"
    };
    if (row[column.id]) {
      if (row[column.id].bcolor) {
        res.backgroundColor = row[column.id].bcolor;
      }
      if (row[column.id].tcolor) {
        res.color = row[column.id].tcolor;
      }
    }
    return res;
  };

  prepareColums(colums) {
    // const { champs, opps } = this.props.mkStore
    return colums.map(col => {
      const resultColum = { ...col };

      if (resultColum.columns) {
        resultColum.columns = this.prepareColums(resultColum.columns);
      }

      if (!resultColum.type) resultColum.type = "base";
      if (!resultColum.width) resultColum.width = 40;
      // console.log(resultColum)
      if (resultColum.type) {
        switch (resultColum.type) {
          case "liga":
            resultColum.Filter = resultColum.Filter
              ? resultColum.Filter
              : ({ filter, onChange }) => {
                return (
                  <LigaSelect
                    keyPrefix={`filter_${resultColum.dataIndex}`}
                    value={
                      filter && typeof filter.value !== "undefined"
                        ? filter.value
                        : 0
                    }
                    onChange={value => {
                      // this.setState({ selectedLiga: value });
                      // setSetSelectedChamp(value);
                      onChange(value);
                    }}
                  />
                );
              };
            resultColum.Cell = ({ row, column }) => {
              let value =
                row[column.id] && typeof row[column.id].v !== "undefined"
                  ? row[column.id].v
                  : 0;
              const cellStyle = resultColum.cellStyle
                ? resultColum.cellStyle(this.rowstyle(row, column))
                : this.rowstyle(row, column);
              return <LigaCell value={value} cellStyle={cellStyle} />
            };
            break;
          case "opp":
            resultColum.Filter = resultColum.Filter
              ? resultColum.Filter
              : ({ filter, onChange }) => {
                return (
                  <OppSelect
                    keyPrefix={`filter_${resultColum.dataIndex}`}
                    // ligaID={this.state.selectedLiga}
                    oppApiUrl={this.props.oppApiUrl}
                    value={
                      filter && typeof filter.value !== "undefined"
                        ? filter.value
                        : 0
                    }
                    onChange={value => {
                      onChange(value);
                    }}
                  />
                );
              };
            resultColum.Cell = cellData => {
              const { row, column } = cellData;
              let value =
                row[column.id] && typeof row[column.id].v !== "undefined"
                  ? row[column.id].v
                  : 0;
              // const cellStyle = resultColum.cellStyle ? resultColum.cellStyle(this.rowstyle(row, column)) : this.rowstyle(row, column);

              const rowStyle = this.rowstyle(row, column);
              const cellStyle = resultColum.cellStyle
                ? resultColum.cellStyle(rowStyle, value, row, column)
                : rowStyle;
              const cellStyle2 = resultColum.cellStyle2
                ? resultColum.cellStyle2(cellStyle, cellData)
                : cellStyle;

              return <OppCell value={value} cellStyle={cellStyle2} />

            };
            break;
          case "custom":
            if (resultColum.key === "analiz") {
              const cellStyle = {
                textAlign: "center",
                width: "100%",
                height: "100%"
              };
              resultColum.Filter = row => (
                <div style={cellStyle}>
                  <Button
                    type="danger"
                    onClick={() => {
                      const { resetFilters } = this.props.mkStore;
                      resetFilters()
                    }}
                  >
                    Сброс
                  </Button>
                </div>
              );
            }
            break;
          case "base":
          default:
            resultColum.Cell = cellData => {
              const { row, column, value } = cellData;
              let text = value && typeof value.v !== "undefined" ? value.v : "";
              if (resultColum.dic) {
                if (typeof resultColum.dic === "function") {
                  text = resultColum.dic(cellData);
                } else {
                  text =
                    resultColum.dic &&
                      typeof resultColum.dic[text] !== "undefined"
                      ? resultColum.dic[text]
                      : text;
                }
              }
              const rowStyle = this.rowstyle(row, column);
              const cellStyle = resultColum.cellStyle
                ? resultColum.cellStyle(rowStyle, value, row, column)
                : rowStyle;
              const cellStyle2 = resultColum.cellStyle2
                ? resultColum.cellStyle2(cellStyle, cellData)
                : cellStyle;
              return <div style={cellStyle2}>{text}</div>;
            };
            resultColum.Filter = resultColum.Filter
              ? resultColum.Filter
              : ({ filter, onChange }) => <TextFilter filter={filter} onChange={onChange} />
            break;
        }
      }

      if (resultColum.HeaderSelectList) {
        if (!resultColum.selectedBacklight)
          resultColum.selectedBacklight = "no";

        if (resultColum.columns) {
          resultColum.columns = resultColum.columns.map(column => ({
            ...column,
            selectedBacklight: resultColum.selectedBacklight
          }));
        }

        const { Header, key } = resultColum;
        resultColum.Header = row => (
          <div>
            <div>{Header}</div>
            <div>
              <Select
                style={{ width: "100%" }}
                value={
                  row.column.selectedBacklight
                    ? row.column.selectedBacklight
                    : 0
                }
                onChange={value => {
                  this.onChangeBacklight(key, value);
                }}
              >
                {Object.keys(resultColum.HeaderSelectList).map(listKey => (
                  <Option key={listKey} value={listKey}>
                    {resultColum.HeaderSelectList[listKey]}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        );
      }

      return resultColum;
    });
  }



  render() {
    const { columns } = this.state;
    const { setStatFiltered, setStatPage, setStatSorted, setStatPageSize } = this.props.mkStore;
    const { rows, pagesCount, loading, filtered } = this.props.mkStore.stat;
    return (
      <div>
        <ReactTable
          // className={'stat-table'}
          columns={columns}
          filtered={filtered}
          onFilteredChange={filtered => {
            setStatFiltered(filtered)
          }}
          onPageChange={page => {
            setStatPage(page)
          }}
          onPageSizeChange={pageSize => {
            setStatPageSize(pageSize)
          }}
          onSortedChange={sorted => {
            setStatSorted(sorted)
          }}
          manual
          data={rows}
          pages={pagesCount}
          loading={loading}
          filterable
          defaultPageSize={10}
          className="-striped -highlight"
          loadingText="Загрузка..."
          nextText={window.innerWidth > 400 ? "Следующая" : "След."}
          previousText={window.innerWidth > 400 ? "Предыдущая" : "Пред."}
          pageText="Страница"
          rowsText="строк"
          noDataText="Данных нет."
          ofText="из"
        />
      </div>
    );
  }
}

export default StatsTable;
