import { action, makeAutoObservable, runInAction, autorun, computed } from "mobx"
import SockJS from "sockjs-client";
import { getOppMK, getStatMK } from "../../api";
import Math from 'mathjs'

class mkStore {
  init = false
  champs = {}
  opp = {}
  // opps = {}
  opps_py_id = {}

  autoUpdateStats = false

  stat = {
    loading: false,
    rows: [],
    pagesCount: 0,

    page: 0,
    pageSize: 10,
    sorted: [],
    filtered: [],


    BotPrognoz: [],
    avgStat: [
      {
        r: 0,
        rp1: 0,
        rp2: 0,
        f: 0,
        fp1: 0,
        fp2: 0,
        b: 0,
        bp1: 0,
        bp2: 0,
        first_row: {}
      }
    ],
  }

  sockjs = null

  constructor() {
    makeAutoObservable(this)

    // this.changeMenuState = this.changeMenuState.bind(this)
    this.initStore = this.initStore.bind(this)
    this.setStatFiltered = this.setStatFiltered.bind(this)
    this.setStatPage = this.setStatPage.bind(this)
    this.setStatSorted = this.setStatSorted.bind(this)
    this.setStatPageSize = this.setStatPageSize.bind(this)
    this.setAutoUpdateStats = this.setAutoUpdateStats.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.setStatFilter = this.setStatFilter.bind(this)

    autorun(() => { this.loadSettings() })
    autorun(() => { this.fetchStat() })
    autorun(() => { this.updateWS() })
    autorun(() => { this.saveSettings() })
  }

  @action initStore() {
    if (this.init) return
    this.fetchOop()
  }

  @action setStatFiltered(filtered) {
    this.stat.filtered = filtered
  }

  @action setStatPage(page) {
    this.stat.page = page

  }

  @action setStatSorted(sorted) {
    this.stat.sorted = sorted
  }

  @action setStatPageSize(pageSize) {
    this.stat.pageSize = pageSize
  }

  @action setAutoUpdateStats(auto_update_state) {
    this.autoUpdateStats = auto_update_state
  }

  @action resetFilters() {
    this.stat.filtered = this.stat.filtered.filter(item => item.id === "champId")
  }

  @action setStatFilter(filter) {
    console.log(filter)
    this.stat.filtered = filter.reduce((r, f) => {
      return [
        ...r.filter(item => item.id !== f.id),
        { id: f.id, value: f.value }
      ]
    }, this.stat.filtered)

  }

  @computed get opps() {
    const selected_champ = this.stat.filtered.reduce((chamdId, item) => item.id === "champId" ? item.value : chamdId, 0)

    return Object.keys(this.opp).reduce((obj, champId) => {
      if ((selected_champ > 0) && (selected_champ !== champId)) { return obj; }
      return this.opp[champId].opp.reduce((opps, oppObj) => {
        if (opps[oppObj.opp] && opps[oppObj.opp] !== `${oppObj.clid_opp}`) {
          opps[oppObj.opp] = `${opps[oppObj.opp]},${oppObj.clid_opp}`
        } else {
          opps[oppObj.opp] = `${oppObj.clid_opp}`
        }
        return opps;
      }, obj);
    }, {});
  }

  @computed get champs_inv(){
    return Object.keys(this.champs).reduce((obj,champId)=>{
      obj[this.champs[champId]]=champId
      return obj
    },{})
  }

  fetchStat(showSpinner = true) {
    if (!this.init) return

    runInAction(() => {
      this.stat.loading = showSpinner;
    })
    getStatMK(this.stat.page, this.stat.pageSize, this.stat.filtered, this.stat.sorted)
      .then(response => {
        const { rows, pages, avgStat, lastPrognoz } = response.data;
        runInAction(() => {
          this.stat.loading = false
          this.stat.pagesCount = pages
          this.stat.rows = rows
          this.stat.avgStat = this.computeAvgStat(avgStat, rows)
          this.stat.BotPrognoz = lastPrognoz.filter(prognoz => prognoz.calculate === 0)
        })
      })
      .catch(err => {
        console.error(err)
        runInAction(() => {
          this.stat.loading = false
        })
      });
  }

  updateWS() {
    if (this.autoUpdateStats) {
      if (!this.sockjs) {
        this.sockjs = new SockJS('/ws/mk/', null, {});
        this.sockjs.onmessage = e => {
          this.onWSMessage(e.data);
        };
      }
    } else {
      if (this.sockjs) {
        this.sockjs.close();
      }
      this.sockjs = null;
    }
  }


  onWSMessage(srcWSData) {
    const wsData = JSON.parse(srcWSData);
    if (!wsData.type) return;
    if (!wsData.data) return;

    const { page, rows } = this.stat;

    const updatedGames = rows.filter(gm =>
      wsData.data.dateStart.includes(gm.dateStart.v)
    );

    switch (wsData.type) {
      case "newGame":
        if (page === 1) this.fetchStat(false);
        break;
      case "updateGame":
        if (updatedGames.length > 0) this.fetchStat(false);
        break;
      default:
    }

  }

  computeAvgStat(srcData, rows) {
    const { db, db1, df, df1, dr, dr1, rc1, rc2, time } = srcData;
    const dr2 = dr - dr1;
    const df2 = df - df1;
    const db2 = db - db1;
    return [
      {
        r: dr ? Math.round((rc1 + rc2) / dr, 1) : 0,
        rp1: dr1 ? Math.round(rc1 / dr1, 1) : 0,
        rp2: dr2 ? Math.round(rc2 / dr2, 1) : 0,

        f: df ? Math.round((rc1 + rc2) / df, 1) : 0,
        fp1: df1 ? Math.round(rc1 / df1, 1) : 0,
        fp2: df2 ? Math.round(rc2 / df2, 1) : 0,

        b: db ? Math.round((rc1 + rc2) / db, 1) : 0,
        bp1: db1 ? Math.round(rc1 / db1, 1) : 0,
        bp2: db2 ? Math.round(rc2 / db2, 1) : 0,
        time,
        first_row: rows[0] ? rows[0] : {}
      }
    ]
  }

  fetchOop() {
    getOppMK().then(response => {
      runInAction(() => {
        this.champs = this.extractChampsFromOop(response.data.items)
        this.opps_by_id = this.extractOppsFromOop(response.data.items)
        this.opp = response.data.items
        this.init = true
      })
    })
      .catch(err => {
        console.error(err)
      });
  }

  extractChampsFromOop(data) {
    return Object.keys(data).reduce((champs, chapmID) => {
      champs[chapmID] = data[chapmID].name
      return champs
    }, {})
  }

  extractOppsFromOop(data) {
    return Object.keys(data).reduce((opps, chapmID) => {

      opps = data[chapmID].opp.reduce((opps, opp) => {
        opps[opp.clid_opp] = opp.opp
        return opps
      }, opps)
      return opps
    }, {})
  }

  saveSettings() {
    if (!this.init) return
    const { filtered, pageSize } = this.stat
    const { autoUpdateStats } = this
    const setting = {
      filtered,
      pageSize,
      autoUpdateStats
    }

    localStorage.setItem(
      `mk_stat_setting`,
      JSON.stringify(setting)
    );
  }

  loadSettings() {
    if (this.init) return

    const settingSrc = localStorage.getItem(`mk_stat_setting`);
    if (settingSrc === null) return;

    try {
      const setting = JSON.parse(settingSrc);

      this.stat.filtered = setting.filtered
      this.stat.pageSize = setting.pageSize
      this.autoUpdateStats = setting.autoUpdateStats

    } catch (err) {
      console.error(err);
      localStorage.removeItem(`mk_stat_setting`);
    }

    // const urlParams = window.location.search.substr(1);
    // let paramsObj = {};
    // if (urlParams !== "" && window.location.pathname === "/mk/stat") {
    //   paramsObj = urlParams.split("&").reduce((result, param) => {
    //     if (param.indexOf("=") !== -1) {
    //       const [key, value] = param.split("=");
    //       result[key] = value;
    //     }
    //     return result;
    //   }, paramsObj);
    // }

    // Object.keys(paramsObj).forEach(key => {
    //   this.stat.filtered = [
    //     ...this.stat.filtered.filter(item => item.id !== key),
    //     { id: key, value: paramsObj[key] }
    //   ];
    // });


  }

}

export default new mkStore()