import React from 'react';
import { Form, Input, Select, Button, DatePicker } from 'antd';

import moment from 'moment';
import 'moment/locale/ru';

const FormItem = Form.Item;
const Option = Select.Option;

const dateFormat = 'YYYY-MM-DD';

const recievers = {
  'max': "Максим",
  'vova': "Владимир",
  'andrey': "Андрей",
  'yana': "Яна",
}
const koshels = {
  'qiwi': 'Киви',
  'ya': 'Яндекс',
  'cards': 'Карта',
  'wm': 'Web Money'
}
class FormEditRow extends React.Component {
  state = {
    id: 0,
    telegramId: 0,
    login: "",
    dateStop: "2019-10-01",
    reciever: "",
    koshel: ""
  }

  componentDidMount = () => {
    const { editRowData } = this.props;
    this.setState({
      id: editRowData.id.v,
      telegramId: editRowData.telegramId.v,
      login: editRowData.login.v,
      dateStop: editRowData.dateStop.v,
      reciever: editRowData.reciever.v,
      koshel: editRowData.koshel.v,
    })
  }

  handleChangeTelegramId = (e) => {
    this.setState({
      telegramId: e.target.value,
    });
  }

  handleChangeTelegramLogin = (e) => {
    this.setState({
      login: e.target.value,
    });
  }

  handleChangeDateStop = (e, dateString) => {
    this.setState({
      dateStop: dateString,
    });
  }

  handleSave = () => {
    const { id, telegramId, login, dateStop, reciever, koshel } = this.state;

    const dataToTable = {
      id,
      telegramId,
      login,
      dateStop,
      reciever,
      koshel
    }
    this.props.updateData(dataToTable)
  }

  handleCancel = () => {
    this.props.closeModal({ visible: false })
  }

  handleRecieverChange = (value) => {
    this.setState({
      reciever: value
    });
  }
  handleKoshelChange = (value) => {
    this.setState({
      koshel: value
    });
  }

  render() {
    const { id, telegramId, login, dateStop, reciever, koshel } = this.state;
    // console.log(this.state)
    return (
      <Form layout="horizontal" onSubmit={this.handleSubmit}>
        <FormItem label="ID в базе">
          <Input
            value={id}
            placeholder="id"
            disabled
          />
        </FormItem>
        <FormItem label="Telegram id">
          <Input
            value={telegramId}
            placeholder="telegramId"
            onChange={this.handleChangeTelegramId}
          />
        </FormItem>
        <FormItem label="Telegram login">
          <Input
            value={login}
            placeholder="telegramLogin"
            onChange={this.handleChangeTelegramLogin}
          />
        </FormItem>
        <FormItem label="Дата окончания подписки">
          <DatePicker
            value={moment(dateStop, dateFormat)}
            format={dateFormat}
            onChange={this.handleChangeDateStop}
            allowClear={false}
          />
        </FormItem>

        <FormItem label="Кто принял платеж">
          <Select
            value={reciever}
            style={{ width: '100%' }}
            onChange={this.handleRecieverChange}
          >
            {Object.keys(recievers).map(key => <Option key={key}>{recievers[key]}</Option>)}
          </Select>
        </FormItem>

        <FormItem label="Кошелек">
          <Select
            value={koshel}
            style={{ width: '100%' }}
            onChange={this.handleKoshelChange}
          >
            {Object.keys(koshels).map(key => <Option key={key}>{koshels[key]}</Option>)}
          </Select>
        </FormItem>

        <Button key="back" onClick={this.handleCancel}>Отмена</Button>,
            <Button key="submit" type="primary" onClick={this.handleSave}>
          Сохранить
            </Button>
      </Form>
    );
  }
}

export { FormEditRow }
