import React, { Component } from 'react';
import { Select, Option } from './Select';
import axios from 'axios';

export default class OppSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oppsItems: {},
      value: this.props.value ? this.props.value : 0
    }
  }

  extractOppDataFromSrc(data, ligaID = 0) {
    return Object.keys(data).reduce((obj, champId) => {
      obj.champs[champId] = data[champId].name;
      if ((ligaID > 0) && (ligaID !== champId)) { return obj; }
      obj.opps = data[champId].opp.reduce((opps, oppObj) => {
        if (opps[oppObj.opp]) {
          opps[oppObj.opp] = `${opps[oppObj.opp]},${oppObj.clid_opp}`
        } else {
          opps[oppObj.opp] = `${oppObj.clid_opp}`
        }
        obj.oppsById[oppObj.clid_opp] = oppObj.opp;
        return opps;
      }, obj.opps);
      return obj;
    }, {
        champs: {},
        opps: {},
        oppsById: {}
      });
  }

  loadOpp() {
    const { oppApiUrl } = this.props;
    if (!window.oppCache) {
      window.oppCache = {}
    }
    // console.log('(window.oppCache', window.oppCache)
    if (window.oppCache[oppApiUrl]) {
      // console.log('window.oppCache[oppApiUrl]', window.oppCache[oppApiUrl])
      this.setState({
        oppsItems: window.oppCache[oppApiUrl]
      });
    } else {
      if (oppApiUrl) {
        axios.post(oppApiUrl, {}).then(response => {
          const data = response.data;
          this.setState({
            oppsItems: data.items
          });
          console.log('newData', data.items);
          window.oppCache[oppApiUrl] = data.items;
        }).catch(err => {
          this.setState({
            oppsItems: {}
          });
        });
      }
    }
  }

  componentDidMount() {
    this.loadOpp();
  }

  render() {
    const { oppsItems } = this.state;
    const { onChange, keyPrefix } = this.props;
    const ligaID = this.props.ligaID ? this.props.ligaID : 0;
    const { oppsById } = this.extractOppDataFromSrc(oppsItems, ligaID)
    const prefixKey = `opp_${keyPrefix}`
    const value = typeof this.props.value !== "undefined" ? this.props.value : this.state.value
    return <Select
      style={{ width: '100%' }}
      value={value}
      onChange={value => {
        onChange(value);
        if (!this.props.value) {
          this.setState({
            value: value
          })
        }
      }}>
      <Option key={`${prefixKey}_0`} value={0}>Все</Option>
      {
        Object.keys(oppsById).sort((a, b) => oppsById[b] > oppsById[a] ? -1 : 1).filter(listID => parseInt(listID, 10) !== 0).map(listID =>
          <Option key={`${prefixKey}_${listID}`} value={listID}>{oppsById[listID]}</Option>
        )
      }
    </Select>
  }

} 