import React, { Component } from 'react';

import { StatsTable } from '../../Common';

import MetaTags from 'react-meta-tags';

const columns = [
  { Header: 'Дата и время', accessor: 'dateStart', width: 100 },
  { Header: 'Лига', accessor: 'champName', width: 180 },
  {
    Header: 'Пара',
    columns: [
      { Header: 'Команда 1', accessor: 'opp1', width: 130 },
      { Header: 'Команда 2', accessor: 'opp2', width: 130 }
    ]
  },
  { Header: 'Результат', accessor: 'result', width: 75 },
  {
    Header: '1', onlyVip: true,
    columns: [
      { Header: '', accessor: 'lp1' },
      { Header: '', accessor: 'rp1' }
    ]
  },
  {
    Header: 'X', onlyVip: true,
    columns: [
      { Header: '', accessor: 'lpx' },
      { Header: '', accessor: 'rpx' }
    ]
  },
  {
    Header: '2', onlyVip: true,
    columns: [
      { Header: '', accessor: 'lp2' },
      { Header: '', accessor: 'rp2' }
    ]
  },
  {
    Header: '1x2', onlyVip: true,
    columns: [
      { Header: 'П1', accessor: 'x2p1' },
      { Header: 'X', accessor: 'x2x' },
      { Header: 'П2', accessor: 'x2p2' }
    ]
  },
  {
    Header: 'Двойной шанс', onlyVip: true,
    columns: [
      { Header: '1X', accessor: 'sh21x' },
      { Header: '12', accessor: 'sh212' },
      { Header: '2X', accessor: 'sh22x' }
    ]
  }
];

function clearFilerInColums(columns) {
  console.log(columns);
  return columns.map(column => {
    if (column.columns) {
      column.columns = clearFilerInColums(column.columns)
    } else {
      column.Filter = <div></div>;
    }
    return column;
  })
}


class Toto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: columns
    }
  }
  render() {
    const { user } = this.props;
    let { columns } = this.state;
    if (user && user.vip === 0) {
      columns = columns.filter(column => !column.onlyVip)
      columns = clearFilerInColums(columns);
    }
    return (
      <div style={{ padding: 14, background: '#fff', minHeight: 360 }} >
        <MetaTags>
          <title>Статистика Fifa</title>
          <meta name="description" content="Статистика киберигр Fifa" />
          <meta name="keywords" content="Fifa статистика, анализ, прогноз" />
        </MetaTags>
        <StatsTable
          columns={columns}
          dataApiUrl={'/api/fifa/toto'}
        />
      </div >
    )
  }
}

export default Toto