import React, { Component } from 'react';
import { Switch, Route } from 'react-router'

import Stats from './Stats.jsx'

import { PageLoading } from '../../Common';

export { Stats };

export default class Fifa extends Component {
  constructor(prop) {
    super(prop)
    this.state = {
      loading: false,
      inGroup: true
    }
  }

  render() {
    const { user } = this.props
    const { loading } = this.state
    return loading ? <PageLoading /> : (<Switch>
      <Route exact path="/21"
        render={(props) => <Stats {...props} user={user} />}
      />
      <Route path="/21/stat"
        render={(props) => <Stats {...props} user={user} />}
      />
    </Switch>)
  }
}