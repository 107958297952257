import React, { Component } from 'react';
import { Layout, Avatar, Dropdown, Menu } from 'antd';
import { Switch, Route } from 'react-router';

import { getUserInfo } from '../api';

import { AutorizationLoginForm } from './Pages/Autorization';

import { YandexM, PageLoading } from './Common';

import SiderMenu from './SiderMenu';
import GlobalHeader from './GlobalHeader';

import Fifa from './Pages/Fifa';
import Tekken from './Pages/Tekken';
import Users from './Pages/Admin/Users';
import Sessions from './Pages/Admin/Sessions';
import UsersTelegram from './Pages/Admin/UsersTelegram';
import UsersTelegramSport from './Pages/Admin/UsersTelegramSport';

import Home from './Pages/Home';
import KI from './Pages/KI';
import MK from './Pages/MK';
import TO from './Pages/21';
import CalcTools from './Pages/CalcTools';
import routes from './Routes';

import './myStyle.less';
import './App.css';

const { Content, Footer } = Layout;

// @inject("rootStore") @observer
class MainContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuCollapse: false
    };
  }

  render() {
    // const { menuCollapse } = this.state;
    const { user } = this.props;
    // const { menuShow } = this.props.rootStore.menuStore
    const locRoutes = routes.filter(route => {
      if (route.admin) {
        return user.admin >= route.admin;
      }
      return true;
    });
    const dropDownMenu = (
      <Menu>
        <Menu.Item key="0">Home</Menu.Item>
        <Menu.Divider />
        <Menu.Item key="1">
          <a href="/api/auth/sign-out">Log out</a>
        </Menu.Item>
      </Menu>
    );
    return (
      <Layout>
        <SiderMenu
          // setCollapseMenu={state => this.setCollapseMenu(state)}
          // menuCollapse={menuShow}
          routes={locRoutes}
        />
        <Layout
          style={{
            minHeight: '100vh'
          }}
        >
          <Dropdown overlay={dropDownMenu} trigger={['click']}>
            <div className="avatar-place">
              <Avatar
                size="large"
                shape="circle"
                style={{ backgroundColor: '#1890ff' }}
                src={
                  user.photoUrl && user.photoUrl !== 'url_to_avatar'
                    ? user.photoUrl
                    : ''
                }
              >
                User
              </Avatar>
            </div>
          </Dropdown>
          )
          <Switch>
            <Route
              render={props => (
                <GlobalHeader
                  {...props}
                  // setCollapseMenu={state => this.setCollapseMenu(state)}
                  // menuCollapse={menuCollapse}
                  routes={locRoutes}
                  user={user}
                />
              )}
            />
          </Switch>
          <Content style={{ margin: '50px 8px 0' }}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                path="/fifa"
                render={props => <Fifa user={user} />}
              />

              <Route path="/user" component={Users} />

              <Route
                path="/tekken"
                render={props => <Tekken {...props} user={user} />}
              />

              <Route
                path="/ki"
                render={props => <KI {...props} user={user} />}
              />

              <Route
                path="/mk"
                render={props => <MK  user={user} />}
              />

              <Route
                path="/21"
                render={props => <TO {...props} user={user} />}
              />

              <Route
                path="/tools"
                render={props => <CalcTools {...props} user={user} />}
              />

              <Route path="/admin/users" component={Users} />
              <Route path="/admin/telegram" component={UsersTelegram} />
              <Route path="/admin/fifa"
                render={props => <UsersTelegramSport {...props}
                  editUserUrl="/api/admin/useredit_fifa"
                  addPaymentUrl="/api/admin/payments/addfifa"
                  deleteUserUrl="/api/admin/userdel_fifa"
                  getUsersUrl="/api/admin/users_fifa"
                />}
              />
              <Route path="/admin/ki"
                render={props => <UsersTelegramSport {...props}
                  editUserUrl="/api/admin/useredit_ki"
                  addPaymentUrl="/api/admin/payments/addki"
                  deleteUserUrl="/api/admin/userdel_ki"
                  getUsersUrl="/api/admin/users_ki"
                />}
              />
              <Route path="/admin/tenis"
                render={props => <UsersTelegramSport {...props}
                  editUserUrl="/api/admin/useredit_tenis"
                  addPaymentUrl="/api/admin/payments/addtenis"
                  deleteUserUrl="/api/admin/userdel_tenis"
                  getUsersUrl="/api/admin/users_tenis"
                />}
              />
              <Route path="/admin/sessions" component={Sessions} />
              <Route component={Home} />
            </Switch>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            BKstat ©2018 Created by
            <a href={'https://vk.com/napad'}>{' Napad Maxim'}</a>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAuth: false,
      loading: true,
      user: {
        admin: 0,
        vip: 0
      }
    };
  }

  checkAuth() {
    this.setState({
      loading: true
    });

    getUserInfo()
      .then(resp => {
        const { data } = resp;
        console.log(data);
        if (data.username) {
          this.setState({
            user: data,
            loading: false,
            userAuth: true
          });
          return true;
        }
        this.setState({
          loading: false,
          userAuth: false
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  componentDidMount() {
    this.checkAuth();
  }

  render() {
    const { loading, userAuth, user } = this.state;
    return (
      <div>
        <YandexM />
        {loading && <PageLoading />}
        {!loading && !userAuth && (
          <AutorizationLoginForm
            checkAuth={() => {
              this.checkAuth();
            }}
          />
        )}
        {!loading && userAuth && <MainContent user={user} />}
      </div>
    );
  }
}

export default App;
