import React, { Component } from 'react';
import { Row, Col, Card } from 'antd';
import MetaTags from 'react-meta-tags';
import { Select, Option } from '../../Common/Select';

import { StatsTable, StatColumnVisibleChanger } from '../../Common';

function setStyleByCardDiler(textStyle, value, row, column) {
  if (row._original.p2Cards.v && row._original.p2Cards.v.includes(column.Header)) {
    textStyle.backgroundColor = '#AAFFAA'
  }
  return textStyle
}

function setStyleByCardPlayer(textStyle, value, row, column) {
  if (row._original.p1Cards.v && row._original.p1Cards.v.includes(column.Header)) {
    textStyle.backgroundColor = '#AAAAFF'
  }
  return textStyle
}

function isGoldDiler(row) {
  const { v } = row._original.resultP2;
  if (!v || v !== 21) return false

  const cards = row._original.p2Cards.v;
  return cards.length === 5 &&
    cards.charAt(0) === 'A' &&
    cards.charAt(3) === 'A'
}

function isGoldPlayer(row) {
  const { v } = row._original.resultP1;
  if (!v || v !== 21) return false

  const cards = row._original.p1Cards.v;
  return cards.length === 5 &&
    cards.charAt(0) === 'A' &&
    cards.charAt(3) === 'A'
}

function isDiler21(row) {
  return row._original.resultP2.v && row._original.resultP2.v === 21
}
function isPlayer21(row) {
  return row._original.resultP1.v && row._original.resultP1.v === 21
}

function isOverDiler(row) {
  return row._original.resultP2.v && row._original.resultP2.v > 21
}
function isOverPlayer(row) {
  return row._original.resultP1.v && row._original.resultP1.v > 21
}

function isTotalB(row, accessor) {
  return (row._original.resultP1.v + row._original.resultP2.v) > row._original[accessor].v
}


const columnGold = {
  Header: 'Золотое очко', onlyVip: true, key: 'gold', width: 70,
  columns: [
    {
      Header: 'Да', accessor: 'Gold21Y',
      cellStyle: (textStyle, value, row, column) => {
        if (isGoldDiler(row) || isGoldPlayer(row)) {
          textStyle.backgroundColor = '#d3d300'
        }
        return textStyle
      }
    },
    {
      Header: 'Игрок получит', accessor: 'GamerGold21Y', width: 70,
      cellStyle: (textStyle, value, row, column) => {
        if (isGoldPlayer(row)) {
          textStyle.backgroundColor = '#d3d300'
        }
        return textStyle
      }
    },
    {
      Header: 'Проход', accessor: 'doGold', width: 70,
      dic: { '0': '', '1': 'П1', '2': 'П2' },
      Filter: (row) => {
        const { filter, onChange } = row;
        return <Select
          defaultValue={filter && filter.value ? filter.value : 0}
          value={filter && filter.value ? filter.value : 0}
          style={{ width: '100%', minWidth: '50px' }}
          onChange={value => {
            onChange(value)
          }}
        >
          <Option key={`filter_no`} value={0} />
          <Option key={`filter_ever`} value={1} > {'НЕТ'}</Option >
          <Option key={`filter_ever`} value={2} > {'ДА'}</Option >
          <Option key={`filter_p1`} value={3} > {'П1'}</Option >
          <Option key={`filter_p1`} value={4} > {'П2'}</Option >

        </Select >
      }
      // cellStyle: (textStyle, value, row, column) => {
      //   if (isGoldPlayer(row)) {
      //     textStyle.backgroundColor = '#d3d300'
      //   }
      //   return textStyle
      // }
    }
  ]
}

const baseColumns = [
  { Header: 'Дата и время', key: 'dateStart', accessor: 'dateStart', width: 100, visible: true },
  { Header: 'Номер игры', key: 'gameN', accessor: 'gameN', width: 40, visible: true },
  {
    Header: 'Счет', visible: true, key: 'schet',
    columns: [
      {
        Header: 'Игрок', accessor: 'resultP1', width: 70,
        cellStyle: (textStyle, value, row, column) => {
          const { resultP1, resultP2 } = row._original;
          if ((resultP2.v > 21) || ((resultP2.v < resultP1.v) && (resultP1.v < 22))) {
            textStyle.backgroundColor = 'rgb(129, 163, 243)'
          }
          return textStyle
        }
      },
      {
        Header: 'Дилер', accessor: 'resultP2', width: 70,
        cellStyle: (textStyle, value, row, column) => {
          const { resultP1, resultP2 } = row._original;
          if ((resultP1.v > 21) || ((resultP1.v < resultP2.v) && (resultP2.v < 22))) {
            textStyle.backgroundColor = 'rgb(129, 163, 243)'
          }
          return textStyle
        }
      }
    ]
  },
  {
    Header: 'Карты', visible: true, key: 'cards',
    columns: [
      {
        Header: 'Игрок', accessor: 'p1Cards', width: 130,
        dic: ({ value }) => {
          let cards = value.v.split(',')
          if (!cards.length) return ''
          cards = cards.map((card, index) => {
            let divider = index < cards.length - 1 && ',';
            const mast = card.slice(-1);
            switch (mast) {
              case '♥':
              case '♦':
                return <span><span style={{ color: 'red' }}>{card}</span>{divider}</span>
              default:
                return <span><span>{card}</span>{divider}</span>
            }
          })
          return cards
        }
      },
      {
        Header: 'Дилер', accessor: 'p2Cards', width: 130,
        dic: ({ value }) => {
          let cards = value.v.split(',')
          if (!cards.length) return ''
          cards = cards.map((card, index) => {
            let divider = index < cards.length - 1 && ',';
            const mast = card.slice(-1);
            switch (mast) {
              case '♥':
              case '♦':
                return <span><span style={{ color: 'red' }}>{card}</span>{divider}</span>
              default:
                return <span><span>{card}</span>{divider}</span>
            }
          })
          return cards
        }
      }
    ]
  },
  {
    Header: '1x2', onlyVip: true, visible: true, key: 'x2',
    columns: [
      {
        Header: 'Игрок', accessor: 'x2p1', width: 70,
        cellStyle: (textStyle, value, row, column) => {
          const { resultP1, resultP2 } = row._original;
          if ((resultP2.v > 21) || ((resultP2.v < resultP1.v) && (resultP1.v < 22))) {
            textStyle.backgroundColor = 'rgb(129, 163, 243)'
          }
          return textStyle
        }
      },
      {
        Header: 'Ничья', accessor: 'x2x', width: 70,
        cellStyle: (textStyle, value, row, column) => {
          const { resultP1, resultP2 } = row._original;
          if (resultP1.v === resultP2.v) {
            textStyle.backgroundColor = 'rgb(148, 245, 135)'
          }
          return textStyle
        }
      },
      {
        Header: 'Дилер', accessor: 'x2p2', width: 70,
        cellStyle: (textStyle, value, row, column) => {
          const { resultP1, resultP2 } = row._original;
          if ((resultP1.v > 21) || ((resultP1.v < resultP2.v) && (resultP2.v < 22))) {
            textStyle.backgroundColor = 'rgb(129, 163, 243)'
          }
          return textStyle
        }
      }
    ]
  },

  {
    Header: 'Игрок наберёт 21', onlyVip: true, key: 'PGet21',
    columns: [
      {
        Header: 'Да', accessor: 'GamerGet21Y',
        cellStyle: (textStyle, value, row, column) => {
          if (isPlayer21(row)) {
            textStyle.backgroundColor = 'rgb(129, 163, 243)'
          }
          return textStyle
        }
      },
      {
        Header: 'Нет', accessor: 'GamerGet21N',
        cellStyle: (textStyle, value, row, column) => {
          if (!isPlayer21(row)) {
            textStyle.backgroundColor = 'rgb(129, 163, 243)'
          }
          return textStyle
        }
      }
    ]
  },
  {
    Header: 'Дилер наберёт 21', onlyVip: true, key: 'DGet21',
    columns: [
      {
        Header: 'Да', accessor: 'DilerGet21Y',
        cellStyle: (textStyle, value, row, column) => {
          if (isDiler21(row)) {
            textStyle.backgroundColor = '#AAFFAA'
          }
          return textStyle
        }
      },
      {
        Header: 'Нет', accessor: 'DilerGet21N',
        cellStyle: (textStyle, value, row, column) => {
          if (!isDiler21(row)) {
            textStyle.backgroundColor = '#AAFFAA'
          }
          return textStyle
        }
      }
    ]
  },

  {
    Header: 'В игре будет 21', onlyVip: true, key: 'Get21',
    columns: [
      {
        Header: 'Да', accessor: 'InGameGet21Y',
        cellStyle: (textStyle, value, row, column) => {
          if (isDiler21(row) || isPlayer21(row)) {
            textStyle.backgroundColor = '#FFAAAA'
          }
          return textStyle
        }
      },
      {
        Header: 'Нет', accessor: 'InGameGet21N',
        cellStyle: (textStyle, value, row, column) => {
          if (!isDiler21(row) && !isPlayer21(row)) {
            textStyle.backgroundColor = '#FFAAAA'
          }
          return textStyle
        }
      }
    ]
  },
  {
    Header: 'Закончится на раздаче', onlyVip: true, key: 'StopOnRazdacha',
    columns: [
      { Header: 'Да', accessor: 'StopOnRazdachaY' },
      { Header: 'Нет', accessor: 'StopOnRazdachaN' }
    ]
  },
  {
    Header: 'Закончится перебором', onlyVip: true, key: 'StopOnOver',
    columns: [
      { Header: 'Да', accessor: 'StopOnOverY' },
      { Header: 'Нет', accessor: 'StopOnOverN' }
    ]
  },
  columnGold,
  {
    Header: 'Перебор у игрока', onlyVip: true, key: 'OverOnGamer',
    columns: [
      {
        Header: 'Да', accessor: 'OverOnGamerY',
        cellStyle: (textStyle, value, row, column) => {
          if (isOverPlayer(row)) {
            textStyle.backgroundColor = 'rgb(129, 163, 243)'
          }
          return textStyle
        }
      },
      {
        Header: 'Нет', accessor: 'OverOnGamerN',
        cellStyle: (textStyle, value, row, column) => {
          if (!isOverPlayer(row)) {
            textStyle.backgroundColor = 'rgb(129, 163, 243)'
          }
          return textStyle
        }
      }
    ]
  },
  {
    Header: 'Перебор у дилера', onlyVip: true, key: 'OverOnDiler',
    columns: [
      {
        Header: 'Да', accessor: 'OverOnDilerY',
        cellStyle: (textStyle, value, row, column) => {
          if (isOverDiler(row)) {
            textStyle.backgroundColor = '#AAFFAA'
          }
          return textStyle
        }
      },
      {
        Header: 'Нет', accessor: 'OverOnDilerN',
        cellStyle: (textStyle, value, row, column) => {
          if (!isOverDiler(row)) {
            textStyle.backgroundColor = '#AAFFAA'
          }
          return textStyle
        }
      }
    ]
  },

  {
    Header: 'Игрок получит масть', onlyVip: true, key: 'GamerGetCartaM',
    columns: [
      { Header: '♠', accessor: 'GamerGetCarta0' },
      { Header: '♣', accessor: 'GamerGetCarta1' },
      { Header: '♦', accessor: 'GamerGetCarta2' },
      { Header: '♥', accessor: 'GamerGetCarta3' }
    ].map(column => {
      return {
        ...column,
        cellStyle: setStyleByCardPlayer
      }
    })
  },
  {
    Header: 'Дилер получит масть', onlyVip: true, key: 'DilerGetCartaM',
    columns: [
      {
        Header: '♠', accessor: 'DilerGetCarta0'
      },
      { Header: '♣', accessor: 'DilerGetCarta1', cellStyle: setStyleByCardDiler },
      { Header: '♦', accessor: 'DilerGetCarta2', cellStyle: setStyleByCardDiler },
      { Header: '♥', accessor: 'DilerGetCarta3', cellStyle: setStyleByCardDiler }
    ].map(column => {
      return {
        ...column,
        cellStyle: setStyleByCardDiler
      }
    })
  },

  {
    Header: 'Игрок получит знач.', onlyVip: true, key: 'GamerGetCartaZ',
    columns: [
      { Header: 'J', accessor: 'GamerGetCarta11' },
      { Header: 'Q', accessor: 'GamerGetCarta12' },
      { Header: 'K', accessor: 'GamerGetCarta13' },
      { Header: 'A', accessor: 'GamerGetCarta14' }
    ].map(column => {
      return {
        ...column,
        cellStyle: setStyleByCardPlayer
      }
    })
  },
  {
    Header: 'Дилер получит знач.', onlyVip: true, key: 'DilerGetCartaZ',
    columns: [
      { Header: 'J', accessor: 'DilerGetCarta11' },
      { Header: 'Q', accessor: 'DilerGetCarta12' },
      { Header: 'K', accessor: 'DilerGetCarta13' },
      { Header: 'A', accessor: 'DilerGetCarta14' }
    ].map(column => {
      return {
        ...column,
        cellStyle: setStyleByCardDiler
      }
    })
  },

  {
    Header: 'Игрок получит карту', onlyVip: true, key: 'GamerGetCarta',
    columns: [
      { Header: 'J♠', accessor: 'GamerGetCarta0011' },
      { Header: 'Q♠', accessor: 'GamerGetCarta0012' },
      { Header: 'K♠', accessor: 'GamerGetCarta0013' },
      // { Header: 'A♠', accessor: 'GamerGetCarta0014' },
      { Header: 'J♣', accessor: 'GamerGetCarta1011' },
      { Header: 'Q♣', accessor: 'GamerGetCarta1012' },
      { Header: 'K♣', accessor: 'GamerGetCarta1013' },
      // { Header: 'A♣', accessor: 'GamerGetCarta1014' },
      { Header: 'J♦', accessor: 'GamerGetCarta2011' },
      { Header: 'Q♦', accessor: 'GamerGetCarta2012' },
      { Header: 'K♦', accessor: 'GamerGetCarta2013' },
      // { Header: 'A♦', accessor: 'GamerGetCarta2014' },
      { Header: 'J♥', accessor: 'GamerGetCarta3011' },
      { Header: 'Q♥', accessor: 'GamerGetCarta3012' },
      { Header: 'K♥', accessor: 'GamerGetCarta3013' },
      // { Header: 'A♥', accessor: 'GamerGetCarta3014' }
    ].map(column => {
      return {
        ...column,
        cellStyle: setStyleByCardPlayer
      }
    })
  },
  {
    Header: 'Дилер получит карту', onlyVip: true, key: 'DilerGetCarta',
    columns: [
      { Header: 'J♠', accessor: 'DilerGetCarta0011' },
      { Header: 'Q♠', accessor: 'DilerGetCarta0012' },
      { Header: 'K♠', accessor: 'DilerGetCarta0013' },
      // { Header: 'A♠', accessor: 'DilerGetCarta0014' },
      { Header: 'J♣', accessor: 'DilerGetCarta1011' },
      { Header: 'Q♣', accessor: 'DilerGetCarta1012' },
      { Header: 'K♣', accessor: 'DilerGetCarta1013' },
      // { Header: 'A♣', accessor: 'DilerGetCarta1014' },
      { Header: 'J♦', accessor: 'DilerGetCarta2011' },
      { Header: 'Q♦', accessor: 'DilerGetCarta2012' },
      { Header: 'K♦', accessor: 'DilerGetCarta2013' },
      // { Header: 'A♦', accessor: 'DilerGetCarta2014' },
      { Header: 'J♥', accessor: 'DilerGetCarta3011' },
      { Header: 'Q♥', accessor: 'DilerGetCarta3012' },
      { Header: 'K♥', accessor: 'DilerGetCarta3013' },
      // { Header: 'A♥', accessor: 'DilerGetCarta3014' }
    ].map(column => {
      return {
        ...column,
        cellStyle: setStyleByCardDiler
      }
    })
  },
  {
    Header: 'Тотал', onlyVip: true, key: 'total',
    columns: [
      {
        Header: 'Б', accessor: 'Total1BKef',
        cellStyle: (textStyle, value, row, column) => {
          if (isTotalB(row, 'Total1B')) {
            textStyle.backgroundColor = '#AAFFAA'

          }
          return textStyle
        }
      },
      { Header: '1', accessor: 'Total1B' },
      {
        Header: 'М', accessor: 'Total1MKef',
        cellStyle: (textStyle, value, row, column) => {
          if (!isTotalB(row, 'Total1B')) {
            textStyle.backgroundColor = '#AAFFAA'

          }
          return textStyle
        }
      },
      {
        Header: 'Б', accessor: 'Total2BKef',
        cellStyle: (textStyle, value, row, column) => {
          if (isTotalB(row, 'Total2B')) {
            textStyle.backgroundColor = '#AAFFAA'

          }
          return textStyle
        }
      },
      { Header: '2', accessor: 'Total2B' },
      {
        Header: 'М', accessor: 'Total2MKef',
        cellStyle: (textStyle, value, row, column) => {
          if (!isTotalB(row, 'Total2B')) {
            textStyle.backgroundColor = '#AAFFAA'

          }
          return textStyle
        }
      },
      {
        Header: 'Б', accessor: 'Total3BKef',
        cellStyle: (textStyle, value, row, column) => {
          if (isTotalB(row, 'Total3B')) {
            textStyle.backgroundColor = '#AAFFAA'

          }
          return textStyle
        }
      },
      { Header: '3', accessor: 'Total3B' },
      {
        Header: 'М', accessor: 'Total3MKef',
        cellStyle: (textStyle, value, row, column) => {
          if (!isTotalB(row, 'Total3B')) {
            textStyle.backgroundColor = '#AAFFAA'

          }
          return textStyle
        }
      },
    ]
  },
  {
    Header: 'Тотал игрока', onlyVip: true, key: 'Ptotal',
    columns: [
      {
        Header: 'Б', accessor: 'TotalPlayer1BKef',
        cellStyle: (textStyle, value, row, column) => {
          if (row._original.resultP1.v
            && row._original.TotalPlayer1B.v
            && row._original.resultP1.v > row._original.TotalPlayer1B.v) {
            textStyle.backgroundColor = 'rgb(129, 163, 243)'
          }
          return textStyle
        }
      },
      { Header: '', accessor: 'TotalPlayer1B' },
      {
        Header: 'М', accessor: 'TotalPlayer1MKef',
        cellStyle: (textStyle, value, row, column) => {
          if (row._original.resultP1.v
            && row._original.TotalPlayer1B.v
            && row._original.resultP1.v < row._original.TotalPlayer1B.v) {
            textStyle.backgroundColor = 'rgb(129, 163, 243)'
          }
          return textStyle
        }
      }
    ]
  },
  {
    Header: 'Тотал дилер', onlyVip: true, key: 'Dtotal',
    columns: [
      {
        Header: 'Б', accessor: 'TotalDealer1BKef',
        cellStyle: (textStyle, value, row, column) => {
          if (row._original.resultP2.v
            && row._original.TotalDealer1B.v
            && row._original.resultP2.v > row._original.TotalDealer1B.v) {
            textStyle.backgroundColor = '#AAFFAA'
          }
          return textStyle
        }
      },
      { Header: '', accessor: 'TotalDealer1B' },
      {
        Header: 'М', accessor: 'TotalDealer1MKef',
        cellStyle: (textStyle, value, row, column) => {
          if (row._original.resultP2.v
            && row._original.TotalDealer1B.v
            && row._original.resultP2.v < row._original.TotalDealer1B.v) {
            textStyle.backgroundColor = '#AAFFAA'
          }
          return textStyle
        }
      }
    ]
  },
];

function clearFilerInColums(columns) {
  console.log(columns);
  return columns.map(column => {
    if (column.columns) {
      column.columns = clearFilerInColums(column.columns)
    } else {
      column.Filter = <div></div>;
    }
    return column;
  })
}

class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: baseColumns
    }
  }

  handleChangeVisibleColumn(visibleColumnKeys) {
    const { columns } = this.state;
    const newColumns = columns.map(column => ({ ...column, visible: column.noHide ? true : visibleColumnKeys.includes(column.key) }));
    this.setState({
      columns: newColumns
    })
    this.saveSettings({ columns: newColumns })
  }

  loadSettings() {
    const settingSrc = localStorage.getItem(`TO_store_table_visible`);
    if (settingSrc === null) return false;
    try {
      const setting = JSON.parse(settingSrc);
      console.log(setting);
      if (!setting.visibleColumn) return false;
      const visObj = setting.visibleColumn.reduce((result, vis) => {
        result[vis.key] = vis.visible;
        return result;
      }, {})

      const newColumns = this.state.columns.map(col => {
        if (typeof visObj[col.key] !== "undefined") col.visible = visObj[col.key];
        return col
      })
      this.setState({
        columns: newColumns
      });

    } catch (err) {
      console.log(err)
    }
  }

  saveSettings(customSetting = {}) {
    const { columns } = this.state;
    const cols = customSetting.columns ? customSetting.columns : columns

    const setting = {
      visibleColumn: cols.map(column => ({
        key: column.key,
        visible: column.visible ? column.visible : false
      }))
    }

    localStorage.setItem(`TO_store_table_visible`, JSON.stringify(setting))
  }

  componentDidMount() {
    this.loadSettings()
  }

  render() {
    const topColResponsiveProps = {
      xs: 24,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 8,
    };

    const { user } = this.props;
    let { columns } = this.state;
    if (user && user.vip === 0) {
      columns = columns.filter(column => !column.onlyVip)
      columns = clearFilerInColums(columns);
    }
    let visibleColumns = columns.filter(column => column.visible)
    return (
      <div style={{ minHeight: 360 }} >
        <MetaTags>
          <title>Статистика 21 очко</title>
          <meta name="description" content="Статистика 21 очко 1xbet" />
          <meta name="keywords" content="21 очко, двадцать одно, очко, 1xbet" />
        </MetaTags>
        <Row gutter={16}>
          <Col {...topColResponsiveProps}>
            <StatColumnVisibleChanger
              storagePrefix='21'
              columns={columns}
              handleChangeVisibleColumn={(key, value) => { this.handleChangeVisibleColumn(key, value) }}
            />
          </Col>
        </Row><Row gutter={16}>
          <Col span={24}>
            <Card bordered={false}>
              <StatsTable
                columns={visibleColumns}
                localStoreKey='21_store'
                dataApiUrl='/api/21/stat'
                wsUrl='/ws/to'
              />
            </Card>
          </Col>
        </Row>
      </div >
    )
  }
}

export default Stats