import React from 'react';
import { Form, Input, Select, Button } from 'antd';

const FormItem = Form.Item;
const Option = Select.Option;
const durationData = ["1 week", "1 month", "2 month", "3 month"];
const durationToCost = {
  "1 week": ['200'],
  "1 month": ['500'],
  "2 month": ['1000'],
  "3 month": ['1500']
};

class FormToSale extends React.Component {
  state = {
    costValueObj: durationToCost[durationData[1]],//объект стоимость
    costValueNumb: durationToCost[durationData[1]][0],//значение стоимости
    vkId: "",
    duration: durationData[1],
    cost: durationToCost[durationData[1]],
    telegramLogin: ""
  }

  handleDurationChange = (value) => {
    this.setState({
      costValueObj: durationToCost[value],
      costValueNumb: durationToCost[value][0],
      duration: value,
      cost: durationToCost[value][0]
    });
  }

  costChenger = (value) => {
    this.setState({
      costValueNumb: value,
    });
  }
  handleChangeVkId = (e) => {
    this.setState({
      vkId: [e.target.value],
    });
  }
  handleChangeTelegramLogin = (e) => {
    this.setState({
      telegramLogin: e.target.value,
    });
  }

  handleAdd = () => {
    const { vkId, duration, cost, telegramLogin } = this.state

    var options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };

    let dateWithDuration = new Date()
    dateWithDuration.setMonth(dateWithDuration.getMonth() + parseInt(duration, 10))
    let dateEndingToTable = dateWithDuration.toLocaleString("ru", options)

    const dataToTable = {
      vkId: vkId[0],
      telegramLogin: telegramLogin,
      duration: duration,
      cost: cost,
      dateEnding: dateEndingToTable
    }
    this.props.updateData(dataToTable)
  }

  handleCancel = () => {
    this.props.closeModal({ visible: false })
  }

  render() {
    const { costValueObj, vkId, telegramLogin, costValueNumb } = this.state;
    return (
      <Form layout="horizontal" onSubmit={this.handleSubmit}>
        <FormItem>
          <Input
            value={vkId}
            placeholder="vk_id"
            onChange={this.handleChangeVkId}
          />
        </FormItem>
        <FormItem>
          <Input
            value={telegramLogin}
            placeholder="telegramLogin"
            onChange={this.handleChangeTelegramLogin}
          />
        </FormItem>
        <FormItem>
          <Select
            defaultValue={"1 month"}
            style={{ width: '100%' }}
            onChange={this.handleDurationChange}
          >
            {durationData.map(duration => <Option key={duration}>{duration}</Option>)}
          </Select>
        </FormItem>
        <FormItem>
          <Select
            style={{ width: '100%' }}
            value={costValueNumb}
            onChange={this.costChenger}
            filterOption={false}
            showArrow={false}
            disabled
          >
            {costValueObj.map(city => <Option key={city}>{city}</Option>)}
          </Select>
        </FormItem>
        <Button key="back" onClick={this.handleCancel}>Отмена</Button>,
            <Button key="submit" type="primary" onClick={this.handleAdd}>
          Подтвердить
            </Button>
      </Form>
    );
  }
}

export { FormToSale }
