import React, { Component } from 'react';
import { Switch, Route } from 'react-router'
import { inject, observer } from "mobx-react";

import Stats from './Stats.jsx'
import Toto from './Toto.jsx'

import { PageLoading } from '../../Common';

import axios from 'axios';

export { Stats, Toto };
@inject("fifaStore") @observer
class FIFA extends Component {
  constructor(prop) {
    super(prop)
    this.state = {
      loading: false,
      inGroup: true
    }
  }

  // checkInGroup() {
  //   const { user } = this.props
  //   if (user.vkid) {
  //     if (user.vkid === -1) {
  //       this.setState({
  //         loading: false,
  //         inGroup: true
  //       })
  //     } else {
  //       axios.post(`/auth/vk/ismember`, { groupId: 161935181, userId: user.vkid })
  //         .then(data => {
  //           console.log(data);
  //           if (data && data.data && data.data.result) {
  //             this.setState({
  //               loading: false,
  //               inGroup: true
  //             })
  //           } else {
  //             this.setState({
  //               loading: false,
  //               inGroup: false
  //             })
  //           }
  //         }).catch(err => {
  //           console.log(err);
  //           this.setState({
  //             loading: false,
  //             inGroup: false
  //           })
  //         })
  //     }
  //   } else {
  //     this.setState({
  //       loading: false,
  //       inGroup: false
  //     })
  //   }
  // }

  componentDidMount() {
    // this.checkInGroup();
    this.props.fifaStore.initStore()
    
  }

  render() {
    const { user } = this.props
    const { loading, inGroup } = this.state
    return loading ? <PageLoading /> : !inGroup ? (<div>
      Для доступа, нужно вступить в
      <a href={'https://vk.com/profitsfifa'}>https://vk.com/profitsfifa</a>
    </div>) : (<Switch>
      <Route exact path="/fifa"
        render={(props) => <Stats {...props} user={user} />}
      />
      <Route path="/fifa/stat"
        render={(props) => <Stats {...props} user={user} />}
      />
      <Route path="/fifa/toto"
        render={(props) => <Toto {...props} user={user} />}
      />
    </Switch>)
  }
}

export default FIFA