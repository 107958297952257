
export default [{
  path: '/',
  name: 'Home',
  icon: 'home',
  // component: Home
},
{
  path: '/fifa',
  name: 'Fifa',
  icon: 'futbal',
  // admin: 5,
  // component: Fifa.Stats,
  subroutes: [
    {
      path: '/fifa/stat',
      name: 'Статистика',
      icon: 'none',
      // component: Fifa.Stats
    },
    {
      path: '/fifa/toto',
      name: 'Тото',
      icon: 'none',
      // component: Fifa.Stats
    },
  ]
},
{
  path: '/tekken/stat',
  name: 'Tekken',
  icon: 'futbal',
  // admin: 5,
  // component: Fifa.Stats,
  subroutes: [
    {
      path: '/tekken/stat',
      name: 'Статистика',
      icon: 'none',
      // component: Fifa.Stats
    }
  ]
},
{
  path: '/ki',
  name: 'Killer Instinct',
  icon: 'futbal',
  // admin: 5,
  // component: Fifa.Stats,
  subroutes: [
    {
      path: '/ki/stat',
      name: 'Статистика',
      icon: 'none',
      // component: Fifa.Stats
    },
    {
      path: '/ki/analiz',
      name: 'Анализ',
      icon: 'none',
      // component: Fifa.Stats
    }
  ]
},
{
  path: '/ufc',
  name: 'UFC',
  icon: 'admin',
  admin: 5,
  // component: Users
},
{
  path: '/mk',
  name: 'MK',
  icon: 'futbal',
  // component: Fifa.Stats,
  subroutes: [
    {
      path: '/mk/stat',
      name: 'Статистика',
      icon: 'none',
      // component: Fifa.Stats
    },
    {
      path: '/mk/analiz',
      name: 'Анализ',
      icon: 'none',
      // component: Fifa.Stats
    }
  ]
},
{
  path: '/21',
  name: '21',
  icon: 'futbal',
  // admin: 5,
  // component: Fifa.Stats,
  subroutes: [
    {
      path: '/21/stat',
      name: 'Статистика',
      icon: 'none',
      // component: Fifa.Stats
    },
  ]
},

{
  path: '/tools',
  name: 'Калькуляторы',
  icon: 'tool',
  subroutes: [
    {
      path: '/tools/dogon',
      name: 'Догон',
      icon: 'none',
    },
    // {
    //   path: '/tools/dalamber',
    //   name: 'Даламбер',
    //   icon: 'none',
    // },
    // {
    //   path: '/tools/ladder',
    //   name: 'Лесенка',
    //   icon: 'none',
    // }
  ]
},
{
  path: '/admin/users',
  name: 'Admin',
  icon: 'usergroup-add',
  admin: 5,
  // component: Users,
  subroutes: [
    {
      path: '/admin/users',
      name: 'Пользователи',
      icon: 'none',
      // component: Fifa.Stats
    },
    {
      path: '/admin/telegram',
      name: 'Телега',
      icon: 'none',
      // component: Fifa.Stats
    },
    {
      path: '/admin/tenis',
      name: 'Тенис',
      icon: 'none',
      // component: Fifa.Stats
    },
    {
      path: '/admin/fifa',
      name: 'FIFA',
      icon: 'none',
      // component: Fifa.Stats
    },
    {
      path: '/admin/ki',
      name: 'КиТ',
      icon: 'none',
      // component: Fifa.Stats
    },
    {
      path: '/admin/sessions',
      name: 'Сессии',
      icon: 'none',
      // component: Fifa.Stats
    }
  ]
},
{
  path: '/api/auth/sign-out',
  name: 'Выход',
  icon: 'logout',
  type: 'a',
  // component: Home
},
]