import React, { Component } from 'react';
import { Table, Card } from 'antd';
import { inject, observer } from "mobx-react";


const addAnalizColumsTmpl = [
  {
    title: `R`,
    className: 'dobiv_r',
    children: [
      { title: `Общ.`, dataIndex: `r`, align: 'center', className: 'dobiv_r' },
      { title: `П1`, dataIndex: `rp1`, align: 'center', className: 'dobiv_r' },
      { title: `П2`, dataIndex: `rp2`, align: 'center', className: 'dobiv_r' },
    ].map(child => ({
      ...child,
      render: (text, record, index) => {
        const textStyle = {}
        if (Number(text) >= 1 && Number(text) <= 1.8) {
          textStyle.backgroundColor = '#AAAAAA'
        }
        return <div style={textStyle}>{text}</div>
      }
    }))
  },
  {
    title: `F`,
    className: 'dobiv_f',
    children: [
      { title: `Общ.`, dataIndex: `f`, align: 'center', className: 'dobiv_f' },
      { title: `П1`, dataIndex: `fp1`, align: 'center', className: 'dobiv_f' },
      { title: `П2`, dataIndex: `fp2`, align: 'center', className: 'dobiv_f' },
    ].map(child => ({
      ...child,
      render: (text, record, index) => {
        const textStyle = {}
        if (Number(text) >= 1 && Number(text) <= 3.3) {
          textStyle.backgroundColor = '#FFAAAA'
        }
        return <div style={textStyle}>{text}</div>
      }
    }))
  },
  {
    title: `B`,
    className: 'dobiv_b',
    children: [
      { title: `Общ.`, dataIndex: `b`, align: 'center', className: 'dobiv_b' },
      { title: `П1`, dataIndex: `bp1`, align: 'center', className: 'dobiv_b' },
      { title: `П2`, dataIndex: `bp2`, align: 'center', className: 'dobiv_b' },
    ].map(child => ({
      ...child,
      render: (text, record, index) => {
        const textStyle = {}
        if (Number(text) >= 1 && Number(text) <= 5) {
          textStyle.backgroundColor = '#AAFFAA'
        }
        return <div style={textStyle}>{text}</div>
      }
    }))
  },
  {
    title: `Время`,
    dataIndex: 'time',
    render: (value, record, index) => {
      console.log(value)
      console.log(record)
      const textStyle = {}
      if (record.first_row.timep2b) {
        if (record.first_row.timep2b.v > value) {
          textStyle.backgroundColor = '#AAFFAA'
        }
        if (record.first_row.timep2b.v < value) {
          textStyle.backgroundColor = '#A3CCFA'
        }
      }
      return <div style={textStyle}>{value}</div>
    }
  }
].map(coll => ({ ...coll, align: 'center' }))

@inject("mkStore") @observer
class AvgStat extends Component {
  render() {
    const { avgStat, loading } = this.props.mkStore.stat;
    // const { loading } = this.props;

    const addAnalizColums = addAnalizColumsTmpl.map(item => item);

    return (
      <Card
        title="Среднее"
        style={{ marginBottom: 12 }}
        size='small'
      >
        <Table
          style={{ marginTop: '10px' }}
          dataSource={avgStat}
          columns={addAnalizColums}
          bordered
          pagination={false}
          loading={loading}
        />
      </Card>)
  }
}

export default AvgStat