import React from 'react';
import { Form, Input, Select, Button, DatePicker } from 'antd';

import moment from 'moment';
import 'moment/locale/ru';

const FormItem = Form.Item;
const Option = Select.Option;
const durationData = ["1 month", "2 month", "3 month"];
const durationToCost = {
  "1 month": ['500'],
  "2 month": ['1000'],
  "3 month": ['1500']
};

const dateFormat = 'YYYY-MM-DD';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class FormEditRow extends React.Component {
  state = {
    id: 0,
    vkid: 0,
    telegramLogin: "",
    admin: 0,
    vip: 0,
    datestop: ""
  }

  componentDidMount = () => {
    const { editRowData } = this.props;
    this.setState({
      id: editRowData.id.v,
      vkid: editRowData.vkid.v,
      telegramLogin: editRowData.telegramLogin.v,
      admin: editRowData.admin.v,
      vip: editRowData.vip.v,
      datestop: editRowData.datestop.v
    })
    console.log(editRowData)
  }


  handleChangeVkId = (e) => {
    this.setState({
      vkid: e.target.value,
    });
  }

  handleChangeTelegramLogin = (e) => {
    this.setState({
      telegramLogin: e.target.value,
    });
  }

  handleChangeDateStop = (e,dateString) => {
    this.setState({
      datestop: dateString,
    });
  }

  handleSave = () => {
    const { id, vkid, telegramLogin, admin, vip, datestop } = this.state;

    const dataToTable = {
      id,
      vkid,
      telegramLogin,
      datestop
    }
    this.props.updateData(dataToTable)
  }

  handleCancel = () => {
    this.props.closeModal({ visible: false })
  }

  render() {
    const { id, vkid, telegramLogin, admin, vip, datestop } = this.state;
    console.log(this.state)
    return (
      <Form layout="horizontal" onSubmit={this.handleSubmit}>
        <FormItem label="ID в базе">
          <Input
            value={id}
            placeholder="id"
            disabled
          />
        </FormItem>
        <FormItem label="VK Id">
          <Input
            value={vkid}
            placeholder="vkid"
            onChange={this.handleChangeVkId}
          />
        </FormItem>
        <FormItem label="Telegram login">
          <Input
            value={telegramLogin}
            placeholder="telegramLogin"
            onChange={this.handleChangeTelegramLogin}
          />
        </FormItem>
        <FormItem label="Дата окончания подписки">
          <DatePicker
            value={moment(datestop, dateFormat)}
            format={dateFormat}
            onChange={this.handleChangeDateStop}
            allowClear={false}
          />
        </FormItem>

        <Button key="back" onClick={this.handleCancel}>Отмена</Button>,
            <Button key="submit" type="primary" onClick={this.handleSave}>
          Сохранить
            </Button>
      </Form>
    );
  }
}

export { FormEditRow }
