import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

import { Tabs } from 'antd';

const { TabPane } = Tabs;

class GraphAnaliz extends Component {
  constructor(props) {
    super(props);

    const avgTime = 10;

    this.state = {
      series: [],
      options: {
        chart: {
          height: 350,
          type: 'line'
        },
        stroke: {
          width: 5,
          curve: 'smooth'
        },
        xaxis: {},
        title: {
          text: '',
          align: 'left',
          style: {
            fontSize: '16px',
            color: '#666'
          }
        },
        fill: {
          type: ['gradient', 'solid', 'solid'],
          gradient: {
            shade: 'dark',
            gradientToColors: ['#FDD835'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          }
        },
        markers: {
          size: 4,
          colors: ['#FFA41B'],
          strokeColors: '#fff',
          strokeWidth: 1,
          hover: {
            size: 7
          }
        }
        // yaxis: {
        //   min: -100,
        //   max: 100,
        //   title: {
        //     text: ''
        //   }
        // }
      },
      seriesAut: [],
      seriesR: [],
      seriesF: [],
      seriesB: [],
      seriesTotal: [],
      optionsAut: {
        chart: {
          height: 350,
          type: 'line'
        },
        stroke: {
          width: 5,
          curve: 'smooth'
        },
        xaxis: {},
        title: {
          text: '',
          align: 'left',
          style: {
            fontSize: '16px',
            color: '#666'
          }
        },
        fill: {
          type: ['gradient', 'solid', 'solid'],
          gradient: {
            shade: 'dark',
            gradientToColors: ['#FDD835'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          }
        },
        markers: {
          size: 4,
          colors: ['#FFA41B'],
          strokeColors: '#fff',
          strokeWidth: 1,
          hover: {
            size: 7
          }
        },
        yaxis: {
          title: {
            text: '%'
          }
        }
      },
      seriesTimeAvgB: [],
      seriesTimeAvgM: [],
      seriesTimeMinB: [],
      seriesTimeMinM: [],
      seriesTimeMaxB: [],
      seriesTimeMaxM: [],
      user: {}
    };
  }

  componentWillReceiveProps(props) {
    const {
      graphAvgtime,
      graphAvgAut,
      graphDobiv,
      graphDobivAvg,
      graphTotal,
      graphAvgtimeAvg,
      graphTimes,
      user
    } = props;

    const series = [
      {
        name: 'Скользящая',
        data: []
      },
      {
        name: 'Среднее',
        data: []
      }
    ];

    const { options } = this.state;

    // options.xaxis.categories = []

    // console.log(graphAvgtime);
    for (let i = 0; i < graphAvgtime.length; i += 1) {
      series[1].data.push(graphAvgtimeAvg);
      series[0].data.push(graphAvgtime[i]);
      // options.xaxis.categories.push(i+1)
    }

    const seriesAut = [
      {
        name: 'Скользящая',
        data: []
      },
      {
        name: 'Среднее',
        data: []
      }
    ];

    for (let i = 0; i < graphAvgAut.groupAvg.length; i += 1) {
      seriesAut[1].data.push(graphAvgAut.totalAvg);
      seriesAut[0].data.push(graphAvgAut.groupAvg[i]);
      // options.xaxis.categories.push(i+1)
    }

    const seriesR = [
      {
        name: 'Скользящая',
        data: []
      },
      {
        name: 'Среднее',
        data: []
      }
    ];

    const seriesF = [
      {
        name: 'Скользящая',
        data: []
      },
      {
        name: 'Среднее',
        data: []
      }
    ];

    const seriesB = [
      {
        name: 'Скользящая',
        data: []
      },
      {
        name: 'Среднее',
        data: []
      }
    ];

    for (let i = 0; i < graphDobiv.length; i += 1) {
      seriesR[1].data.push(graphDobivAvg.r);
      seriesR[0].data.push(graphDobiv[i].r);

      seriesF[1].data.push(graphDobivAvg.f);
      seriesF[0].data.push(graphDobiv[i].f);

      seriesB[1].data.push(graphDobivAvg.b);
      seriesB[0].data.push(graphDobiv[i].b);
    }

    const seriesTotal = [
      {
        name: 'Скользящая',
        data: []
      },
      {
        name: 'Среднее',
        data: []
      },
      {
        name: 'Среднее',
        data: []
      }
    ];

    for (let i = 0; i < graphTotal.length; i += 1) {
      seriesTotal[1].data.push(7.5);
      seriesTotal[2].data.push(6.5);
      seriesTotal[0].data.push(graphTotal[i]);
    }

    const seriesTimeAvgB = [
      {
        name: 'Скользящая',
        data: []
      },
      {
        name: 'Среднее',
        data: []
      },
      {
        name: 'Среднее',
        data: []
      }
    ];

    const seriesTimeAvgM = JSON.parse(JSON.stringify(seriesTimeAvgB));

    const seriesTimeMinM = JSON.parse(JSON.stringify(seriesTimeAvgB));

    // const seriesTimeMinB = JSON.parse(JSON.stringify(seriesTimeAvgB));
    // const seriesTimeMaxM = JSON.parse(JSON.stringify(seriesTimeAvgB));
    const seriesTimeMaxB = JSON.parse(JSON.stringify(seriesTimeAvgB));

    const avgValueTime = (times, index) =>
      times
        ? Math.round(
            (times.reduce((acc, item) => acc + item[index], 0) / times.length) *
              10
          ) / 10
        : 0;

    // const avgValueTimeMinB = avgValueTime(graphTimes.times, 'minB');
    const avgValueTimeMinM = avgValueTime(graphTimes.times, 'minM');
    const avgValueTimeAvgB = avgValueTime(graphTimes.times, 'avgB');
    const avgValueTimeAvgM = avgValueTime(graphTimes.times, 'avgM');
    const avgValueTimeMaxB = avgValueTime(graphTimes.times, 'maxB');
    // const avgValueTimeMaxM = avgValueTime(graphTimes.times, 'maxM');

    for (let i = 0; i < graphTimes.times.length; i += 1) {
      // seriesTimeMinB[1].data.push(graphTimes.avg.minB);
      // seriesTimeMinB[2].data.push(avgValueTimeMinB);
      // seriesTimeMinB[0].data.push(graphTimes.times[i].minB);

      seriesTimeMinM[1].data.push(graphTimes.avg.minM);
      seriesTimeMinM[2].data.push(avgValueTimeMinM);
      seriesTimeMinM[0].data.push(graphTimes.times[i].minM);

      seriesTimeAvgB[1].data.push(graphTimes.avg.avgB);
      seriesTimeAvgB[2].data.push(avgValueTimeAvgB);
      seriesTimeAvgB[0].data.push(graphTimes.times[i].avgB);

      seriesTimeAvgM[1].data.push(graphTimes.avg.avgM);
      seriesTimeAvgM[2].data.push(avgValueTimeAvgM);
      seriesTimeAvgM[0].data.push(graphTimes.times[i].avgM);

      seriesTimeMaxB[1].data.push(graphTimes.avg.maxB);
      seriesTimeMaxB[2].data.push(avgValueTimeMaxB);
      seriesTimeMaxB[0].data.push(graphTimes.times[i].maxB);

      // seriesTimeMaxM[1].data.push(graphTimes.avg.maxM);
      // seriesTimeMaxM[2].data.push(avgValueTimeMaxM);
      // seriesTimeMaxM[0].data.push(graphTimes.times[i].maxM);
    }

    console.log(user);

    this.setState({
      series,
      seriesAut,
      seriesR,
      seriesF,
      seriesB,
      seriesTotal,
      // seriesTimeMinB,
      seriesTimeMinM,
      seriesTimeAvgB,
      seriesTimeAvgM,
      seriesTimeMaxB,
      // seriesTimeMaxM,
      user
    });
  }

  render() {
    return (
      <div id="chart" style={{ padding: '10px' }}>
        <Tabs>
          <TabPane tab="Время" key="1">
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="line"
              height={350}
            />
          </TabPane>

          <TabPane tab="Время сред. Б" key="9">
            <ReactApexChart
              options={this.state.optionsAut}
              series={this.state.seriesTimeAvgB}
              type="line"
              height={350}
            />
          </TabPane>

          <TabPane tab="Время сред. М" key="10">
            <ReactApexChart
              options={this.state.optionsAut}
              series={this.state.seriesTimeAvgM}
              type="line"
              height={350}
            />
          </TabPane>

          {/* {this.state.user && this.state.user.admin && (
            <TabPane tab="Время мин. Б" key="7">
              <ReactApexChart
                options={this.state.optionsAut}
                series={this.state.seriesTimeMinB}
                type="line"
                height={350}
              />
            </TabPane>
          )} */}
          {/* {this.state.user && this.state.user.admin && ( */}
            <TabPane tab="Время мин. М" key="8">
              <ReactApexChart
                options={this.state.optionsAut}
                series={this.state.seriesTimeMinM}
                type="line"
                height={350}
              />
            </TabPane>
          {/* )} */}

          {/* {this.state.user && this.state.user.admin && ( */}
            <TabPane tab="Время макс. Б" key="11">
              <ReactApexChart
                options={this.state.optionsAut}
                series={this.state.seriesTimeMaxB}
                type="line"
                height={350}
              />
            </TabPane>
          {/* )} */}
          {/* {this.state.user && this.state.user.admin && (
            <TabPane tab="Время макс. М" key="12">
              <ReactApexChart
                options={this.state.optionsAut}
                series={this.state.seriesTimeMaxM}
                type="line"
                height={350}
              />
            </TabPane>
          )} */}
          <TabPane tab="Аут" key="2">
            <ReactApexChart
              options={this.state.optionsAut}
              series={this.state.seriesAut}
              type="line"
              height={350}
            />
          </TabPane>
          <TabPane tab="Без добивания" key="3">
            <ReactApexChart
              options={this.state.optionsAut}
              series={this.state.seriesR}
              type="line"
              height={350}
            />
          </TabPane>
          <TabPane tab="Фаталити" key="4">
            <ReactApexChart
              options={this.state.optionsAut}
              series={this.state.seriesF}
              type="line"
              height={350}
            />
          </TabPane>
          <TabPane tab="Бруталити" key="5">
            <ReactApexChart
              options={this.state.optionsAut}
              series={this.state.seriesB}
              type="line"
              height={350}
            />
          </TabPane>
          <TabPane tab="Тотал" key="6">
            <ReactApexChart
              options={this.state.optionsAut}
              series={this.state.seriesTotal}
              type="line"
              height={350}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default GraphAnaliz;
