import React from 'react';

import { Form, InputNumber, Table, Radio } from 'antd';


const columns = [{
  title: 'Номер ставки',
  dataIndex: 'hop',
  width: 50
}, {
  title: 'Сумма',
  dataIndex: 'bet',
  width: 100
}];

export default class Dogon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minBet: 10,
      gain: 10,
      kef: 2,
      checked: 'bet',
      data: []
    }
  }

  onChangeMinBet(value) {
    const data = this.calculateData({ minBet: value })

    this.setState({
      minBet: value,
      data
    })
  }

  onChangeKef(value) {
    const data = this.calculateData({ kef: value })
    this.setState({
      kef: value,
      data
    })
  }

  onChangeGain(value) {
    const data = this.calculateData({ gain: value })
    this.setState({
      gain: value,
      data
    })
  }

  onChangeRadio(e) {
    const data = this.calculateData({ checked: e.target.value })
    this.setState({
      checked: e.target.value,
      data
    });
  }

  calculateData({ minBet, kef, gain, checked }) {
    let locMinBet = typeof minBet !== "undefined" ? minBet : this.state.minBet;
    let locKef = typeof kef !== "undefined" ? kef : this.state.kef;
    let locGain = typeof gain !== "undefined" ? gain : this.state.gain;
    let locChecked = typeof checked !== "undefined" ? checked : this.state.checked;

    if ((locChecked === 'gain') && (locKef === 1)) return []

    const priz = locChecked === 'bet' ? locMinBet * (locKef - 1) : locGain

    let sumStav = 0;
    let Hops = Array.from({ length: 9 }, (value, index) => {
      const stav = (priz + sumStav) / (locKef - 1)
      sumStav = sumStav + stav;
      return {
        hop: index + 1,
        bet: stav.toFixed(2)
      }
    })
    return Hops
  }

  componentDidMount() {
    const data = this.calculateData({})
    this.setState({
      data
    })
  }

  render() {
    const { minBet, kef, data, checked } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 12 },
        md: { span: 12 },
        lg: { span: 12 }
      },
      wrapperCol: {
        xs: { span: 10 },
        sm: { span: 10 },
        md: { span: 10 },
        lg: { span: 10 }
      },
    };

    return (
      <div>
        <Form onSubmit={() => { }} style={{ margin: 0 }}>
          <Form.Item style={{ width: '100%', margin: 0 }}>
            <Radio.Group onChange={(e) => { this.onChangeRadio(e) }} value={checked} size="medium" style={{ width: '100%' }}>
              <Radio.Button value={'bet'}>Минимальная ставка</Radio.Button>
              <Radio.Button value={'gain'}>Желаемый выигрыш</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {checked === 'bet' && (<Form.Item {...formItemLayout} style={{ width: '100%', margin: 0 }} label="Минимальная ставка">
            <InputNumber
              min={10}
              step={1}
              style={{ width: '100%' }}
              value={minBet}
              onChange={(value) => { this.onChangeMinBet(value) }} />
          </Form.Item>)}
          {checked === 'gain' && (<Form.Item {...formItemLayout} style={{ width: '100%', margin: 0 }} label="Желаемый выигрыш">
            <InputNumber
              min={10}
              step={1}
              style={{ width: '100%' }}
              value={minBet}
              onChange={(value) => { this.onChangeGain(value) }} />
          </Form.Item>)}
          <Form.Item {...formItemLayout} style={{ width: '100%', margin: 0 }} label="Коэффициент">
            <InputNumber
              min={1}
              step={0.1}
              style={{ width: '100%' }}
              value={kef}
              onChange={(value) => { this.onChangeKef(value) }}
            />
          </Form.Item>
          <Table
            rowKey={'hop'}
            columns={columns}
            dataSource={data}
            bordered
            pagination={false}
          />
        </Form>

      </div>
    )
  }
}
