import React, { Component } from "react";
import ReactTable from "react-table";
import { Button } from "antd";
import axios from "axios";
import SockJS from "sockjs-client";

import OppSelect from "./OppSelect";
import LigaSelect from "./LigaSelect";
import { Select, Option } from "./Select";

import "react-table/react-table.css";
import "./StatsTable.less";
import "./StatsTable.css";

class StatsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pages: null,
      page: 0,
      filtered: [],
      sorted: [],
      pageSize: 10,

      loading: true,
      oppLoaded: false,
      selectedLiga: 0,
      columns: this.prepareColums(this.props.columns)
    };
    this.fetchData = this.fetchData.bind(this);
  }

  startSockjs(wsUrl) {
    const options = {};
    this.sockjs = new SockJS(wsUrl, null, options);
    this.sockjs.onmessage = e => {
      this.onWSMessage(e.data);
    };

    this.sockjs.onclose = () => {
      console.log(`close ws ${wsUrl}`);
    };
  }

  componentDidMount() {
    const { filtered: defFiltered, pageSize: defPageSize } = this.state;
    const { defaultFilter } = this.props;
    const setting = this.loadSettings();
    let filtered = defFiltered;

    const urlParams = window.location.search.substr(1);
    let paramsObj = {};
    if (urlParams !== "") {
      paramsObj = urlParams.split("&").reduce((result, param) => {
        if (param.indexOf("=") !== -1) {
          const [key, value] = param.split("=");
          result[key] = value;
        }
        return result;
      }, paramsObj);
    }

    if (defaultFilter) {
      Object.keys(defaultFilter).forEach(key => {
        paramsObj[key] = defaultFilter[key];
      });
    }

    Object.keys(paramsObj).forEach(key => {
      filtered = [
        ...filtered.filter(item => item.id !== key),
        { id: key, value: paramsObj[key] }
      ];
    });

    this.refreshData({
      filtered,
      pageSize: setting && setting.pageSize ? setting.pageSize : defPageSize
    });
    this.loadOpp(0);

    const { wsUrl } = this.props;
    if (wsUrl) {
      this.startSockjs(wsUrl);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { columns, wsUrl } = nextProps;
    const { columns: localColumns } = this.state;
    const migratedColumns = columns.map(column => {
      if (!column.HeaderSelectList) return column;
      const oldColumn = localColumns.filter(
        locColumn => locColumn.key === column.key
      )[0];
      if (oldColumn)
        return { ...column, selectedBacklight: oldColumn.selectedBacklight };
      return column;
    });
    this.setState({
      columns: this.prepareColums(migratedColumns)
    });

    if (!wsUrl && this.sockjs) {
      this.sockjs.close();
      this.sockjs = null;
    }
    if (wsUrl && !this.sockjs) {
      this.startSockjs(wsUrl);
    }
  }

  componentWillUnmount() {
    if (this.sockjs) this.sockjs.close();
  }

  onWSMessage(srcWSData) {
    const wsData = JSON.parse(srcWSData);
    console.log(wsData);
    if (!wsData.type) return;

    const { page, data } = this.state;
    console.log(data);

    const updatedGames = data.filter(gm =>
      wsData.data.dateStart.includes(gm.dateStart.v)
    );

    switch (wsData.type) {
      case "newGame":
        if (page === 0) this.refreshData({});
        break;
      case "updateGame":
        if (updatedGames.length > 0) this.refreshData({});
        break;
      default:
    }
  }

  onChangeBacklight(key, value) {
    const { columns } = this.state;
    this.setState({
      columns: columns.map(column => {
        if (column.key !== key) return column;
        const newColumn = { ...column };
        newColumn.selectedBacklight = value;
        if (newColumn.columns) {
          newColumn.columns = newColumn.columns.map(childColumn => ({
            ...childColumn,
            selectedBacklight: value
          }));
        }
        return newColumn;
      })
    });
  }

  rowstyle = (row, column) => {
    const res = {
      textAlign: "center",
      width: "100%",
      height: "100%",
      lineHeight: "20px",
      paddingTop: "5px",
      paddingBottom: "3px"
    };
    if (row[column.id]) {
      if (row[column.id].bcolor) {
        res.backgroundColor = row[column.id].bcolor;
      }
      if (row[column.id].tcolor) {
        res.color = row[column.id].tcolor;
      }
    }
    return res;
  };

  prepareColums(colums) {
    return colums.map(col => {
      const resultColum = { ...col };

      if (resultColum.columns) {
        resultColum.columns = this.prepareColums(resultColum.columns);
      }

      if (!resultColum.type) resultColum.type = "base";
      if (!resultColum.width) resultColum.width = 40;
      // console.log(resultColum)
      if (resultColum.type) {
        switch (resultColum.type) {
          case "liga":
            resultColum.Filter = resultColum.Filter
              ? resultColum.Filter
              : ({ filter, onChange }) => {
                  return (
                    <LigaSelect
                      keyPrefix={`filter_${resultColum.dataIndex}`}
                      oppApiUrl={this.props.oppApiUrl}
                      value={
                        filter && typeof filter.value !== "undefined"
                          ? filter.value
                          : 0
                      }
                      onChange={value => {
                        this.setState({ selectedLiga: value });
                        onChange(value);
                      }}
                    />
                  );
                };
            resultColum.Cell = ({ row, column }) => {
              let value =
                row[column.id] && typeof row[column.id].v !== "undefined"
                  ? row[column.id].v
                  : 0;
              const cellStyle = resultColum.cellStyle
                ? resultColum.cellStyle(this.rowstyle(row, column))
                : this.rowstyle(row, column);
              try {
                value = this.state.champs[value]
                  ? this.state.champs[value]
                  : value;
              } catch (e) {
                console.log(e);
              }
              return <div style={cellStyle}>{value}</div>;
            };
            break;
          case "opp":
            resultColum.Filter = resultColum.Filter
              ? resultColum.Filter
              : ({ filter, onChange }) => {
                  return (
                    <OppSelect
                      keyPrefix={`filter_${resultColum.dataIndex}`}
                      ligaID={this.state.selectedLiga}
                      oppApiUrl={this.props.oppApiUrl}
                      value={
                        filter && typeof filter.value !== "undefined"
                          ? filter.value
                          : 0
                      }
                      onChange={value => {
                        onChange(value);
                      }}
                    />
                  );
                };
            resultColum.Cell = cellData => {
              const { row, column } = cellData;
              let value =
                row[column.id] && typeof row[column.id].v !== "undefined"
                  ? row[column.id].v
                  : 0;
              // const cellStyle = resultColum.cellStyle ? resultColum.cellStyle(this.rowstyle(row, column)) : this.rowstyle(row, column);

              const rowStyle = this.rowstyle(row, column);
              const cellStyle = resultColum.cellStyle
                ? resultColum.cellStyle(rowStyle, value, row, column)
                : rowStyle;
              const cellStyle2 = resultColum.cellStyle2
                ? resultColum.cellStyle2(cellStyle, cellData)
                : cellStyle;

              try {
                value = this.state.oppsById[value]
                  ? this.state.oppsById[value]
                  : value;
              } catch (e) {
                console.log(e);
              }
              return <div style={cellStyle2}>{value}</div>;
            };
            break;
          case "custom":
            if (resultColum.key === "analiz") {
              const cellStyle = {
                textAlign: "center",
                width: "100%",
                height: "100%"
              };
              resultColum.Filter = row => (
                <div style={cellStyle}>
                  <Button
                    type="danger"
                    onClick={() => {
                      const { defaultFilter } = this.props;
                      const filtered = [];
                      if (defaultFilter) {
                        Object.keys(defaultFilter).forEach(key => {
                          filtered.push({ id: key, value: defaultFilter[key] });
                        });
                      }
                      this.refreshData({ filtered });
                    }}
                  >
                    Сброс
                  </Button>
                </div>
              );
            }
            break;
          case "base":
          default:
            resultColum.Cell = cellData => {
              const { row, column, value } = cellData;
              let text = value && typeof value.v !== "undefined" ? value.v : "";
              if (resultColum.dic) {
                if (typeof resultColum.dic === "function") {
                  text = resultColum.dic(cellData);
                } else {
                  text =
                    resultColum.dic &&
                    typeof resultColum.dic[text] !== "undefined"
                      ? resultColum.dic[text]
                      : text;
                }
              }
              const rowStyle = this.rowstyle(row, column);
              const cellStyle = resultColum.cellStyle
                ? resultColum.cellStyle(rowStyle, value, row, column)
                : rowStyle;
              const cellStyle2 = resultColum.cellStyle2
                ? resultColum.cellStyle2(cellStyle, cellData)
                : cellStyle;
              return <div style={cellStyle2}>{text}</div>;
            };
            resultColum.Filter = resultColum.Filter
              ? resultColum.Filter
              : ({ filter, onChange }) => (
                  <input
                    type="text"
                    defaultValue={filter && filter.value ? filter.value : ""}
                    onChange={event => {
                      window.filter_changed = true;
                    }}
                    onBlur={event => {
                      if (window.filter_changed) {
                        window.filter_changed = false;
                        onChange(event.target.value);
                      }
                    }}
                    onKeyDown={event => {
                      if (event.keyCode === 13) {
                        window.filter_changed = false;
                        onChange(event.target.value);
                      }
                    }}
                    style={{
                      width: "100%"
                    }}
                  />
                );
            break;
        }
      }

      if (resultColum.HeaderSelectList) {
        if (!resultColum.selectedBacklight)
          resultColum.selectedBacklight = "no";

        if (resultColum.columns) {
          resultColum.columns = resultColum.columns.map(column => ({
            ...column,
            selectedBacklight: resultColum.selectedBacklight
          }));
        }

        const { Header, key } = resultColum;
        resultColum.Header = row => (
          <div>
            <div>{Header}</div>
            <div>
              <Select
                style={{ width: "100%" }}
                value={
                  row.column.selectedBacklight
                    ? row.column.selectedBacklight
                    : 0
                }
                onChange={value => {
                  this.onChangeBacklight(key, value);
                }}
              >
                {Object.keys(resultColum.HeaderSelectList).map(listKey => (
                  <Option key={listKey} value={listKey}>
                    {resultColum.HeaderSelectList[listKey]}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        );
      }

      return resultColum;
    });
  }

  refreshData(fetchParam) {
    this.setState({ loading: true });
    const { pageSize, page, sorted, filtered } = fetchParam;
    axios
      .post(this.props.dataApiUrl, {
        pageSize:
          typeof pageSize !== "undefined" ? pageSize : this.state.pageSize,
        page: typeof page !== "undefined" ? page : this.state.page,
        sorted: typeof sorted !== "undefined" ? sorted : this.state.sorted,
        filtered:
          typeof filtered !== "undefined" ? filtered : this.state.filtered
      })
      .then(res => {
        const { rows, pages, avgStat, lastPrognoz } = res.data;
        this.setState({
          ...fetchParam,
          loading: false,
          data: rows,
          pages
        });
        const { onUpdateAvgStat, onUpdateBotPrognoz } = this.props;
        if (onUpdateAvgStat) onUpdateAvgStat(avgStat);
        if (onUpdateBotPrognoz) onUpdateBotPrognoz(lastPrognoz);
        this.saveSettings();
      })
      .catch(err => {
        this.setState({
          loading: false,
          data: []
        });
      });
  }

  fetchData(state, instance) {
    if (this.state.skipFetchData) {
      this.setState({
        loading: false,
        skipFetchData: false
      });
      return;
    }

    const { pageSize, page, sorted, filtered } = state;

    this.setState({ loading: true });
    axios
      .post(this.props.dataApiUrl, { pageSize, page, sorted, filtered })
      .then(res => {
        const { rows, pages } = res.data;
        this.setState({
          loading: false,
          data: rows,
          pages
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          data: []
        });
      });
  }

  loadSettings() {
    if (!this.props.localStoreKey) return false;
    const settingSrc = localStorage.getItem(
      `${this.props.localStoreKey}_table`
    );
    if (settingSrc === null) return false;
    try {
      const setting = JSON.parse(settingSrc);
      return setting;
    } catch (err) {
      console.log(err);
    }
    return false;
  }

  saveSettings(customSetting = {}) {
    if (!this.props.localStoreKey) return false;

    const { filtered, pageSize } = this.state;

    const setting = {
      filtered: customSetting.filtered ? customSetting.filtered : filtered,
      pageSize: customSetting.pageSize ? customSetting.pageSize : pageSize
    };
    localStorage.setItem(
      `${this.props.localStoreKey}_table`,
      JSON.stringify(setting)
    );
    return true;
  }

  loadOpp(group_id) {
    if (this.props.oppApiUrl) {
      axios
        .post(this.props.oppApiUrl, {
          sport_id: 85,
          group_id: group_id
        })
        .then(response => {
          const data = response.data;
          const newData = Object.keys(data.items).reduce(
            (obj, champId) => {
              obj.champs[champId] = data.items[champId].name;
              if (group_id > 0 && group_id !== champId) {
                return obj;
              }
              obj.opps = data.items[champId].opp.reduce((opps, oppObj) => {
                if (opps[oppObj.opp]) {
                  opps[oppObj.opp] = `${opps[oppObj.opp]},${oppObj.clid_opp}`;
                } else {
                  opps[oppObj.opp] = `${oppObj.clid_opp}`;
                }
                obj.oppsById[oppObj.clid_opp] = oppObj.opp;
                return opps;
              }, obj.opps);
              return obj;
            },
            {
              champs: {},
              opps: {},
              oppsById: {}
            }
          );

          this.setState({
            opps: newData.opps,
            champs: newData.champs,
            oppsById: newData.oppsById,
            oppLoaded: true
          });
        })
        .catch(err => {
          this.setState({
            opps: {},
            champs: {},
            oppsById: {},
            oppLoaded: true
          });
        });
    }
  }

  render() {
    const { data, loading, pages, columns, filtered } = this.state;
    return (
      <div>
        <ReactTable
          // className={'stat-table'}
          columns={columns}
          filtered={filtered}
          onFilteredChange={filtered => {
            this.refreshData({ filtered });
          }}
          onPageChange={page => {
            this.refreshData({ page });
          }}
          onPageSizeChange={pageSize => {
            this.refreshData({ pageSize });
          }}
          onSortedChange={sorted => {
            this.refreshData({ sorted });
          }}
          manual
          data={data}
          pages={pages}
          loading={loading}
          filterable
          defaultPageSize={10}
          className="-striped -highlight"
          loadingText="Загрузка..."
          nextText={window.innerWidth > 400 ? "Следующая" : "След."}
          previousText={window.innerWidth > 400 ? "Предыдущая" : "Пред."}
          pageText="Страница"
          rowsText="строк"
          noDataText="Данных нет."
          ofText="из"
        />
      </div>
    );
  }
}

export default StatsTable;
