import React, { Component } from 'react';
import { Row, Col, Switch, Card, Button, Icon } from 'antd';

import './StatColumnVisibleChanger.css';

export default class StatColumnVisibleChanger extends Component {
  constructor(props) {
    super(props);
    const { columns } = this.props;
    this.state = {
      columns,
      colapsed: true,
    }
    this.onClickColapse = this.onClickColapse.bind(this);
  }

  componentDidMount() {
    this.load(true);
  }

  onClickColapse() {
    const { colapsed } = this.state;
    const setting = this.getSettings();
    setting.colapsed = !colapsed;

    this.save(setting);

    this.setState({
      colapsed: !colapsed,
    })

  }

  getSettings() {
    const { colapsed } = this.state;
    return {
      colapsed
    };
  }

  headerButtons() {
    const { colapsed } = this.state;
    const { presetColumns } = this.props;

    const iType = colapsed ? "caret-up" : "caret-down";

    const presetButtons = presetColumns ? presetColumns.map(
      column => <Button
        key={column.key}
        style={{ height: '30px' }}
        onClick={() => { this.handleChangeVisibleColumns(column.columnsKeys) }}
      >{column.title}
      </Button>
    ) : '';
    return <div className='headerButtons'>{presetButtons}
      <Button onClick={this.onClickColapse} style={{ height: '30px' }}>
        <Icon type={iType} />
      </Button>
    </div>
  }

  save(setting = this.getSettings()) {
    const { storagePrefix } = this.props;
    if (!storagePrefix) return false;

    localStorage.setItem(`${storagePrefix}_StatColumnVisibleChanger_store`, JSON.stringify(setting))
    return true;
  }

  load(updateState) {
    const { storagePrefix } = this.props;
    if (!storagePrefix) return false;

    const settingSrc = localStorage.getItem(`${storagePrefix}_StatColumnVisibleChanger_store`);
    if (!settingSrc) return false;
    let setting = {};
    try {
      setting = JSON.parse(settingSrc);
    } catch (err) {
      console.log(err)
      return false;
    }
    if (updateState) {
      const { colapsed } = setting;
      this.setState({
        colapsed
      })
    }
    return setting;
  }

  handleChangeVisibleColumns(visibleKeys) {
    const { columns } = this.state;

    const newColumns = columns.map(column => ({ ...column, visible: column.noHide ? true : visibleKeys.includes(column.key) }))

    const visKeys = newColumns.reduce((acc, column) => {
      if (column.visible) return [...acc, column.key];
      return acc;
    }, [])

    const { handleChangeVisibleColumn } = this.props;
    handleChangeVisibleColumn(visKeys);

    this.setState({
      columns: newColumns
    })
  }

  handleChangeVisibleColumn(key, value) {
    const { columns } = this.state;

    const newColumns = columns.map(column => ({ ...column, visible: column.key === key ? value : column.visible }))

    const visibleKeys = newColumns.reduce((acc, column) => {
      if (column.visible) return [...acc, column.key];
      return acc;
    }, [])

    const { handleChangeVisibleColumn } = this.props;
    handleChangeVisibleColumn(visibleKeys);

    this.setState({
      columns: newColumns
    })
  }

  render() {
    const { columns, colapsed } = this.state;

    return <Card
      className='StatColumnVisibleChanger'
      title="Выбор столбцов"
      extra={this.headerButtons()}
      style={{ marginBottom: 12 }}
      size='small'
    >
      {colapsed && <Row>
        {columns.map(column => {
          if (column.noHide) return '';
          let label = column.Header ? column.Header : '';
          label = column.title ? column.title : label;
          return <Col key={`visibleChangerCol_${column.key}`} span={12}>
            <div style={{ padding: '5px 0', whiteSpace: 'nowrap' }} >
              <Switch key={`visibleChanger_${column.key}`} checked={column.visible}
                onChange={(value => {
                  this.handleChangeVisibleColumn(column.key, value);
                })} />
              <span style={{ padding: '5px', whiteSpace: 'nowrap' }}>{label}</span></div>
          </Col>
        })}
      </Row>}
    </Card>
  }
}